import React, { useRef, useEffect, useState } from "react";
import { MdMyLocation } from "react-icons/md";
import { fromLatLng } from "react-geocode";
import { useTranslation } from "react-i18next";

function MapSearch({
  short = false,
  displayLocationIcon = true,
  searchedLocation = "",
}) {
  const autocompleteInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("userCountryCode")? localStorage.getItem("userCountryCode") : "QA");
  const [input, setInput] = useState(sessionStorage.getItem("address"));
  const { t } = useTranslation();

  useEffect(() => {
    // Ensure the Google Maps API is loaded
    if (!window.google || !window.google.maps) return;

    // Initialize the autocomplete service
    autocompleteService.current = new window.google.maps.places.Autocomplete(
      autocompleteInputRef.current,
      {
        componentRestrictions: { country: selectedCountry }, // Default country restriction
        fields: ["address_components", "geometry", "icon", "name"], // Specify fields
      }
    );

      // Add a listener for place selection
      autocompleteService.current.addListener("place_changed", () => {
        const place = autocompleteService.current.getPlace();
        if (place.geometry && place.name) {
          const { lat, lng } = place.geometry.location.toJSON();
  
          // Store the latitude and longitude in localStorage
          localStorage.setItem("mapLatitude", lat);
          localStorage.setItem("mapLongitude", lng);
          sessionStorage.setItem("address", place.name);
          setInput(place.name)
          window.location.reload();
        } else {
          console.warn("No geometry available for this place.");
          window.location.reload();
        }
      });
  }, [selectedCountry]);


  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        localStorage.setItem("mapLatitude", latitude);
        localStorage.setItem("mapLongitude", longitude);
        const lat = latitude;
        const lng = longitude;
        fromLatLng(lat, lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            sessionStorage.setItem("address", address)
            localStorage.setItem("mapLatitude", latitude)
            localStorage.setItem("mapLongitude", longitude)
            setInput(address)
            const countryComponent = addressArray.find((component) =>
              component.types.includes("country")
            );
            localStorage.setItem("userCountryCode", countryComponent.short_name);
            localStorage.setItem("currentCountryCode", countryComponent.short_name);
          },
          (error) => {
            console.error("unable to set my location", error);
          }
        );
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );

    window.location.reload()
  }

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText === "") {
      sessionStorage.setItem("address", "")
      localStorage.setItem("mapLatitude", "");
      localStorage.setItem("mapLongitude", "");
    }
    setInput(inputText);
  }

  return (
    <div className="container" style={{ marginTop: '1%', marginBottom: '1%' }}>
      <div
        style={{
          position: "relative",
          border: "1px solid rgb(224, 224, 224)",
          // borderRadius: short ? "0px" : "30px",
          backgroundColor: "white",
          display: "flex",
          padding: "7px",
          marginLeft: "-10px",
          alignItems: "center",
          borderRadius: '5px'
        }}
        className="map-search"
      >
        <img
          src="/images/pin-point.png"
          alt="pin-point"
          title="Search Location"
          style={{ height: "25px", color: 'rgb(224, 224, 224)' }} />
        <input
          className="search-container"
          type="text"
          ref={autocompleteInputRef}
          placeholder={short ? "" : t("where_to_serve")}
          style={{ fontSize: '16px', paddingLeft: '10px !important' }}
          value={input}
          onChange={handleInputChange}
        />
        {displayLocationIcon && (
          <button
            style={{
              width: short ? "10%" : "50%",
              backgroundColor: "#9e6dc8",
              border: "none",
              outline: "none",
              marginLeft: "2%",
              height: "45px",
            }}
            onClick={setCurrentLocation}
          >
            <MdMyLocation color="white" /> {short ? "" : <span style={{ color: 'white' }}>{t("set_my_location")}</span>}
          </button>
        )}
      </div>
    </div>
  );
}

export default MapSearch;
