import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useState } from "react";
import axios from "axios";
import { authHeader, paymentConfirmation } from "../helpers/helpers";
import { useTranslation } from "react-i18next";
import { BuyOnline, stepTwoBooking } from "../helpers/bookingAPIsteps";
import { toast } from "react-toastify";
import AddressComponent from "../pages/my_account/Address/AddressComponent";
import { Modal } from "react-bootstrap";
import SuccessBooking from "../pages/appointment_booking/service/components/SuccessBooking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import StripePay from "./Stripe";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import MyFatoorahPaymentForm from "./MyFatoorahPaymentForm";

const cardBrandLogos = {
  visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
  mastercard:
    "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
  amex: "https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg",
  discover:
    "https://upload.wikimedia.org/wikipedia/commons/5/52/Discover_Card_logo.svg",
  diners:
    "https://upload.wikimedia.org/wikipedia/commons/0/04/Diners_Club_Logo5.png",
  jcb: "https://upload.wikimedia.org/wikipedia/commons/1/1b/JCB_logo.svg",
};

const AppointmentSummary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { checkoutData } = location.state || {};
  const dispatch = useDispatch();
  const [isCashPrev, setIsCashPrev] = useState(false)

  const [mobileNumber, setMobileNumber] = useState(
    checkoutData?.customer?.mobile
  ); // Default value
  const [mobileCountryCode, setMobileCountryCode] = useState(
    checkoutData?.customer?.country_code
  );
  const [appointmentData, setAppointmentData] = useState(checkoutData);
  const [promoInput, setPromoInput] = useState("");
  const [isAddAddress, setIsAddress] = useState(false);
  const [showTotalSummary, setShowTotalSummary] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [isWallet, setIsWallet] = useState(false);
  const [payType, setPayType] = useState("");
  const [bookingData, setBookingData] = useState();
  const [bookingSuccessModal, setBookingSuccessModal] = useState(false);
  const [bookedAppt, setBookedAppt] = useState();
  const [credits, setCredits] = useState();
  const [checked, setChecked] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [stripeCustomerCards, setStripeCustomerCards] = useState();
  const [fatoorahCustomerCards, setFatoorahCustomerCards] = useState([]);
  const [promoError, setPromoError] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const [orderedItems, setOrderedItems] = useState([]);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const userCC = localStorage.getItem("userCountryCode");
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [isNewCard, setIsNewCard] = useState(false);
  const [isTxnFailed, setIsTxnFailed] = useState(false);
  const [isTxnSuccess, setIsTxnSuccess] = useState(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const status = urlParams.get("status");
    const transId = urlParams.get("transId");
    const custom1 = urlParams.get("custom1");
    const appointmentId = urlParams.get("appointmentId");
    console.log("abc ", {appointmentId})
    if (status === "Failed") {
      setIsTxnFailed(true);
    } 
    if (status === "Paid"){
      axios
        .get(`${baseUrl}/appointment/view/${appointmentId}`, {
          headers: authHeader(),
        })
        .then((response) => {
          setBookedAppt(response.data);
        })
        .catch((error) => {
          console.error("Error fetching appointment details:", error);
          toast.error("Failed to fetch appointment details", {
            position: "top-right",
            closeOnClick: true,
          });
        });
      setIsTxnSuccess(true);
      setBookingSuccessModal(true);
      return (<></>)
    }
  }, []);

  // myFatoorah not supported in India and Turkiye
  // mandatorily using Stripe in UAE
  const codeToPG = {
    IN: "stripe",
    AE: "stripe",
    TR: "stripe",
  };
  const isFatoorah = userCC in codeToPG ? false : true;

  useEffect(() => {
    if (appointmentData?.cardDetails) {
      let newCard = {
        card: {
          cardNumber: appointmentData.cardDetails.cardNumber,
          cardType: appointmentData.cardDetails.cardType,
          cardExpiry: `${appointmentData.cardDetails.expiryMonth}/${appointmentData.cardDetails.expiryYear}`,
        },
        token: appointmentData.cardDetails.token,
      };
      setFatoorahCustomerCards((prevCards) =>
        Array.isArray(prevCards) ? [...prevCards, newCard] : [newCard]
      );

      // setFatoorahCustomerCards((prevCards) => [
      //   ...prevCards,
      //   {
      //     card: {
      //       cardNumber: appointmentData.cardDetails.cardNumber,
      //       cardType: appointmentData.cardDetails.cardType,
      //       cardExpiry: `${appointmentData.cardDetails.expiryMonth}/${appointmentData.cardDetails.expiryYear}`,
      //     },
      //     token: appointmentData.cardDetails.token,
      //   },
      // ]);
    }
  }, [appointmentData?.cardDetails]);
  useEffect(() => {
    const newArray = [];
    for (const [key, value] of appointmentData?.selected_services) {
      newArray.push({
        _id: key._id,
        item_count: value,
        qty: value,
      });
    }
    setOrderedItems((prevArr) => [...prevArr, ...newArray]);
  }, []);

  const cardBrandLogos = {
    visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
    mastercard:
      "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
    amex: "https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg",
    discover:
      "https://upload.wikimedia.org/wikipedia/commons/5/52/Discover_Card_logo.svg",
    diners:
      "https://upload.wikimedia.org/wikipedia/commons/0/04/Diners_Club_Logo5.png",
    jcb: "https://upload.wikimedia.org/wikipedia/commons/1/1b/JCB_logo.svg",
  };

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(async () => {
    let customerCardListUrl = `${baseUrl}/appointment/card/details?isFatoorah=${isFatoorah}`;
    try {
      // Make the API request and wait for the response
      const response = await axios.get(customerCardListUrl, {
        headers: authHeader(),
      });

      setStripeCustomerCards(response?.data?.stripeCards);
      setFatoorahCustomerCards(response?.data?.fatoorahCards);
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        closeOnClick: true,
      });
    }
  }, []);

  const hideTotalSummary = () => {
    setShowTotalSummary(false);
  };

  const updateAppointmentData = (key, value) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    updateAppointmentData("mobile", e.target.value);
  };

  const handleMobileCountryCodeChange = (e) => {
    setMobileCountryCode(e.target.value);
    updateAppointmentData("mobile_country_code", e.target.value);
  };

  const handleAddressChange = (e) => {
    updateAppointmentData("address_id", e.target.value);
    const defaultItem = appointmentData?.customer_address?.find(
      (item) => item._id === e.target.value
    );
    updateAppointmentData("address", defaultItem.address);
    let addressDefaultUrl =
      baseUrl + "/customer/address/default/" + e.target.value;
    axios({
      method: "post",
      url: addressDefaultUrl,
      headers: authHeader(),
    }).then((res) => {
      const filteredAddresses = res.data.addressList.filter(
        (address) =>
          address.country_id === localStorage.getItem("userCountryId")
      );
      updateAppointmentData("customer_address", filteredAddresses);
    });
  };

  const handlePaymentChange = (e) => {
    if (isCashPrev) {
      updateAppointmentData("totalPrice", appointmentData?.totalPrice - checkoutData?.cod_charges)
      setIsCashPrev(false)
    }
    if (e.target.value === "new-card") {
      setIsNewCard(true);
      setPaymentMode("card");
      setPayType("online");
      setIsWallet(false);
      updateAppointmentData("paymentCardToken", "");
    } else {
      setIsNewCard(false);
      setPaymentMode("card");
      setPayType("online");
      let card = JSON.parse(e.target.value);
      updateAppointmentData("payment", card);
      updateAppointmentData("paymentCardToken", card.token);
    }
  };

  useEffect(() => {
    if (appointmentData?.service?.ui_type === "Package") {
      updateAppointmentData(
        "price",
        checkoutData?.service_charge ? checkoutData?.service_charge : 0
      );
      updateAppointmentData(
        "totalPrice",
        checkoutData?.service_charge ? checkoutData?.service_charge : 0
      );
    } else {
      let priceUrl =
        baseUrl + "/appointment/calculate/frequency/appointment/price";
      axios({
        method: "post",
        url: priceUrl,
        headers: authHeader(),
        data: {
          hours_count: appointmentData?.hours,
          country_id: appointmentData?.country_id,
          customer_id: appointmentData?.customer_id,
          frequency_id: appointmentData?.frequency_id,
          is_cleaning_materials: checkoutData?.is_cleaning_materials
            ? checkoutData?.is_cleaning_materials
            : false,
          professionals_count: parseInt(appointmentData?.no_of_pros, 10),
          ordered_item: orderedItems,
        },
      }).then((res) => {
        updateAppointmentData("currency", res.data.currency);
        updateAppointmentData("totalDiscount", res.data.discount);
        updateAppointmentData(
          "totalMaterialPrice",
          res.data.totalMaterialPrice
        );
        updateAppointmentData("price", res.data.price);
        updateAppointmentData(
          "totalFrequencyPrice",
          res.data.totalFrequencyPrice
        );
        updateAppointmentData(
          "totalPrice",
          res.data.totalPrice +
            res.data.totalMaterialPrice +
            checkoutData?.slot_price
        );
        updateAppointmentData("vipDiscountPrice", res.data.vipDiscountPrice);
      });
    }
  }, []);

  useEffect(() => {
    let sc =
      appointmentData?.service?.ui_type === "Package"
        ? Number(appointmentData?.service_price)
        : Number(appointmentData?.totalPrice);

    let ta =
      sc +
      Number(checkoutData?.slot_price ? checkoutData?.slot_price : 0) -
      Number(
        appointmentData?.vipDiscountPrice
          ? appointmentData?.vipDiscountPrice
          : 0
      );

    setTotalAmount(ta.toFixed(2));
  }, [appointmentData]);

  useEffect(() => {
    let rhWalletUrl = baseUrl + "/isRhValid";
    axios({
      method: "post",
      url: rhWalletUrl,
      headers: authHeader(),
      data: {
        service_id: appointmentData?.service_id,
      },
    }).then((res) => {
      if (res?.data?.total_wallet_credits > 0) {
        setIsWallet(true);
        setPayType("wallet");
      }
      setCredits(res?.data);
    });
  }, []);

  const handleApplyPromo = async (e) => {
    setPromoLoading(true);
    let promoUrl = baseUrl + "/discount/apply/voucher";

    try {
      const response = await axios({
        method: "POST",
        headers: authHeader(),
        url: promoUrl,
        data: {
          country_id: appointmentData?.country_id,
          customer_id: appointmentData?.customer_id,
          min_amount: parseFloat(appointmentData?.price),
          promo_code: promoInput,
          service_id: [appointmentData?.service_id],
        },
      });

      if (response?.data?.code === 200) {
        updateAppointmentData("voucherDiscount", response.data.voucher);
        updateAppointmentData(
          "discount_type",
          response.data.coupon_type === "promo" ? "PromoCode" : "Birthday"
        );
        updateAppointmentData("promo_code", promoInput);
        updateAppointmentData(
          "promoDiscount",
          Number(response.data.discount_price).toFixed(2)
        );
        updateAppointmentData(
          "totalDiscount",
          Number(
            Number(appointmentData?.totalDiscount) +
              Number(response.data.discount_price)
          ).toFixed(2)
        );
        updateAppointmentData(
          "totalPrice",
          Number(
            Number(appointmentData?.totalPrice) -
              Number(response.data.discount_price)
          ).toFixed(2)
        );

        setTotalAmount(
          (prevTotalAmount) =>
            prevTotalAmount - Number(response.data.discount_price)
        );
        setPromoError(false);
      } else {
        setPromoError(true);
      }
    } catch (error) {
      // if (
      //   error.response &&
      //   (error.response.status === 404 || error.response.status === 400)
      // ) {
      //   toast.error("Voucher not found", {
      //     duration: 2000,
      //     position: "top-right",
      //   });
      // } else if (error.response && error.response.status === 406) {
      //   toast.error("Voucher expired", {
      //     duration: 2000,
      //     position: "top-right",
      //   });
      // }
      setPromoError(true);
      setPromoLoading(false);
      updateAppointmentData(
        "totalDiscount",
        Number(
          appointmentData?.totalDiscount ? appointmentData?.totalDiscount : 0
        ).toFixed(2) -
          Number(
            appointmentData?.promoDiscount ? appointmentData?.promoDiscount : 0
          ).toFixed(2)
      );
      updateAppointmentData(
        "totalPrice",
        Number(
          Number(
            appointmentData?.totalPrice ? appointmentData?.totalPrice : 0
          ) +
            Number(
              appointmentData?.promoDiscount
                ? appointmentData?.promoDiscount
                : 0
            )
        ).toFixed(2)
      );
      updateAppointmentData("promoDiscount", null);

      return error;
      // handlingAuthErrors(err);
      // throw err; // Re-throw the error so it can be handled by the calling component.
    }

    setPromoLoading(false);
  };

  const customerAddress = async () => {
    let addressListUrl =
      baseUrl +
      `/customer/address/list?country_id=${localStorage.getItem(
        "userCountryId"
      )}`;
    try {
      // Make the API request and wait for the response
      const response = await axios.get(addressListUrl, {
        headers: authHeader(),
      });

      // Only proceed when the response is received
      updateAppointmentData("customer_address", response.data.address);
      const defaultItem = response.data.address.find(
        (item) => item.isDefault === true
      );
      updateAppointmentData("address_id", defaultItem._id);
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        closeOnClick: true,
      });
    }
  };

  useEffect(() => {
    customerAddress();
  }, [checkoutData?.customer_id]);

  useEffect(async () => {
    let apptFreqUrl = baseUrl + "/appointment/create/frequency";
    if (appointmentData?.appointment_id != null) {
      await axios({
        method: "post",
        url: apptFreqUrl,
        headers: authHeader(),
        data: {
          appointment_id: appointmentData?.appointment_id,
          frequency_id: appointmentData?.frequency_id,
          hours_count: appointmentData?.hours,
          appointment_process: 2,
        },
      }).then((res) => {
        // updateAppointmentData("appointment_id", res.data.appointment._id);
        updateAppointmentData(
          "appointment_process",
          res.data.appointment.appointment_process
        );
        updateAppointmentData(
          "appointment_no",
          res.data.appointment.appointment_no
        );
        updateAppointmentData(
          "appointment",
          JSON.stringify(res.data.appointment)
        );
      });
    }
  }, [appointmentData?.appointment_id]);

  useEffect(async () => {
    if (appointmentData?.appointment_process === 2) {
      let createSvcUrl = baseUrl + "/appointment/create/service";
      await axios({
        method: "post",
        url: createSvcUrl,
        headers: authHeader(),
        data: {
          appointment_id: appointmentData?.appointment_id,
          hours_count: appointmentData?.hours,
          appointment_process: 3,
          cleaners_id: appointmentData?.selected_cleaners,
          is_auto_assign: true,
          is_cleaning_materials: checkoutData?.is_cleaning_materials
            ? checkoutData?.is_cleaning_materials
            : false,
          professionals_count: appointmentData?.no_of_pros,
          ordered_item: orderedItems,
        },
      }).then((res) => {
        updateAppointmentData("appointment_id", res.data.appointment._id);
        updateAppointmentData(
          "appointment_process",
          res.data.appointment.appointment_process
        );
        updateAppointmentData(
          "appointment_no",
          res.data.appointment.appointment_no
        );
        updateAppointmentData(
          "appointment",
          JSON.stringify(res.data.appointment)
        );
      });
    }
  }, [appointmentData?.appointment_process]);

  useEffect(async () => {
    if (appointmentData?.appointment_process === 3) {
      let createSvcUrl = baseUrl + "/appointment/create/time";
      await axios({
        method: "post",
        url: createSvcUrl,
        headers: authHeader(),
        data: {
          appointment_id: appointmentData?.appointment_id,
          appointment_date_start: appointmentData?.appointment_date,
          appointment_process: 4,
          cleaner_id: appointmentData?.selected_cleaners,
          start_time_between: checkoutData?.start_time,
          is_auto_assign: true,
          hours_count:
            appointmentData?.service?.ui_type === "Package"
              ? 2
              : appointmentData?.hours,
          address_id: appointmentData?.address_id,
          ordered_item: orderedItems,
        },
      })
        .then((res) => {
          updateAppointmentData("appointment_id", res.data.appointment._id);
          updateAppointmentData(
            "appointment_process",
            res.data.appointment.appointment_process
          );
          updateAppointmentData(
            "appointment_no",
            res.data.appointment.appointment_no
          );
          updateAppointmentData(
            "appointment",
            JSON.stringify(res.data.appointment)
          );
        })
        .catch((err) => {
          // toast.error("Pro not available. Go back and try another slot", {
          //   position: "top-right",
          //   closeOnClick: true,
          // });
        });
    }
  }, [appointmentData?.appointment_process]);

  useEffect(async () => {
    let appointmentCreateUrl = baseUrl + "/appointment/create/address/";
    try {
      // Make the API request and wait for the response
      if (appointmentData?.address_id) {
        const response = await axios({
          method: "POST",
          headers: authHeader(),
          url: appointmentCreateUrl,
          data: {
            address_id: appointmentData?.address_id,
            appointment_process: 1,
            country_id: appointmentData?.country_id,
            is_home_cleaning:
              appointmentData?.service?.ui_type === "Package" ? false : true,
            service_id: [appointmentData?.service_id],
            customer_id: appointmentData?.customer_id,
            ordered_item: orderedItems,
          },
        });
        updateAppointmentData("appointment_id", response.data.appointment._id);
        updateAppointmentData(
          "appointment",
          JSON.stringify(response.data.appointment)
        );
        updateAppointmentData(
          "appointment_no",
          response.data.appointment.appointment_no
        );
        updateAppointmentData(
          "appointment_process",
          response.data.appointment.appointment_process
        );
      }
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        closeOnClick: true,
      });
    }
  }, [appointmentData?.address_id]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return
    }
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const handelPayOnline = async () => {
    let price =
      parseFloat(
        appointmentData?.service?.ui_type === "Package"
          ? totalAmount
          : appointmentData?.totalPrice
      ) || 0;
    // Use Number() instead of conditional operator to handle non-numeric values
    price +=
      Number(appointmentData?.CodCharges) > 0
        ? Number(appointmentData.CodCharges)
        : 0;
    price +=
      Number(appointmentData?.rushSlot) > 0
        ? Number(appointmentData.rushSlot)
        : 0;
    price +=
      appointmentData?.holidayPrice > 0
        ? Number(appointmentData.holidayPrice)
        : 0;
    price +=
      appointmentData?.totalMaterialPrice > 0
        ? Number(appointmentData.totalMaterialPrice)
        : 0;
    price -=
      appointmentData?.rh_discount > 0
        ? Number(appointmentData.rh_discount)
        : 0;

    if (credits?.total_wallet_credits > 0 && payType === "wallet") {
      price = credits?.total_wallet_credits
        ? Math.max(price - Number(credits?.total_wallet_credits), 0)
        : price;
    }
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`;

    const data = {
      amount: price,
      name: appointmentData?.customer?.name,
      phone: `${appointmentData?.customer?.country_code}${appointmentData?.customer?.mobile}`,
      email: appointmentData?.customer?.email,
      reference_no: appointmentData?.appointment_no,
      custom: appointmentData?.isBuyMemberShipRadio
        ? "memberShipAppointment"
        : "appointment",
      isStripe: !isFatoorah,
      isFatoorah: isFatoorah,
      tokenId: appointmentData?.paymentCardToken,
      appointment_process: 5,
    };

    if (data.isFatoorah) {
      data["currency"] = appointmentData?.currency;
      data["cvv"] = appointmentData?.cardDetails?.cvv;

      if (!appointmentData?.paymentCardToken) {
        data["cardNumber"] = appointmentData?.cardDetails?.cardNumber;
        data["expiryMonth"] = appointmentData?.cardDetails?.expiryMonth;
        data["expiryYear"] = appointmentData?.cardDetails?.expiryYear;
      }
    }
    try {
      if (isFatoorah) {
        data.CustomerIdentifier = appointmentData?.customer_id;
        data.SaveToken = true;
        const fatoorahLinkData = await BuyOnline({
          baseUrl: onlinePayUrl,
          data,
        });
        // window.location.href = `http://localhost:3000/fatoorah-payment?amount=${price}&customerId=${appointmentData?.customer_id}&currency=${appointmentData?.currency}&sessionId=${fatoorahLinkData?.Data?.SessionId}&countryCode=${fatoorahLinkData?.Data?.CountryCode}&appointmentId=${appointmentData?.appointment_id}&&countryId=${appointmentData?.country_id}`;
        navigate('/fatoorah-payment', { 
          state: { 
            appointmentData, 
            fatoorahLinkData, 
            totalAmount: price 
          } 
        });      }

        localStorage.setItem("appointmentDetailsId", appointmentData?.appointment_id);
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data,
      });
      if (
        stepOneData.paymentRequire === false ||
        stepOneData.Status === "Success"
      ) {
        // window.location.href = stepOneData?.data?.successUrl;
        // Make the API request and wait for the response
        // setBookingSuccessModal(true);

        // add payment status to appointment data
        let onlinePayUrl = `${baseUrl}/appointment/create/payment/`;

        const data = {
          appointment_id: appointmentData?.appointment_id,
          appointment_process: 5,
          country_id: appointmentData?.country_id,
          customer_id: appointmentData?.customer_id,
          discount_type: appointmentData?.discount_type,
          isWallet: isWallet,
          is_home_cleaning:
            appointmentData?.service?.ui_type === "Package" ? false : true,
          pay_type: "online",
          payment_mode: "Card",
          promo_code: appointmentData?.promo_code,
          voucher: appointmentData?.voucher,
          service_type: "Home",
        };
        try {
          const appointmentBookingData = await stepTwoBooking({
            baseUrl: onlinePayUrl,
            data,
          });

          window.location.href = `/summary/status?status=Paid&appointmentId=${appointmentData?.appointment_id}`;
          setPaymentInProgress(false);
        } catch (error) {
          // Handle errors here
          setPaymentInProgress(false);
          console.error("stepOneData-error", error);
          toast.error(error, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else if (stepOneData?.data) {
        window.location.href = stepOneData?.data?.payUrl;
      }
    } catch (error) {
      setPaymentInProgress(false);
      // Handle errors here
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error("stepOneData-error", error);
    }
  };

  const handlePayCash = async () => {
    setPaymentInProgress(true);
    let price =
      parseFloat(
        appointmentData?.service?.ui_type === "Package"
          ? totalAmount
          : appointmentData?.totalPrice
      ) || 0;
    price +=
      Number(appointmentData?.cod_charges) > 0
        ? Number(appointmentData.cod_charges)
        : 0;
    price +=
      Number(appointmentData?.rushSlot) > 0
        ? Number(appointmentData.rushSlot)
        : 0;
    price +=
      appointmentData?.holidayPrice > 0
        ? Number(appointmentData.holidayPrice)
        : 0;
    price +=
      appointmentData?.totalMaterialPrice > 0
        ? Number(appointmentData.totalMaterialPrice)
        : 0;
    price -=
      appointmentData?.rh_discount > 0
        ? Number(appointmentData.rh_discount)
        : 0;

    if (credits?.total_wallet_credits > 0 && payType === "wallet") {
      price = credits?.total_wallet_credits
        ? Math.max(price - Number(credits?.total_wallet_credits), 0)
        : price;
    }

    let onlinePayUrl = `${baseUrl}/appointment/create/payment/`;

    const data = {
      amount: price,
      appointment_id: appointmentData?.appointment_id,
      appointment_process: 5,
      country_id: appointmentData?.country_id,
      customer_id: appointmentData?.customer_id,
      discount_type: appointmentData?.discount_type,
      isWallet: isWallet,
      is_home_cleaning:
        appointmentData?.service?.ui_type === "Package" ? false : true,
      pay_type: price === 0 && payType === "wallet" ? "online" : "cod",
      payment_mode: price === 0 && payType === "wallet" ? "online" : "cash",
      service_type: "Home",
      appointment_date_start: appointmentData?.appointment_date,
    };
    try {
      const appointmentBookingData = await stepTwoBooking({
        baseUrl: onlinePayUrl,
        data,
      });
      if (appointmentBookingData) {
        setBookingData(appointmentBookingData);
        setBookedAppt(appointmentBookingData);
        updateAppointmentData(
          "appointment",
          JSON.stringify(appointmentBookingData)
        );
        setPaymentInProgress(false);
        setBookingSuccessModal(true);
      }
    } catch (error) {
      setPaymentInProgress(false);
      // Handle errors here
      console.error("stepOneData-error", error);
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleBooking = async () => {
    if (!appointmentData?.address_id) {
      toast.error("Please select an address to proceed!", {
        position: "top-right",
      });
      return;
    }
    if (!paymentMode) {
      toast.error("Please select a payment mode to proceed!", {
        position: "top-right",
      });
      return;
    }

    if (!checked) {
      toast.error("Please check the T&C", {
        position: "top-right",
      });
      return;
    }
    setPaymentInProgress(true);
    if (paymentMode === "card") {
      handelPayOnline();
    } else if (paymentMode === "cash" || paymentMode === "wallet") {
      handlePayCash();
    } else {
      setPaymentInProgress(false);
      toast.error("Invalid payment method", {
        position: "top-right",
        duration: 2000,
      });
    }
  };

  if (paymentInProgress) {
    return <Loader />;
  }

  

  return (
    <>
      <Header />
      <div className="background-grey">
        <div className="container">
          <div className="container-margin">
            <div className="display-flex-space-between">
              <div
                className="appointment-summary-details background-white-shadow"
                style={{ marginTop: "4%" }}
              >
                <br />
                <h2 className="left-space" style={{ fontSize: '20px' }}>Setup your cleaning plan</h2>
                <hr />
                <div className="left-space">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Service Address</h3>
                    <button
                      className="right-space btn btn-appointmentSummary"
                      onClick={() => {
                        setIsAddress(true);
                      }}
                    >
                      + Add
                    </button>
                  </div>
                  <br />
                  <div>
      {/* <label htmlFor="addressSelect"></label> */}
      <select
        id="addressSelect"
        name="formSelect"
        onChange={handleAddressChange}
        style={{ width: "70%", padding: "8px", fontSize: "16px", borderRadius: '20px' }}
      >
        {appointmentData?.customer_address?.map((add, i) => (
          <option key={add._id} value={add._id} selected={add.isDefault}>
            {add.area}, {add.address}, {add.street}
            {add.apartment_no ? `, Apartment ${add.apartment_no}` : ""} - {add.mobile}
          </option>
        ))}
      </select>
    </div>
                  {/* {appointmentData?.customer_address?.map((add, i) => {
                    return (
                      <div>
                        {
                          <div>
                            <div className="radio-container">
                              <input
                                type="radio"
                                name="formSelect"
                                value={add._id}
                                checked={add.isDefault}
                                onChange={handleAddressChange}
                                style={{ width: "10%", fontSize: "5px" }}
                              />
                              <h4 style={{ fontSize: "16px", lineHeight: '0.3' }}>{add.area}</h4>
                            </div>
                            <br />
                            <div className="address-details">
                              <p style={{ fontSize: "14px",lineHeight: '5px' }}>{add.address},{add.street}{add.apartment_no ? <span>, Apartment {add.apartment_no}</span>: ''}</p>
                              <p style={{ fontSize: "14px",lineHeight: '5px' }}>{add.mobile}</p>
                              <br />
                            </div>
                          </div>
                        }
                      </div>
                    );
                  })} */}
                  {/* </select> */}
                  <hr />
                  <h4 style={{ fontSize: '16px' }}>Promo Code (optional)</h4>
                  <div className="display-flex">
                    <input
                      className="promo-input"
                      value={promoInput}
                      onChange={(e) => {
                        setPromoInput(e.target.value);
                      }}
                    ></input>
                    {promoLoading ? (
                      <Loader />
                    ) : (
                      <button
                        className="btn btn-primary left-space"
                        onClick={handleApplyPromo}
                        style={{ fontSize: '14px' }}
                      >
                        Apply
                      </button>
                    )}
                  </div>
                  {appointmentData?.promoDiscount ? (
                    <p style={{ color: "green", fontSize: "80%" }}>
                      {"Discount of "}
                      {appointmentData?.currency}{" "}
                      {appointmentData?.promoDiscount}
                      {" applied successfully"}
                    </p>
                  ) : (
                    ""
                  )}
                  {promoError ? (
                    <p style={{ color: "red", fontSize: "80%" }}>
                      {"Promo couldn't be applied. Try a different code!"}
                    </p>
                  ) : (
                    ""
                  )}
                  <hr />
                  <div className="phone">
                    <h4 style={{ marginRight: "10px", fontSize: '16px' }}>Phone Number</h4>
                    <div className="display-flex">
                      <input
                        type="text"
                        style={{ width: "8%", height: "35px", fontSize: '14px' }}
                        value={mobileCountryCode}
                        onChange={handleMobileCountryCodeChange}
                      />
                      <input
                        type="number"
                        className="formSelect-input-style"
                        style={{ width: "20%", marginLeft: "1%", fontSize: '14px' }}
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="payment">
                    <h4 style={{ fontSize: '16px' }}>Payment</h4>
                    <div>
                      {appointmentData?.rhMembershipValid && (
                        <div className="payment-cards">
                          <input
                            type="radio"
                            style={{ width: "8%", fontSize: '14px' }}
                            onClick={(e) => {
                              setPaymentMode("rh");
                              if (isCashPrev) {
                                updateAppointmentData("totalPrice", appointmentData?.totalPrice - checkoutData?.cod_charges)
                                setIsCashPrev(false)
                              }
                            }}
                            checked={paymentMode === "rh" ? true : false}
                          />
                          <p style={{ marginTop: "5%", marginLeft: "5%", fontSize: '14px' }}>
                            RH Club Benefits{" ("}
                            {appointmentData?.currency}{" "}
                            {credits?.totalRemainingRHCredits} {")"}
                          </p>
                          <br />
                        </div>
                      )}

                      <div className="payment-cards">
                        <input
                          type="radio"
                          style={{ width: "8%", fontSize: '14px' }}
                          onClick={(e) => {
                            setPaymentMode("wallet");
                            setIsWallet(true);
                            setIsNewCard(false);
                            if (isCashPrev) {
                              updateAppointmentData("totalPrice", appointmentData?.totalPrice - checkoutData?.cod_charges)
                              setIsCashPrev(false)
                            }
                          }}
                          checked={isWallet}
                          disabled={
                            credits?.total_wallet_credits > 0 ? false : true
                          }
                        />
                        <p style={{ marginTop: "5%", marginLeft: "5%", fontSize: '14px' }}>
                          Wallet {"("}
                          {appointmentData?.currency}{" "}
                          {Number(credits?.total_wallet_credits).toFixed(2)}
                          {")"}
                        </p>
                        <br />
                      </div>
                      <div className="payment-cards">
                        <input
                          type="radio"
                          style={{ width: "8%", fontSize: '14px' }}
                          onClick={(e) => {
                            setPaymentMode("cash");
                            setPayType("cod");
                            setIsNewCard(false);
                            setIsWallet(false);
                            setIsCashPrev(true);
                            updateAppointmentData(
                              "totalPrice",
                             appointmentData?.totalPrice + checkoutData?.cod_charges
                            );
                          }}
                          checked={paymentMode === "cash" ? true : false}
                        />
                        <p style={{ marginTop: "5%", marginLeft: "5%", fontSize: '14px' }}
                        
                        >
                          Cash{" "} 
                          {checkoutData?.cod_charges > 0 && (
                            <span>
                              (Pay extra {appointmentData?.currency} {Number(checkoutData?.cod_charges).toFixed(2)})
                            </span>
                          )}
                        </p>
                        <br />
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "5%",
                        }}
                      >
                        <h4 style={{ fontSize: '16px' }}>Saved Cards</h4>
                      </div>
                      {
                        <div style={{ marginBottom: "3%" }}>
                          <div className="payment-cards">
                            <input
                              id={"new-card"}
                              type="radio"
                              style={{ width: "8%", fontSize: '14px' }}
                              onChange={handlePaymentChange}
                              checked={
                                isNewCard && !isWallet && paymentMode !== "cash"
                              }
                              value={"new-card"}
                            />
                            <p style={{ marginLeft: "5%", fontSize: '14px' }}>Use a New Card</p>
                          </div>
                        </div>
                      }
                      {!isFatoorah &&
                        stripeCustomerCards?.map((sc, i) => {
                          return (
                            <div style={{ marginBottom: "3%" }}>
                              <div className="payment-cards">
                                <input
                                  id={`cc-${sc?.card.number}`}
                                  type="radio"
                                  style={{ width: "8%", fontSize: '14px' }}
                                  onChange={handlePaymentChange}
                                  value={JSON.stringify(sc)}
                                  disabled={isWallet}
                                  checked={
                                    appointmentData?.paymentCardToken ===
                                    sc?.token
                                      ? paymentMode === "card"
                                        ? true
                                        : false
                                      : false
                                  }
                                />
                                <img
                                  style={{ width: "50px", height: "auto" }}
                                  src={
                                    sc?.card.cardType === 1
                                      ? cardBrandLogos["visa"]
                                      : sc?.card.cardType === 2
                                      ? cardBrandLogos["mastercard"]
                                      : "/images/account-credits.webp"
                                  }
                                  alt="account-credits"
                                />
                                <p style={{ marginLeft: "5%", fontSize: '14px' }}>
                                  **** {sc?.card.cardNumber}
                                </p>
                              </div>
                              <span style={{ marginLeft: "12%", fontSize: '14px' }}>
                                Expires {sc?.card.cardExpiry}
                              </span>
                            </div>
                          );
                        })}
                      {isFatoorah &&
                        fatoorahCustomerCards?.map((sc, i) => {
                          return (
                            <div style={{ marginBottom: "3%" }}>
                              <div className="payment-cards">
                                <input
                                  id={`cc-${sc?.card.cardNumber}`}
                                  type="radio"
                                  style={{ width: "8%", fontSize: '14px' }}
                                  onChange={handlePaymentChange}
                                  value={JSON.stringify(sc)}
                                  disabled={isWallet}
                                  checked={
                                    appointmentData?.paymentCardToken ===
                                    sc?.token
                                      ? paymentMode === "card"
                                        ? true
                                        : false
                                      : false
                                  }
                                />
                                <img
                                  style={{ width: "50px", height: "auto" }}
                                  src={
                                    sc?.card.cardType === "Visa"
                                      ? cardBrandLogos["visa"]
                                      : sc?.card.cardType === "Master"
                                      ? cardBrandLogos["mastercard"]
                                      : "/images/account-credits.webp"
                                  }
                                  alt="account-credits"
                                />
                                <p style={{ marginLeft: "5%", fontSize: '14px' }}>
                                  **** {sc?.card.cardNumber}
                                </p>
                              </div>
                            </div>
                          );
                        })}

<div className="checkboxItem paymentLabel ">
                  <label>
                    <input
                      type="checkbox"
                      className="me-2 "
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        updateAppointmentData(
                          "payment_terms_condition",
                          e.target.checked
                        );
                      }}
                    />
                    {t("understand_that_purchasing")}{" "}
                    <Link to={"/terms/conditions"} target="_blank">
                      {t("terms_of_use")}
                    </Link>{" "}
                    {t("and")}{" "}
                    <Link to={"/cancellation/policy"} target="_blank">
                      {t("cancellation_policy")}
                    </Link>{" "}
                    {t("my_payment_method_charged_before_service")}
                  </label>
                </div>
                {
                  <button
                    className="booking-continue-button btn-continue initial-height btn btn-primary"
                    style={{ marginLeft: "50%" }}
                    onClick={handleBooking}
                  >
                    Book Now
                  </button>
                }
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div
                className="booking-summary-details"
                style={{ marginTop: "4%" }}
              >
                <div
                  className="background-white-shadow"
                  style={{ marginTop: "0%" }}
                >
                  <br />
                  <h3
                    style={{ marginBottom: "0px", marginTop: "4%" }}
                    className="left-space"
                  >
                    {checkoutData?.service?.name}
                  </h3>
                  <br />
                  <p style={{ marginTop: "0px" }} className="left-space">
                    {formatDate(checkoutData?.appointment_date)} @{" "}
                    {checkoutData?.time_slot} (for {checkoutData?.hours} hours)
                  </p>
                  <hr />
                  <div className="left-space">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {appointmentData?.service?.ui_type === "Package" ? (
                        <div className="d-flex justify-content-between">
                          <p>Service Charge</p>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                              marginLeft: "8px",
                              cursor: "pointer",
                              fontSize: "70%",
                            }}
                            onClick={() => {
                              setShowTotalSummary(true);
                            }}
                          />
                        </div>
                      ) : (
                        <p>Service Charge</p>
                      )}

                      <p style={{ marginRight: "1%" }}>
                        {appointmentData?.currency}{" "}
                        {appointmentData?.service?.ui_type === "Package"
                          ? Number(appointmentData?.service_price).toFixed(2)
                          : Number(
                              appointmentData?.price
                                ? appointmentData?.price
                                : 0
                            ).toFixed(2)}
                      </p>
                    </div>
                    {checkoutData?.is_cleaning_materials && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>Materials charges</p>
                          <p style={{ marginRight: "1%" }}>
                            {appointmentData?.currency}{" "}
                            {Number(
                              appointmentData?.totalMaterialPrice
                            ).toFixed(2)}
                          </p>
                        </div>
                        <hr />
                      </div>
                    )}
                    {checkoutData?.slot_price ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        TimeSlot Charge{" "}
                        <span
                          style={{ height: "fit-content", marginRight: "1%" }}
                        >
                          {appointmentData?.currency}{" "}
                          {Number(checkoutData?.slot_price).toFixed(2)}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                    {paymentMode === "cash" && checkoutData?.cod_charges > 0 && (
                      (
                        <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Cod Charge{" "}
                        <span
                          style={{ height: "fit-content", marginRight: "1%" }}
                        >
                          {appointmentData?.currency}{" "}
                          {Number(checkoutData?.cod_charges).toFixed(2)}
                        </span>
                      </p>
                      )
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Vip Discount</p>
                      <p style={{ marginRight: "1%" }}>
                        - {appointmentData?.currency}{" "}
                        {Number(
                          appointmentData?.vipDiscountPrice
                            ? appointmentData?.vipDiscountPrice
                            : 0
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Promo Discount</p>
                      <p style={{ marginRight: "1%" }}>
                        - {appointmentData?.currency}{" "}
                        {Number(
                          appointmentData?.promoDiscount
                            ? appointmentData?.promoDiscount
                            : 0
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Total Discount</p>
                      <p style={{ marginRight: "1%" }}>
                        - {appointmentData?.currency}{" "}
                        {Number(
                          appointmentData?.totalDiscount
                            ? appointmentData?.totalDiscount
                            : 0
                        ).toFixed(2)}
                      </p>
                    </div>
                    {/* <p>RH Club Credits <span style={{marginLeft: '8em', height:'fit-content'}}>-$165.00</span></p> */}
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: "25px" }}>Total</p>
                      <p style={{ marginRight: "1%", fontSize: "25px" }}>
                        {appointmentData?.currency}{" "}
                        {appointmentData?.service?.ui_type === "Package"
                          ? totalAmount
                          : Number(appointmentData?.totalPrice).toFixed(2)}
                      </p>
                    </div>
                    <br />
                  </div>
                </div>
                <br />

              </div>
            </div>
          </div>
        </div>
      </div>
      <AddressComponent
        isAddAddress={isAddAddress}
        setIsAddress={setIsAddress}
      />
      {/* <Modal
        show={addNewCard}
        onHide={() => setAddNewCard(false)}
        id="addVocher"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("add_new_card")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCardModal handleAddNewCardClose={handleAddNewCardClose} />
        </Modal.Body>
      </Modal> */}

      <Modal
        show={showTotalSummary}
        onHide={hideTotalSummary}
        className="totalPriceSummary"
        id="totalPriceSummary"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">Total Summary</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h4>Selected Services</h4>
            <ul>
              {[...checkoutData?.selected_services.entries()].map(
                ([key, value]) => {
                  return (
                    <>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {value}
                          {" X "}
                          {key.name}
                        </p>
                        <p>
                          {checkoutData?.currency}{" "}
                          {Number(value * key.package_new_price).toFixed(2)}
                        </p>
                      </li>
                    </>
                  );
                }
              )}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <h4>Total</h4>
          <h4>
            {checkoutData?.currency}{" "}
            {Number(checkoutData?.service_price).toFixed(2)}
          </h4>
        </Modal.Footer>
      </Modal>
      <Footer />
      <SuccessBooking
        bookingSuccessModal={bookingSuccessModal}
        appointment={bookedAppt}
        bookingData={bookedAppt}
      />
    </>
  );
};

export default AppointmentSummary;
