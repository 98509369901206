import * as React from "react";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import countries from "../../components/CountryCodes.json";
import {getEditData, saveForm, saveFormMultipart} from "../../helpers/helpers";
import {useEffect, useState, useRef} from "react";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import _ar from "date-fns/locale/ar";
import _es from "date-fns/locale/es";
import axios from "axios";
import {authHeader, handlingAuthErrors} from "../../helpers/helpers";
import {useNavigate} from "react-router-dom";
const moment = require("moment");

let displayContent = {
  displayNone: {
    display: "none"
  },
  displayBlock: {
    display: "block"
  }
};

const currentUser =
  localStorage.getItem("currentUser") &&
  localStorage.getItem("currentUser") !== "null"
    ? JSON.parse(localStorage.getItem("currentUser"))
    : false;
const ProfileUpdate = () => {
  // const navigate = useNavigate();
  const [file, setFile] = useState();
  const otpRef = useRef();
  const mobileRef = useRef();
  // const [mobile, setMobile] = useState("");
  // const [otp, setOtp] = useState("");
  const [isMobileValid, setIsMobileValid] = useState(true);

  let object1Url;
  function handleUpload(event) {
    setFile(event.target.files[0]);
    object1Url = URL.createObjectURL(event.target.files[0]);
    setProfileData({...profileData, profile_image: object1Url});
  }
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const [customer, setCustomer] = useState();
  const {t} = useTranslation();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showEmailOtpModal, setShowEmailOtpModal] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    country_code: "",
    mobileNumber: "",
    dob: new Date(),
    gender: "",
    referral_code: "",
    profile_image: "",
    isEmailVerified: false,
    isMobileVerified: false
  });
  const handleOtpModalShow = () => setShowOtpModal(true);
  const handleOtpModalClose = () => {
    setShowOtpModal(false);
  };

  const handleEmailOtpModalShow = () => setShowEmailOtpModal(true);
  const handleEmailOtpModalClose = () => {
    setShowEmailOtpModal(false);
  };
  if (currentLanguageCode === "en") {
    registerLocale("es", _es);
  } else {
    registerLocale("ar", _ar);
  }
  const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  useEffect(() => {
    const listUrl = baseUrl + "/customer/get/profile";
    getCustomerDetails();
    getEditData(listUrl, setCustomer).catch((err) => console.log(err));
  }, []);
  const getCustomerDetails = () => {
    const listUrl = baseUrl + "/customer/get/profile";
    axios({
      method: "GET",
      headers: authHeader(),
      url: listUrl
      // data: data
    })
      .then((res) => {
        setProfileData({
          ...profileData,
          country_code: res.data.user.country_code,
          mobile: res.data.user.mobile,
          name: res.data.user.name,
          email: res.data.user.email,
          gender: res.data.user.gender,
          dob: moment(res?.data?.user?.dob).format("DD/MM/YYYY"),
          referral_code: res.data.user.referral_code,
          profile_image: res?.data?.user?.profile_image,
          isEmailVerified: res?.data?.user?.isEmailVerified,
          isMobileVerified: res?.data?.user?.isMobileVerified
        });
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  };
  const [startDate, setStartDate] = useState("");
  const changeMobileState = (phone) => {
    setProfileData({...profileData, ["country_code"]: phone});
  };
  var dob =
    customer && customer.dob
      ? moment(customer.dob, ["YYYY-MM-DDT00:00:00.000Z"]).format("MM/DD/YYYY")
      : "";
  if (dob) {
    dob = new Date(dob);
  }
  const handelChangeDetails = (e) => {
    const {name, value} = e.target;
    setProfileData({...profileData, [name]: value});
  };
  var timeOut;
  function startOTPTimeOut(){
    if(timeOut) {
      stopOTPTimeOut();
      clearInterval(timeOut);
    }
    var time1 = 30;
    var time2 = 0;
    timeOut = setInterval(function(){
      time2 = time1--;
      jQuery('.startTimer').html("00:"+((time2 < 10) ? '0'+time2:time2));
      if (time2 === 0) {
        clearInterval(timeOut);
        resendEvent();
      }
    }, 1000);
  }
  function stopOTPTimeOut() {
    clearInterval(timeOut);
  }

  const typeOtp = async (e) => {
    // let newOtp = "1234";
    // let otp = jQuery('#otp').val();
    // if(otp.length > 0){
    //     jQuery('.otpContinueBtn').removeAttr("disabled");
    //     jQuery('.otpContinueBtn').removeClass("disabled");
    //     jQuery('.spinner-border').css("display", "block");
    // }else{
    //     jQuery('.spinner-border').css("display", "none");
    //     jQuery('.otpContinueBtn').attr("disabled", "disabled");
    //     jQuery('.otpContinueBtn').addClass("disabled");
    // }
    // if(otp && newOtp === otp && otp.length === 4){
    // jQuery('#newotp').val(otp);
    // let form = document.getElementById("profileForm");
    //     jQuery('.resendOtpError').css("display", "none");
    //     let awaitUrl = baseUrl + "/customer/update/profile";
    //     jQuery('#mobile').val(localStorage.getItem('mobile'));
    //     jQuery('#country_code').val(localStorage.getItem('country_code'));
    //     await saveForm(awaitUrl, form, "post")
    //     .then((response) => {
    //       if(response.data.updateMobile){
    //         handleOtpModalShow();
    //         setInterval();
    //       }else{
    //         localStorage.setItem('currentUser', JSON.stringify(response.data.user));
    //         localStorage.removeItem('mobile');
    //         localStorage.removeItem('country_code');
    //         toast.success(response.data.message, {
    //           position: "top-center",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //         // handleOtpModalClose();
    //         setTimeout(() => {
    //          window.location.href = "/my-account/profile-update"
    //         }, 1000);
    //       }
    //     }).catch(function (error) {
    //       if (error.response) {
    //         if (error.response.status === 419) {
    //           setTimeout(function () {
    //             window.location.reload();
    //           }, 2000);
    //         }
    //       }
    //     });
    // }else{
    //     if((otp.length >= 4) || (otp.length === 4 && newOtp !== otp)){
    //       jQuery('.resendOtpError').css("display", "block");
    //       jQuery('.resendOtpError').html(t('invalid_otp'));
    //     }
    // }
  };

  const resendOtpSend = (e) => {
    jQuery(".resend-otp").css("display", "none");
    jQuery(".resendOtpError").css("display", "none");
    jQuery(".resendOtpError").html("");
    jQuery(".otpSent").css("display", "block");
    jQuery(".otpSent").html(t("otp_sent_on_your_mobile"));
    // startOTPTimeOut();
    sendOtp();

  };

  const resendEmailOtpSend = (e) => {
    jQuery(".resend-otp").css("display", "none");
    jQuery(".resendOtpError").css("display", "none");
    jQuery(".resendOtpError").html("");
    jQuery(".otpSent").css("display", "block");
    jQuery(".otpSent").html(t("otp_sent_on_your_mobile"));
    // startOTPTimeOut();
    sendEmailOtp();

  };

  const resendEvent = (e) => {
    jQuery(".resendOtpError").css("display", "block");
    jQuery(".resend-otp").css("display", "block");
  };
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  var country = userCountryCode.toLocaleLowerCase();
  if (currentUser && currentUser.mobile) {
    const mobileNumber1 = currentUser.mobile;
    const mobileNumber = mobileNumber1.split(" ");
    if (mobileNumber[1] && mobileNumber[1].length > 0) {
      jQuery.each(countries.countries, function (i, value) {
        if (mobileNumber[0] === value.dial_code) {
          var code = value.code;
          country = code.toLowerCase();
          return false;
        }
      });
    }
  }

  const [otpValue, setOtpValue] = React.useState("");

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (file && !validImageTypes.includes(file.type)) {
      toast.error(t("image_only"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      let awaitUrl = baseUrl + "/customer/update/profile";
      const formData = new FormData();
      formData.append("customer_id", currentUser?.id);
      formData.append("profile_image", file);
      formData.append("name", profileData?.name);
      formData.append("email", profileData?.email);
      formData.append("country_code", profileData?.country_code);
      formData.append("mobile", profileData?.mobile);
      formData.append("gender", profileData?.gender);
      var inputDob = jQuery("#dob").val();

      if (inputDob) {
        jQuery("#dob").val(
          moment(inputDob, ["DD/MM/YYYY"]).format("MM/DD/YYYY")
        );
      }

      formData.append("dob", jQuery("#dob").val());
      axios({
        method: "POST",
        headers: authHeader(),
        url: awaitUrl,
        data: formData
      })
        .then((res) => {
          if (res.status === 200) {
            getCustomerDetails();
            toast.success(res?.data?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch((err) => {
          console.log("error: ", err);
          toast.error(err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          handlingAuthErrors(err);
        });
    }
  };

  const handleFormOtpSubmit = (e) => {
    e.preventDefault();
  };
  const sendOtp =() => {
    let data = {
      mobile: profileData?.mobile,
      country_code: profileData?.country_code
        ? !profileData.country_code.includes('+')  ?`+${profileData.country_code}` : profileData.country_code
        : currentUser
        ? currentUser?.country_code
        : null
    };
    let listUrl = baseUrl + "/presignup/send/otp";
    axios({
      method: "POST",
      headers: authHeader(),
      url: listUrl,
      data: data
    })
      .then((res) => {
        if(res?.status === 200){
          handleOtpModalShow();
          startOTPTimeOut();
        }
       
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  }

  const sendEmailOtp =() => {
    let data = {
      email: profileData?.email,
    };
    let listUrl = baseUrl + "/customer/send-otp";
    axios({
      method: "POST",
      headers: authHeader(),
      url: listUrl,
      data: data
    })
      .then((res) => {
        if(res?.status === 200){
          handleEmailOtpModalShow();
          startOTPTimeOut();
        }
       
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  }

  async function getMobileNumberVerify(e) {
    let number = e.target.value;
    setProfileData({...profileData, ["mobile"]: number});
    if (/^\d{10}$/.test(number)) {
      setIsMobileValid(true);
    } else {
      setIsMobileValid(false);
    }
  }
  const handelMobileVerify = async () => {
    if (/^\d{10}$/.test(profileData?.mobile)) {
      setIsMobileValid(true);
      sendOtp();
      // await getOtpHandler(data);
    } else {
      setIsMobileValid(false);
    }
  };

  const handelEmailVerify = async () => {
      sendEmailOtp();
  };


  async function getVerifyOtp() {
    let data = {
      mobile: profileData?.mobile,
      country_code:  profileData?.country_code
      ? !profileData.country_code.includes('+')  ?`+${profileData.country_code}` : profileData.country_code : null,
      otp: otpRef.current.value
    };
    let listUrl = baseUrl + "/presignup/verify/otp";
    axios({
      method: "POST",
      headers: authHeader(),
      url: listUrl,
      data: data
    })
      .then((res) => {
        if(res?.status === 200) {
          handleOtpModalClose();
          setOtpValue('')
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
       
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  }

  async function getEmailVerifyOtp() {
    let data = {
      email: profileData?.email,
     otp: otpRef.current.value
    };
    let listUrl = baseUrl + "/customer/verify-otp";
    axios({
      method: "POST",
      headers: authHeader(),
      url: listUrl,
      data: data
    })
      .then((res) => {
        if(res?.status === 200) {
          handleEmailOtpModalClose();
          setOtpValue('')
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
       
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  }

  return (
    <>
      <Header />
      <HelmetProvider>
        <Helmet>
          <title>RightHands | Update Profile</title>
        </Helmet>
      </HelmetProvider>
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="heading">
            <Link to="/my-account/account-details">
              <h5>{t("account_details")}</h5>
            </Link>
            <h2>{t("profile")}</h2>
          </div>
          <div className="userInfo mb-3">
            <div className="profileImage">
              <img
                src={
                  profileData?.profile_image ? profileData?.profile_image : "/images/user-placeholder.webp"
                }
                alt=""
              />
              <div className="file-upload">
                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  onChange={handleUpload}
                  className="formControl"
                  placeholder="Password *"
                />
                <i className="fa fa-edit"></i>
              </div>
            </div>
            <h2>{profileData && profileData?.name ? profileData?.name : ""}</h2>
          </div>
          <div className="editPersonalDetail">
            <div className="card">
              <div className="personalForm">
                <div id="profileForm">
                  <div className="d-flex">
                <div className="col-lg-12 inputdiv" style={{ width: "15%", marginRight: "5%" }}>
                    <label>{t("gender")}</label>
                    <select
                      className="form-select pl-0 genderSelect"
                      name="gender"
                      id="gender"
                      onChange={(e) => handelChangeDetails(e)}
                      value={
                        profileData?.gender
                          ? profileData?.gender
                          : "Rather Not Say"
                      }
                    >
                      <option
                        value={"Mr."}
                        selected={
                          profileData?.gender
                            ? profileData?.gender
                            : "Rather Not Say"
                        }
                      >
                        {t("gender_mr")}
                      </option>
                      <option
                        value={"Ms."}
                        selected={
                          customer &&
                          customer.gender &&
                          customer.gender === "Ms"
                            ? "selected"
                            : ""
                        }
                      >
                        {t("gender_ms")}
                      </option>
                      <option
                        value={"Rather Not Say"}
                        selected={
                          customer &&
                          customer.gender &&
                          customer.gender === "Rather Not Say"
                            ? "selected"
                            : ""
                        }
                      >
                        {t("gender_rather_not_say")}
                      </option>
                    </select>
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputdiv col-lg-12 ">
                    <label>{t("your_full_name")}</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      defaultValue={
                        profileData && profileData?.name
                          ? profileData?.name
                          : ""
                      }
                      value={
                        profileData && profileData?.name
                          ? profileData?.name
                          : ""
                      }
                      onChange={(e) => handelChangeDetails(e)}
                      placeholder={t("your_full_name")}
                      style={{ width: "75%" }}
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                  </div>
                  <div className="d-flex">
                  <div className="inputdiv col-lg-12" style={{ width: "54%", marginRight: "2%" }}>
                    <label>{t("email")}{"   "}</label>
                    <label style={{marginLeft: '5px'}}>
                    {profileData?.isEmailVerified ?
                          <img title={t('verified')} src='/images/verified.png' alt='verified' width='16px'/>
                                        :
                          <img title={t('unverified')} src='/images/unverified.png' alt='unverified' width='16px'/>
                    }
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={
                        profileData && profileData?.email
                          ? profileData?.email
                          : ""
                      }
                      onChange={(e) => handelChangeDetails(e)}
                      className="form-control"
                      placeholder="e.g. name@example.com"
                      readOnly
                    />
                    <span className="removeinput">
                      <i className="fa fa-close"></i>
                    </span>
                    <div className="help-block text-danger"></div>
                  </div>
                  {!profileData?.isEmailVerified ? <button
                    className="btn btn-primary w-half mb-3"
                    onClick={() => handelEmailVerify()}
                    style={{marginTop: '6%', height: 'fit-content', width: '35%'}}
                  >
                    {t("verify_email")}
                  </button>:<p/>}
                  </div>

                  <div className="row loginMobileInput">
                    <div className="d-flex">
                    <label>{t("phone_number")}</label>
                    <label style={{marginLeft: '5px'}}>
                    {profileData?.isMobileVerified ?
                          <img title={t('verified')} src='/images/verified.png' alt='verified' width='16px'/>
                                        :
                          <img title={t('unverified')} src='/images/unverified.png' alt='unverified' width='16px'/>
                    }
                    </label>
                    </div>
                    <div className="inputdiv col-lg-3 ">
                      <ReactPhoneInput
                        inputProps={{
                          name: "country_code",
                          id: "country_code",
                          autoFocus: true,
                          placeholder: t("enter_mobile_number"),
                          readOnly: "readonly"
                        }}
                        localization={currentLanguageCode === "en" ? es : ar}
                        value={
                          profileData?.country_code
                            ? profileData?.country_code
                            : currentUser
                            ? currentUser?.country_code
                            : null
                        }
                        onChange={(phone) => {
                          changeMobileState(phone);
                        }}
                        country={country}
                        countryCodeEditable={false}
                        placeholder=""
                        disableCountryCode={false}
                        containerStyle={{marginTop: "15px"}}
                        searchClass="search-class"
                        searchStyle={{
                          margin: "0",
                          width: "97%",
                          height: "30px"
                        }}
                        enableSearch
                        disableSearchIcon
                      />
                      <input type="hidden" name="otp" id="newotp" />
                      <div className="help-block text-danger"></div>
                    </div>
                    <div className="inputdiv col-lg-9 " style={{ width: "30%" }}>
                      <input
                        type="number"
                        name="mobile"
                        id="mobile"
                        className="form-control mt-3"
                        value={profileData?.mobile ? profileData?.mobile : ""}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          getMobileNumberVerify(e);
                        }}
                        ref={mobileRef}
                        placeholder="33333 3333"
                      />
                      <div className="help-block text-danger">
                        {!isMobileValid
                          ? `Please Enter a Valid ${t("phone_number")}`
                          : ""}
                      </div>
                    </div>
                    {!profileData?.isMobileVerified && (<button
                    className="btn btn-primary w-half mb-3"
                    onClick={() => handelMobileVerify()}
                    style={{ marginTop: '2.5%', height: 'fit-content', width: '35%'}}
                  >
                    {t("verify_phone_number")}
                  </button>)}
                  </div>

                  <div className="inputdiv col-lg-12 ">
                    <label>{t("date_of_birth_optional")}</label>
                    <DatePicker
                      className="form-control"
                      name="dob"
                      id="dob"
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => setStartDate(date)}
                      locale={currentLanguageCode === "en" ? "" : "ar"}
                      selected={startDate ? startDate : dob ? dob : null}
                      minDate={new Date("02-01-1974")}
                      maxDate={new Date("02-29-2020")}
                      placeholderText={t("dd_mm_yyyy")}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <div className="help-block text-danger"></div>
                  </div>

                  <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                    <button
                      onClick={(e) => handleCustomerSubmit(e)}
                      className="btn btn-primary w-full "
                    >
                      {t("update_profile")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  OTP Modal */}
      <Modal show={showOtpModal} onHide={handleOtpModalClose} id="otpVerify">
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("verify_phone_number")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormOtpSubmit}>
            <div className="">
              <label>{t("enter_otp_code_text")}{'   '}{profileData?.mobile}</label>
              <p className="phone-number mb-4 phoneNumberDir"></p>
              <input
                type="text"
                id="otp"
                className="form-control"
                placeholder=""
                maxLength="4"
                ref={otpRef}
                value={otpValue}
                onChange={(e)=> setOtpValue(e.target.value)}
              />
              <p className="mb-0 py-3 resentOtpTime">
                {t("resend_code_in")}:<p className="startTimer">00:30</p>
              </p>
              <p
                className="text-danger resendOtpError"
                style={displayContent.displayNone}
              ></p>
              <p
                className="otpSent text-success"
                style={displayContent.displayNone}
              ></p>
              <p
                className="btn btn-light resend-otp"
                onClick={resendOtpSend}
                style={displayContent.displayNone}
              >
                {t("resend_code_btn")}
              </p>
              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full  otpContinueBtn"
                  onClick={() => getVerifyOtp()}
                  disabled=""
                >
                  {t("Verify")}
                </button>
                <div
                  className="spinner-border text-dark "
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Email OTP Modal */}
      <Modal show={showEmailOtpModal} onHide={handleEmailOtpModalClose} id="otpVerify">
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("verify_email")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormOtpSubmit}>
            <div className="">
              <label>{t("enter_otp_code_text")}{'   '}{profileData?.email}</label>
              <p className="phone-number mb-4 phoneNumberDir"></p>
              <input
                type="text"
                id="otp"
                className="form-control"
                placeholder=""
                maxLength="4"
                ref={otpRef}
                value={otpValue}
                onChange={(e)=> setOtpValue(e.target.value)}
              />
              <p className="mb-0 py-3 resentOtpTime">
                {t("resend_code_in")}:<p className="startTimer">00:30</p>
              </p>
              <p
                className="text-danger resendOtpError"
                style={displayContent.displayNone}
              ></p>
              <p
                className="otpSent text-success"
                style={displayContent.displayNone}
              ></p>
              <p
                className="btn btn-light resend-otp"
                onClick={resendEmailOtpSend}
                style={displayContent.displayNone}
              >
                {t("resend_code_btn")}
              </p>
              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full  otpContinueBtn"
                  onClick={() => getEmailVerifyOtp()}
                  disabled=""
                >
                  {t("Verify")}
                </button>
                <div
                  className="spinner-border text-dark "
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default ProfileUpdate;
