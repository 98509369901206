/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React, {useState, useEffect, forwardRef, useRef} from "react";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
//import LoginSignupModal from "./LoginSignupModal";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import {countryListAction} from "../actions/userActions";
import jQuery from "jquery";
import {useDispatch, useSelector} from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Axios from "axios";
import Geocode, {setKey} from "react-geocode";
// import Loader from "../components/Loader";
import {getHomeDataAction, getHomeMainService} from "../actions/homeAction";
import {ValidateCountry} from "../helpers/bookingAPIsteps";
import MapSearch from "./MapSearch";

// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
const NavMenu = forwardRef((props, ref) => {
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const {t} = useTranslation();
  const loginSignupRef = useRef();
  const dispatch = useDispatch();
  let countryList = baseUrl + "/country/list";
  useEffect(() => {
    dispatch(countryListAction(countryList, {}));
  }, [countryList]);

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const {country_list} = getAllCountryState;
  const [countryData, setCountryData] = useState("");
  // const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [currentCountryCode, setCurrentCountryCode] = useState("QA");
  const [locationSearch, setLocationSearch] = useState("")

  let selectedCountryId = "";

  async function changeLanguage() {
    var selectedLanguage = "";
    if (currentLanguage && currentLanguage === "ar") {
      selectedLanguage = "en";
    } else {
      selectedLanguage = "ar";
    }
    localStorage.setItem("currentLanguage", selectedLanguage);
    i18next.changeLanguage(selectedLanguage);
  }

  const getcountry = (addressList) => {
    let country_name = "";
    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i].types[0]) {
        for (let j = 0; j < addressList[i].types.length; j++) {
          if (addressList[i].types[j] === "country") {
            country_name = addressList[i].address_components[0];
            return country_name;
          }
        }
      }
    }
  };
  const getCountryFlagName = async () => {
    if (countryData) {
      let countryList = countryData?.find((f) => f.name === countryName);
      if (countryList?._id) {
        setCountryId(countryList?._id);

        setCurrentCountryCode(countryList.country_flag);
        // localStorage.removeItem("userCountryId");
        // localStorage.removeItem("userCountryCode");
        // localStorage.removeItem("currentCountryCode");

        let listMainUrl =
          baseUrl + `/home/dashboard?country_id=${countryList?._id}`;
        dispatch(getHomeMainService(listMainUrl));
        localStorage.setItem("userCountryId", countryList._id);
        localStorage.setItem("userCountryCode", countryList.country_flag);
        localStorage.setItem("currentCountryCode", countryList.country_flag);
      }
      //  setLoading(true);
    }
  };
  const getCountryName = async () => {
    if (localStorage.getItem("defaultLocation") !== "false") {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const {latitude, longitude} = position.coords;
            },

            (error) => {
              if (error.message === "User denied Geolocation") {
                console.log("Geolocation is not supported on your browser!2")
                alert("Please allow location access.");
                window.location.href = "app-settings:location";
              }
            }
          );
        } catch (error) {
          console.log("Error", error);
        }
        navigator.geolocation.getCurrentPosition(async function (position) {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let data = await Axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          )
            .then(async (res) => {
              let countryComponent = await getcountry(res.data.results);
              let newShortName = countryComponent?.short_name;
              let url = baseUrl + `/validate/location`;
              let data = {country_code_short: newShortName};
              const isCountryValid = await ValidateCountry({
                baseUrl: url,
                data
              });
              console.log("isCountryValid", isCountryValid);
              if (isCountryValid) {
                setCountryName(countryComponent?.long_name);
              } else {
                localStorage.setItem(
                  "userCountryId",
                  "636cd9cb43c4dcc60ce2fbd7"
                );
                setCountryName("Qatar");
              }
            })
            .catch((e) => {
              console.log("Error", e);
            });
        });
      }
    }
  };

  const country_list_data = async () => {
    await Axios({
      method: "GET",
      url: baseUrl + "/country/list"
    }).then((res) => {
      if (res?.data?.list?.length > 0) {
        setCountryData(res.data.list);
      }
    });
  };
  useEffect(() => {
    if (countryId) {
      localStorage.setItem("userCountryId", countryId);
    }
    if (localStorage.getItem("userCountryCode")) {
      setCurrentCountryCode(localStorage.getItem("userCountryCode"));
    } else {
      localStorage.setItem("userCountryId", "636cd9cb43c4dcc60ce2fbd7");
      localStorage.setItem("userCountryCode", "QA");
      setCountryName("Qatar");
    }
  }, [countryId]);

  useEffect(() => {
    getCountryName();
  }, []);

  useEffect(() => {
    country_list_data();
  }, [countryName]);

  useEffect(() => {
    if (countryData) {
      getCountryFlagName();
    }
  }, [countryData]);

  if (
    localStorage.getItem("userCountryId") &&
    localStorage.getItem("userCountryCode")
  ) {
    selectedCountryId = localStorage.getItem("userCountryId");
  }

  // const getAllCountryState = useSelector(state => state.countryReducer);
  // const { loading, country_list } = getAllCountryState;
  // let countryListUrl = baseUrl+"/country/list";

  const [showCountryModal, setShowCountryModal] = useState(false);
  const handleCountryModalShow = () => setShowCountryModal(true);
  const handleCountryModalClose = () => setShowCountryModal(false);

  const countryInformation = () => {
    handleCountryModalShow();
  };

  async function updateCountry(e) {
    e.preventDefault();

    // const country_and_flag = jQuery('input[name=country_id]:checked').val();
    // const country_id = country_and_flag.substring(0, country_and_flag.indexOf(","));
    // const country_code = country_and_flag.split(',')[1].trim();

    const country_and_flag = jQuery("input[name=country_id]:checked").val();
    const country_id = country_and_flag.substring(
      0,
      country_and_flag.indexOf(",")
    );
    const country_code = country_and_flag.split(",")[1].trim();
    setCurrentCountryCode(country_code);
    // if (country_list?.length > 0) {
    //     let country_obj = country_list.find(o => o.country_flag === currentCountryCode);
    // }
    if (country_id) {
      let listUrl = baseUrl + "/home/services";
      dispatch(getHomeDataAction(listUrl));
    }
    setTimeout(() => {
      localStorage.removeItem("userCountryId");
      localStorage.removeItem("userCountryCode");
      localStorage.setItem("userCountryId", country_id);
      localStorage.setItem("defaultLocation", "false");
      localStorage.setItem("userCountryCode", country_code);
      localStorage.setItem("currentCountryCode", country_code);
      window.location.reload();
    }, 200);
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(country_list?.length > 0){
  //       let country_obj = country_list.find(o => o.country_flag === localStorage.getItem('currentCountryCode'));
  //       if(country_obj && country_obj?.isActive){
  //         localStorage.setItem('userCountryId',country_obj._id);
  //         localStorage.setItem('userCountryCode',country_obj.country_flag);
  //         localStorage.setItem('currentCountryCode',country_obj.country_flag);
  //       }
  //     }
  //   }, 1500);
  // }, [country_list]);

  return (
    <div>
      {/* {loading ? <Loader /> : ""} */}
      <LoginSignupModal ref={loginSignupRef} />

      <nav
        className="navbar navbar-expand-lg navbar-light bg-light py-2"
        id="navFixed"
      >
        <div className="container">
          <Link
            to="/"
            className="navbar-brand p-0 d-none d-md-block"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img alt="" src="/images/logo.webp" />
          </Link>
          <Link
            to="/services"
            className="navbar-brand p-0 d-none d-md-block"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p>All Services</p>
          </Link>
          <Link
            to="/blog"
            className="navbar-brand p-0 d-none d-md-block"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p>Blog</p>
          </Link>
          <Link
            to="https://righthandssupport.zendesk.com/hc/en-us"
            className="navbar-brand p-0 d-none d-md-block navItems"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p>Help</p>
          </Link>
          <div>
            {locationSearch && (<MapSearch displayLocationIcon={false} address={locationSearch} style={{ borderRadius: '30px' }}/>)}
          </div>
          <div className="d-flex ms-auto align-items-center">
            <div
              className="loginbtn me-3"
              onClick={() =>
                loginSignupRef.current.handleLoginSignupModalShow()
              }
            >
              {t("login_btn")}{" "}
              <img
                alt=""
                src="https://dvdljkkxpxqo3.cloudfront.net/images/user.webp"
              />
            </div>
            <a className="navbar-brand p-0 d-block w-75 d-md-none" href={"/#"}>
              <img
                alt=""
                src="https://dvdljkkxpxqo3.cloudfront.net/images/logo.webp"
                className="w-100"
              />
            </a>
            <div className="roundedCircle d-flex align-items-center px-1 me-2">
              <Link
                to="/"
                className="pe-2 d-block d-md-none"
                onClick={() => window.scrollTo(0, 0)}
              >
                Help
              </Link>

              <ReactCountryFlag
                svg
                style={{fontSize: "1.5em", lineHeight: "1.5em"}}
                countryCode={currentCountryCode}
                onClick={() => countryInformation()}
              />
              <div className="language ms-3">
                <div className="selectedLang" onClick={changeLanguage}>
                  {currentLanguage && currentLanguage === "ar"
                    ? "English"
                    : "عربي"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Modal
        show={showCountryModal}
        onHide={handleCountryModalClose}
        className="changeCountry"
        id="changeCountry"
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">{t("select_your_country")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {country_list?.length > 0
              ? country_list.map((country, index) => {
                  return (
                    <div key={index}>
                      {country?.isActive && (
                        <label className="w-full languageChecked" key={index}>
                          <p>
                            {currentLanguageCode === "en"
                              ? country?.name
                              : country?.name_arabic}
                          </p>
                          <input
                            type="radio"
                            value={country?.id + "," + country?.country_flag}
                            name="country_id"
                            className="language"
                            defaultChecked={country?.id === selectedCountryId}
                          ></input>
                          <i className="fa fa-check"></i>
                        </label>
                      )}
                    </div>
                  );
                })
              : ""}
            <button
              type="button"
              className="btn btn-primary w-full mt-2"
              onClick={(e) => updateCountry(e)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default NavMenu;
