import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getHomeMainService } from "../actions/homeAction";
import { useEffect, useState } from "react";
import MapSearch from "./MapSearch";

const HomeServices = () => {
  const { t } = useTranslation();
  const countryId = localStorage.getItem("userCountryId");
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services } = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const [serviceID, setServiceID] = useState("");

  useEffect(() => {
    const countryId = localStorage.getItem("userCountryId");
    let listMainUrl = baseUrl + `/home/dashboard?country_id=${countryId}`;

    if (countryId) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, [countryId]);

  useEffect(() => {
    const homeCleaningService = services
      ?.flatMap((service) => service.serviceTypes)
      .find((type) => type.name === "Home Cleaning");

    if (homeCleaningService) {
      setServiceID(homeCleaningService._id);
    }
  }, [services]);

  return (
    <div className="homeServices">
      <div className="container" style={{ marginTop: '1%', marginBottom: '1%' }}>
        <div className="homeservice-para1" style={{  lineHeight: '0px' }}>
          <h1>{t("tagline")}</h1>
        </div>
        {/* <div style={{ maxWidth: "37rem" }} >
          <MapSearch short={false} />
        </div> */}
        <div className="servicesSection">
          <Link
            key={serviceID}
            className="d-flex home-cleaning"
            to={`/service/${"home-cleaning"
              .replace(/\s+/g, "-")
              .toLowerCase()}-${serviceID}`}
          >
            <div className="serviceIcon" style={{ backgroundColor: "#8cc43c" }}>
              <img
                src="./images/cleaning.png"
                title="Home Cleaning"
                alt="Home Cleaning"
                style={{ width: "35%" }}
              />
            </div>
            <div className="service-Link">{t("home_cleaning")}</div>
          </Link>
          <Link className="d-flex all-services-block" to="/services">
            <div className="serviceIcon" style={{ backgroundColor: "#44d4bc" }}>
              <img
                src="./images/all-services.png"
                title="All Services"
                alt="All Services"
                style={{ width: "35%" }}
              />
            </div>
            <div className="service-Link">{t("all_services")}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
