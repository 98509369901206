import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import jQuery from "jquery";
import { getHomeMainService } from "../actions/homeAction";
// import { getHomeMainService} from "../actions/homeAction";

const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  // const getAllServicesState = useSelector((state) => state.footerDataReducer);
  // const { services, banner } = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/home/services";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  // const allStates = useSelector((state) => state);
  const { servicesResult } = getAllServicesState;

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  const [homeCleaningID, setHomeCleaningID] = useState(null);
  // const bookService = (serviceId, ui_type, service) => {
  //   // debugger
  //   if (currentUser) {
  //     if (ui_type === "Frequency" || ui_type === "Wheel") {
  //       navigate("/frequency/checkout/details", {
  //         state: {
  //           serviceId: serviceId,
  //           isBookNow: true,
  //           isLogin: true,
  //           ui_type: ui_type,
  //         },
  //       });
  //     } else if (ui_type === "Package") {
  //       navigate("/");
  //     } else {
  //       navigate("/frequency/checkout/details?step=2", {
  //         state: {
  //           serviceId: serviceId,
  //           isBookNow: true,
  //           isLogin: false,
  //           ui_type: ui_type,
  //         },
  //       });
  //     }
  //   } else {
  //     navigate("/frequency/checkout/details?step=2", {
  //       state: {
  //         serviceId: serviceId,
  //         isBookNow: true,
  //         isLogin: false,
  //         ui_type: ui_type,
  //       },
  //     });
  //   }
  // };

  useEffect(() => {
    const country = country_list?.find(
      (country) => country.country_flag === "QA"
    );
    const countryID = localStorage.getItem("userCountryId")
      ? localStorage.getItem("userCountryId")
      : country?.id;
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, [country_list]);

  useEffect(() => {
    if (servicesResult?.length > 0) {
      const cleaningService = servicesResult.find(
        (service) =>
          service.name.toLowerCase() === "house cleaning" ||
          service.name.toLowerCase() === "home cleaning"
      );

      if (cleaningService) {
        console.log("Found Cleaning Service:", cleaningService);
        setHomeCleaningID(cleaningService._id);
        // Perform any additional actions with the cleaningService object
      } else {
        console.log("Cleaning Service not found");
      }
    }
  },[servicesResult])

  function viewServiceDetails(id) {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    localStorage.setItem("serviceDetailId", id);
    setTimeout(() => {
      navigate("/service-details");
    }, 500);
  }

  function viewStaticContent(id) {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    setTimeout(() => {
      navigate(id);
    }, 500);
  }

  function gotoExternalSite(site) {
    window.open(site, "_blank");
  }

  const servicesResultFirstSix = servicesResult?.slice(0, 6);
  function toTitleCase(str) {
    return str
      .split(" ") // Split string by spaces into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join words back into a single string
  }
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="d-flex justify-content-between flex-wrap">
            {/* <div className="footerLogo">
            </div> */}
            <div className="footerLinks">
              <ul>
                <li>
                  <Link to={`/service/${homeCleaningID}`} onClick={() => window.scrollTo(0, 0)}>
                    {t("superior_customer_service")}
                  </Link>
                </li>
                <li>
                  <a
                    href="/about"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("about")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://righthands.applytojob.com/apply"
                    target="_blank"
                  >
                    {t("career")}
                  </a>
                </li>
                <li>
                  <Link to={"/blog"} onClick={() => window.scrollTo(0, 0)}>
                    {t("blog")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://righthandssupport.zendesk.com/hc/en-us"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("help")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact-us"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("contact_us")}
                  </Link>
                </li>
                <br />
                <br />
                <li>
                  <Link
                    className="d-flex align-items-center px-1"
                    to="/become-pro"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("become_pro")}
                  </Link>
                </li>
                <br />
              </ul>
            </div>
            <div className="footerLinks footerLocation">
              <p style={{color: "#ffffff" }}>{t("location")}</p>
              <ul>
                {country_list?.map((country, i) => {
                  return (
                    <li style={{color: "#ffffff" }} key={country._id}>
                      {currentLanguage === "en"
                        ? country.name
                        : country.name_arabic}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footerLinks footerserviceLinks ">
              <p style={{color: "#ffffff" }}>{t("popular_services")}</p>
              <ul>
                {servicesResultFirstSix?.length > 0
                  ? servicesResultFirstSix.map((service, index) => {
                      return (
                        <li key={index}>
                          <a
                              className="footerLocation"
                            href={`/service/${service.id}`}
                          >
                            {currentLanguage === "en"
                              ? toTitleCase(service?.name)
                              : service?.name_arabic}
                          </a>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
            {/* <div
                className="helpQuote"
                data-bs-toggle="modal"
                data-bs-target="#chatModal"
              >
                 
                   <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote2.png.webp" />
               
              </div> */}
          </div>
          <li style={{ listStyle: "none" }}>
            <div className=" burttonImage mt-5" style={{ width: "30%" }}>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.righthands"
                }
              >
                <img
                  style={{ width: "50%" }}
                  alt="Get it on Google Play"
                  title="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
              <a
                href={
                  "https://apps.apple.com/ae/app/right-hands/id1641289996?itscg=30200&itsct=apps_box_badge&mttnsubad=1641289996"
                }
              >
                <img
                  style={{ width: "40%" }}
                  alt="Download on the App Store"
                  title="Download on the App Store"
                  src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1703030400"
                />
              </a>
            </div>
          </li>
          <li style={{ listStyle: "none", marginTop: "5%" }}>
            <div className="socialIcon">
              <div className="d-flex justify-content-between">
                <Link
                  style={{ marginRight: "5%" }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://www.facebook.com/RightHandService"
                    )
                  }
                >
                  <img
                    src="/images/facebook.png"
                    alt="Facebook icon"
                    title="Facebook"
                    width="40"
                    height="40"
                  ></img>
                </Link>
                <Link
                  style={{ marginRight: "5%" }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://twitter.com/righthandsqa")
                  }
                >
                  <img
                    src="/images/x.png"
                    alt="X icon"
                    title="X"
                    width="40"
                    height="40"
                  ></img>
                </Link>
                <Link
                  style={{ marginRight: "5%" }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://instagram.com/right.hands")
                  }
                >
                  <img
                    src="/images/insta.png"
                    alt="Instagram icon"
                    title="Instagram"
                    width="40"
                    height="40"
                  ></img>
                </Link>
                <Link
                  style={{ marginRight: "5%" }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://in.pinterest.com/righthandsqa/_saved"
                    )
                  }
                >
                  <img
                    src="/images/pintrest.png"
                    alt="Pintrest icon"
                    title="Pintrest"
                    width="40"
                    height="40"
                  ></img>
                </Link>
                <Link
                  style={{ marginRight: "5%" }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "http://www.linkedin.com/in/right-hands-qa"
                    )
                  }
                >
                  <img
                    src="/images/linkedin.png"
                    alt="LinkedIn icon"
                    title="LinkedIn"
                    width="40"
                    height="40"
                  ></img>
                </Link>
              </div>
            </div>
          </li>
          <br />
          <hr style={{ border: "none", borderTop: "2px solid white" }} />
          <div className="footerLinks footerserviceLinks ">
            <br />
            <h1 style={{ fontSize: "150%", color: "#ffffff" }}>
              {t("other_services")}
            </h1>
            <div className="d-flex justify-content-between">
              <ul>
                {servicesResult?.slice(6, 9)?.length > 0
                  ? servicesResult?.slice(6, 9).map((service, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className="footerLocation"
                            to={`/service/${service._id}`}
                          >
                            {currentLanguage === "en"
                              ? toTitleCase(service?.name)
                              : service?.name_arabic}
                          </Link>
                        </li>
                      );
                    })
                  : ""}
              </ul>
              <ul>
                {servicesResult?.slice(9, 12)?.length > 0
                  ? servicesResult?.slice(9, 12).map((service, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className="footerLocation"
                            to={`/service/${service._id}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {currentLanguage === "en"
                              ? toTitleCase(service?.name)
                              : service?.name_arabic}
                          </Link>
                        </li>
                      );
                    })
                  : ""}
              </ul>
              <ul>
                {servicesResult?.slice(12, 14)?.length > 0
                  ? servicesResult?.slice(12, 14).map((service, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className="footerLocation"
                            to={`/service/${service._id}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {currentLanguage === "en"
                              ? toTitleCase(service?.name)
                              : service?.name_arabic}
                          </Link>
                        </li>
                      );
                    })
                  : ""}
                {
                  // <li className="footerLocation" onClick={() =>
                  //   navigate('/services')
                  // }>See all services</li>
                  <li>
                    <span
                      className="footerLocation"
                      onClick={() => navigate("/services")}
                    >
                      See all services
                    </span>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </>
  );
};
export default Footer;
