import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getRhValid, hasStripePayment} from "../../helpers/helpers";
import {BuyOnline} from "../../helpers/bookingAPIsteps";
import {extendMemberShipAction} from "../../actions/extendMembershipAction";

const ExtendAppointments = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const dispatch = useDispatch();
  const [rhCreditDetails, setRhCreditDetails] = useState([]);
  const [wallet, setWallet] = useState();
  const [isWallet, setIsWallet] = useState(false);
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  // const extendedAppointment =
  //   localStorage.getItem("extended_msg") &&
  //   localStorage.getItem("extended_msg") !== "null"
  //     ? localStorage.getItem("extended_msg")
  //     : "Null";
  // localStorage.removeItem("extended_msg");
  // let countrywisePriceUrl = baseUrl + `/appointment/get/category/`;

  async function validRhCredit(id) {
    let data = {
      service_id: id
    };
    await getRhValid(data)
      .then((res) => {
        setRhCreditDetails(res.data);
        // console.log(
        //   "res?.data?.total_wallet_credits",
        //   res?.data?.total_wallet_credits
        // );
        setWallet(res?.data?.total_wallet_credits);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }
  const [checked, setChecked] = React.useState(true);

  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );
  const {appointment} = getAppointmentInfoState;
  const professionals_count = appointment?.professionals_count

  const [extendPrice, setExtendPrice] = React.useState(
    appointment?.extendOption[0]?.price
  );
  useEffect(() => {
    validRhCredit(state?.appointmentService_Id);
  }, [state]);
  // const [extendPrice,setExtendPrice] = React.useState(0);
  const currency = appointment?.countryDetails?.currency;

  const UseWalletComponent = () => {
    return (
      <div className="switchFlex">
        <label className="form-check-label" htmlFor="radio3">
          <img alt="" src="/images/card.png" />
          {t("use_wallet")} <span>{wallet ? wallet : wallet}</span>
        </label>
        <div className="useMyCredit">
          <label className="switch">
            <input
              type="checkbox"
              className="usermycredit PaymentByWallet"
              onChange={(event) => setIsWallet(!isWallet)}
              checked={isWallet}
              disabled={wallet === 0}
            />
            <span className="switchslide round"></span>
          </label>
        </div>
      </div>
    );
  };

  // const app =[{hour: 1, price: 40}, {hour: 2, price: 100}, {hour: 3, price: 55}]
  const [useExtendHour, setUseExtendHour] = useState(1);

  const handlerChange = (extendedHour) => {
    let hour = extendedHour;
    // console.log("  appointment?.extendOption", appointment?.extendOption);
    appointment?.extendOption.forEach((element) => {
      if (element?.hour == hour) {
        setExtendPrice(element?.price);
        setUseExtendHour(element?.hour);
      }
    });
    setChecked(!checked);
  };
  const extendHour = (e) => {
    let extendHours =
      useExtendHour === 1
        ? "1"
        : useExtendHour === 2
        ? "2"
        : useExtendHour === 3
        ? "3"
        : "";
    if (isWallet && wallet > extendPrice * professionals_count) {
      let extendMemberShipUrl =
        baseUrl +
        `/appointment/extend/${state?.appointmentId}?additional_hours=${extendHours}`;
      let values = {
        additional_hours:extendHours,
        isWallet: 1
      };
      dispatch(
        extendMemberShipAction(
          extendMemberShipUrl,
          values,
          // setPageLoading,
          navigate
        )
      );
    } else if (isWallet && wallet < extendPrice * professionals_count) {
      handelPayExtend(
        extendPrice*professionals_count - wallet,
        "extendAppointmentWallet",
        state?.appointmentId,
        extendHours
      );
    } else {
      handelPayExtend(extendPrice * professionals_count, "extendAppointment", state?.appointmentId, extendHours);
    }
    //  setTimeout(() => {
    //       localStorage.setItem('additional_hours',useExtendHour)
    //       navigate('/buy/extendmembership')
    //       window.scroll(0,0);

    // },3000);
  };
  const handelPayExtend = async (price, custom, appointmentId, extendHours) => {
    localStorage.setItem('extended_hours',extendHours);
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`;
    // console.log("onlinePayUrl", price);
    const data = {
      amount: price,
      name: currentUser?.name,
      phone: `${currentUser?.country_code}${currentUser?.mobile}`,
      email: currentUser.email,
      reference_no: appointmentId,
      custom: custom,
      custom1:extendHours,
      isStripe: hasStripePayment(localStorage.getItem("userCountryId"), country_list)
      ? true
      : false
      // custom3: extendHours
    };
    try {
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
      if (stepOneData?.data) {
        window.location.href = stepOneData?.data?.payUrl;
      }
    } catch (error) {
      // Handle errors here
      console.error("stepOneData-error", error);
    }
  };
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <Link to="/my-account/appointment-details">
                {" "}
                <h5>{t("appointment_details")}</h5>
              </Link>
              <h2>{t("extend_appointments")}</h2>
            </div>
            <div className="card">
              <div className="stepsCard ">
                <div className="checkboxSec stepService">
                  <div className="checkboxItem ">
                    <div className="heading">
                      <h3>{t("extend_Hour")}</h3>
                    </div>
                    <div
                      className="buttonItem"
                      style={{background: "none", padding: "0"}}
                    >
                      {appointment?.extendOption?.length > 0
                        ? appointment?.extendOption?.map(
                            (hourExtend, index) => {
                              return (
                                <label key={index}>
                                  <input
                                    type="radio"
                                    value={hourExtend?.hour}
                                    id={"hourExtend_" + index}
                                    name="hoursExtended"
                                    className="form-check-input"
                                    defaultChecked={index === 0}
                                    onChange={(e) =>
                                      handlerChange(hourExtend.hour)
                                    }
                                  />
                                  <span> {hourExtend?.hour}Hr</span>
                                </label>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </div>
                  <div className="checkboxItem">
                    <h4 className="payExtraLabel">
                      {t("Pay_extra_amount")} {currency}{" "}
                      {extendPrice === 0
                        ? appointment?.extendOption[0]?.price
                        : extendPrice}{" "}
                      {t("per_pro")}.{" "}
                      <span className="professionalLabel">
                        <label className="proLabel">{t("professionals_selected")}<span className="labelDetail">{professionals_count}</span></label>
                      </span>
                    </h4>
                    {/* <h4 style={{fontSize:"16px"}}>{t("Pay_extra_amount")} { extendPrice} {t("")}. </h4> */}
                  </div>
                  <UseWalletComponent />
                  <div  className="inputdiv col-lg-12 mt-4 mb-5 pt-4 ">
                    <div
                      // to="/buy/extendmembership"
                      onClick={(e) => extendHour()}

                    >
                      <button disabled={isWallet && wallet > extendPrice* appointment?.professionals_count} className="btn btn-primary w-full mt-4">
                        {t("pay_now")} {currency} {extendPrice === 0
                        ? appointment?.extendOption[0]?.price * appointment?.professionals_count
                        : extendPrice * appointment?.professionals_count}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExtendAppointments;
