import React, {useState} from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {ModifiedToastContainer} from "./ModifiedToastContainer";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {
  loginUserAction,
  loginUserResendOTPAction,
  otpVerificationAction,
  // profileSetupAction,
  addressSetupAction
} from "../actions/userActions";
import LocationSearchModal from "./LocationSearchModal";
import Loader from "./Loader";

let displayContent = {
  displayNone: {
    display: "none"
  },
  displayBlock: {
    display: "block"
  }
};
const SignupModal = ({showSignupModal, hideSignupModal,  implementedFrom, handelMobileLogin}) => {
  const getUserLoginState = useSelector((state) => state.userLoginReducer);
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const {loading} = getUserLoginState;

  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const dispatch = useDispatch();
  // const navigate = useNavigation();
  const {t} = useTranslation();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const handleOtpModalShow = () => setShowOtpModal(true);
  const handleOtpModalClose = () => {
    setShowOtpModal(false);
    if(implementedFrom !== "bookingNotLogin"){
      window.location.reload();
    }
  };
  
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleInfoModalShow = () => setShowInfoModal(true);
  const handleInfoModalClose = () => setShowInfoModal(false);
  const [profileInfoModal, setProfileInfoModal] = useState(false);
  const handleProfileInfoModalShow = () => setProfileInfoModal(true);
  const handleProfileInfoModalClose = () => setProfileInfoModal(false);

  const [appointmentCartModal, setAppointmentCartModal] = useState(false);
  const handleAppointmentCartModalShow = () => setAppointmentCartModal(true);
  const handleAppointmentCartModalClose = () => setAppointmentCartModal(false);

  const [showAddressModal, setShowAddressModal] = useState(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);
  let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/login";
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_arr} = getAllCountryState;

  var timeOut;
  function startOTPTimeOut() {
    if (timeOut) {
      stopOTPTimeOut();
      clearInterval(timeOut);
    }
    var time1 = 59;
    var time2 = 0;
    jQuery(".resentOtpTime").removeClass("timeNotVisible");
    timeOut = setInterval(function () {
      time2 = time1--;
      jQuery(".startTimer").html("00:" + (time2 < 10 ? "0" + time2 : time2));
      if (time2 === 0) {
        clearInterval(timeOut);
        resendEvent();
      }
    }, 1000);
  }

  function stopOTPTimeOut() {
    clearInterval(timeOut);
  }

  const resendOtpSend = (e) => {
    jQuery(".resend-otp").css("display", "none");
    jQuery(".resendOtpError").css("display", "none");
    jQuery(".resendOtpError").html("");
    jQuery(".otpSent").css("display", "block");
    jQuery(".otpSent").html(t("otp_sent_on_your_mobile"));
    jQuery(".resentOtpTime").removeClass("timeNotVisible");
    let frm = document.getElementById("loginForm");
    dispatch(
      loginUserResendOTPAction(
        apiUrl,
        {
          mobile: currentUser.mobile,
          country_code: currentUser.country_code
        },
        loginSuccess
      )
    );
  };

  const resendEvent = (e) => {
    jQuery(".resendOtpError").css("display", "block");
    jQuery(".resend-otp").css("display", "block");
    jQuery(".otpSent").css("display", "none");
    jQuery(".resentOtpTime").addClass("timeNotVisible");
  };

  async function loginSuccess(otp) {
    setTimeout(() => {
      const currentUser =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? true
          : false;
      const newCurrentUser =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? JSON.parse(localStorage.getItem("currentUser"))
          : false;
      if (currentUser) {
        hideSignupModal();
        handleOtpModalShow();
        jQuery("#otp").val(otp);
        jQuery(".otpContinueBtn").removeClass("disabled");
        jQuery(".otpContinueBtn").attr("disabled", false);
        jQuery(".phone-number").html(
          newCurrentUser.country_code + newCurrentUser.mobile
        );
        jQuery(".resend-otp").css("display", "none");
        jQuery(".resendOtpError").css("display", "none");
        jQuery(".resendOtpError").html("");
        jQuery(".resentOtpTime").addClass(" timeNotVisible");
        startOTPTimeOut();
      }
    }, 700);
  }

  const handleFormSubmit = (e) => {
    
    e.preventDefault();
    let mobile = jQuery("#mobile").val();
    let country_code = jQuery("#country_code").val();
    let frm = document.getElementById("loginForm");
    var mobileNumberRange = 10;
    if (country_code === "+974") {
      mobileNumberRange = 8;
    }
    if (mobile && mobile.length === mobileNumberRange) {
      jQuery(".createOtpSubmit").removeClass("disabled");
      jQuery(".createOtpSubmit").removeAttr("disabled");
      dispatch(loginUserAction(apiUrl, frm, loginSuccess));
    } else {
      jQuery(".createOtpSubmit").addClass("disabled");
      jQuery(".createOtpSubmit").attr("disabled", "true");
    }
  };

  const enterMobile = (e) => {
    let mobile = jQuery("#mobile").val();
    let country_code = jQuery("#country_code").val();
    let frm = document.getElementById("loginForm");
    var mobileNumberRange = 10;
    if (country_code === "+974") {
      mobileNumberRange = 8;
    }
    if (mobile && mobile.length === mobileNumberRange) {
      jQuery(".createOtpSubmit").removeClass("disabled");
      jQuery(".createOtpSubmit").removeAttr("disabled");
      dispatch(loginUserAction(apiUrl, frm, loginSuccess));
    } else {
      jQuery(".createOtpSubmit").addClass("disabled");
      jQuery(".createOtpSubmit").attr("disabled", "true");
    }
  };

  async function onOTPVerificationSuccess(token) {
    if(implementedFrom === "bookingNotLogin") {
      const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
        handleOtpModalClose();
      handelMobileLogin(token, currentUserDetail,  setShowOtpModal);
     }else  if (token && token !== null) {
      setTimeout(() => {
        localStorage.setItem("currentToken", JSON.stringify(token));
        if(implementedFrom !== "bookingNotLogin"){
          window.location.reload();
        }
        
      }, 500);
    } else {
    
      handleInfoModalShow();
    }
  }

  const handleFormOtpSubmit = (e) => {
    
    e.preventDefault();
    let otp = e.target.elements.otp.value;
    if (otp && otp.length === 4) {
      let values = {
        otp: otp,
        fcm_token: "website",
        device_type: "website",
        mobile: currentUser.mobile,
        country_code: currentUser.country_code
      };
      let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
      dispatch(
        otpVerificationAction(apiOtpUrl, values, onOTPVerificationSuccess)
      );
    } else {
      let message = !otp ? t("enter_otp") : t("invalid_otp");
      jQuery(".resendOtpError").html(message);
    }
  };

  async function handleProfileModalShow(address, msg) {
    setTimeout(() => {
      handleAddressModalClose();
      handleProfileInfoModalShow();
    }, 1000);
  }

  const typeOtp = (e) => {
    let otp = jQuery("#otp").val();
    if (otp.length === 4) {
      jQuery(".otpContinueBtn").removeClass("disabled");
      jQuery(".spinner-border").css("display", "block");
    } else {
      jQuery(".otpContinueBtn").addClass("disabled");
      jQuery(".spinner-border").css("display", "none");
    }

    if (otp && otp.length === 4) {
      jQuery(".resendOtpError").css("display", "none");
      let values = {
        mobile: currentUser.mobile,
        country_code: currentUser.country_code,
        device_type: "website",
        fcm_token: "website",
        otp: otp
      };
      let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
      dispatch(
        otpVerificationAction(apiOtpUrl, values, onOTPVerificationSuccess)
      );
    } else {
      if (otp.length >= 4 || otp.length === 4) {
        jQuery(".resendOtpError").css("display", "block");
        jQuery(".resendOtpError").html(t("invalid_otp"));
      } else {
        jQuery(".resendOtpError").css("display", "none");
        jQuery(".resendOtpError").html("");
      }
    }
  };

  const handleCustomerSubmit = async (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          localStorage.setItem("latitude", latitude);
          localStorage.setItem("longitude", longitude);

          // Do something with the latitude and longitude, like updating state or making an API request
        },
        (error) => {
          console.error("Error getting location:", error.message);
          console.log("Geolocation is not supported on your browser!4 ")
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
    e.preventDefault();
    let addr = jQuery("#address").val();
    let contry = jQuery("#country").val();
    let lat = jQuery("#latitude").val()
      ? jQuery("#latitude").val()
      : localStorage.getItem("latitude");
    let long = jQuery("#longitude").val()
      ? jQuery("#longitude").val()
      : localStorage.getItem("longitude");
    let country_id = jQuery("#country_id").val()
      ? jQuery("#country_id").val(country_id)
      : localStorage.getItem("userCountryId");
    setTimeout(() => {
      jQuery("#address").val(addr);
      jQuery("#country").val(contry);
      jQuery("#latitude").val(lat);
      jQuery("#longitude").val(long);
      jQuery("#country_id").val(country_id);
    }, 1000);

    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    var message = "";
    if (contry) {
      if (country_arr.includes(contry)) {
        jQuery("#customer_id").val(currentUserDetail.id);
        let frm = document.getElementById("addressSetupForm");
        let apiAddressSetupUrl = apiUrl + "/customer/address/setup";
        dispatch(
          addressSetupAction(
            frm,
            apiAddressSetupUrl,
            handleProfileModalShow,
            currentLanguage
          )
        );
        /* setUserSignUpEmail(currentUserDetail?.email);
        setUserSignUpName(currentUserDetail?.name); */
      } else {
        message = t("currently_not_operate_in_this_area");
      }
    } else {
      message = t("search_area_first");
    }
    if (message !== "") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  /* const handleCustomerSubmit = async (e) => {
      e.preventDefault();
      var country = jQuery('#country').val();
      var message = '';
      if(country){
        if(country === 'IN' || country === 'QA'){
          jQuery('#mobile').val(currentUser.mobile);
          jQuery('#country_code').val(currentUser.country_code);
          let frm = document.getElementById('profileSetupForm');
          let apiProfileSetupUrl = process.env.REACT_APP_LOCAL_API_URL + "/profile/setup";
          dispatch(profileSetupAction(frm, apiProfileSetupUrl,currentLanguage));
        }else{
          message = t('currently_not_operate_in_this_area');
        }
      }else{
        message = t('search_area_first');
      }
      if(message !== ''){
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }; */

  const onChange = (event) => {
    const value = event.target.value;
    let address = jQuery("#address").val();
    let country = jQuery("#country").val();
    let latitude = jQuery("#latitude").val();
    let longitude = jQuery("#longitude").val();
    let country_id = jQuery("#country_id").val();
    setTimeout(() => {
      jQuery("#address").val(address);
      jQuery("#country").val(country);
      jQuery("#latitude").val(latitude);
      jQuery("#longitude").val(longitude);
      jQuery("#country_id").val(country_id);
    }, 1000);
    let inputName = "house_no";
    let inputPlaceholder = t("home");

    if (value == "Apartment") {
      inputName = "apartment_no";
      inputPlaceholder = t("apartment");
    } else if (value == "Office") {
      inputName = "office_no";
      inputPlaceholder = t("office");
    }

    inputPlaceholder = inputPlaceholder + " " + t("number");

    jQuery("#home_number").attr({
      name: inputName,
      placeholder: inputPlaceholder
    });
  };

  return (
    <>
      {/*  Login Modal */}
      {loading ? <Loader /> : ""}
      <Modal show={showSignupModal} onHide={hideSignupModal} id="loginModal">
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="loginHead ">
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center mb-3">{t("sign_up")}</h2>
          <p className="text-center">{t("login_detail")}</p>
          <form
            onSubmit={handleFormSubmit}
            id="loginForm"
            className="mt-4 pt-2"
          >
            <div className="">
              <label className="mb-0">{t("your_mobile_number")}</label>
              <div className="row loginMobileInput">
                <div className="inputgroup phonecodeselect col-lg-5">
                  <ReactPhoneInput
                    inputProps={{
                      className: "login_country_code form-control",
                      name: "country_code",
                      id: "country_code",
                      autoFocus: true,
                      placeholder: t("enter_mobile_number"),
                      readOnly: "readonly"
                    }}
                    country={userCountryCode.toLocaleLowerCase()}
                    localization={currentLanguageCode === "en" ? es : ar}
                    countryCodeEditable={false}
                    placeholder=""
                    disableCountryCode={false}
                    searchClass="search-class"
                    searchStyle={{
                      margin: "0",
                      width: "97%",
                      height: "30px"
                    }}
                    enableSearch
                    disableSearchIcon
                  />
                  <div className="help-block text-danger"></div>
                </div>

                <div className="inputgroup phonecodeselect col-lg-7">
                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    className="form-control login_mobile"
                    onChange={enterMobile}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={t("enter_mobile_number")}
                  />
                  <div className="help-block text-danger"></div>
                </div>
              </div>

              {/* <div className="inputgroup phonecodeselect ">
                  <ReactPhoneInput
                    inputProps={{
                      name: "mobile",
                      id: "mobile",
                      autoFocus: true,
                      placeholder: t('enter_mobile_number'),
                    }}
                    onChange
                    ={enterMobile}
                    country="in"
                    localization={currentLanguageCode === 'en' ? es : ar}
                    containerStyle={{ marginTop: "15px" }}
                    searchClass="search-class"
                    searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                    enableSearch
                    disableSearchIcon
                  />{" "}
                </div>
                <input
                  type="hidden"
                  id="country_code"
                  name="country_code"
                  value=""
                /> */}
              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full disabled createOtpSubmit"
                  disabled="disabled"
                >
                  {t("sign_up")}
                </button>
                <div
                  className="spinner-border text-dark mobileSubmitLoader"
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/*  OTP Modal */}
      <Modal
        show={showOtpModal}
        onHide={handleOtpModalClose}
        id="otpVerify"
        keyboard={false}
      >
        <Modal.Header closeButton id="closeButtonId" className="border-0">
          <Modal.Title>
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormOtpSubmit}>
            <div className="">
              <h2 className="mb-3">{t("otp_verification")}</h2>
              <label>{t("otp_detail")}</label>
              <p className="phone-number mb-4 phoneNumberDir"></p>
              <input
                type="text"
                id="otp"
                className="form-control"
                placeholder=""
                maxLength="4"
                onKeyUp={typeOtp}
              />
              <p className="mb-0 py-3 resentOtpTime ">
                {t("resend_code_in")}:<p className="startTimer">00:59</p>
              </p>
              <p
                className="text-danger resendOtpError"
                style={displayContent.displayNone}
              ></p>
              <p
                className="otpSent text-success"
                style={displayContent.displayNone}
              ></p>

              <div
                className=" resend-otp"
                onClick={resendOtpSend}
                style={displayContent.displayNone}
              >
                {" "}
                <p>Did not receive an OTP?</p>
                <p className="btn btn-light rounded-pill">
                  {" "}
                  {t("resend_code_btn")}
                </p>
              </div>
              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full disabled otpContinueBtn"
                  disabled=""
                >
                  {t("continue_btn")}
                </button>
                <div
                  className="spinner-border text-dark "
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/*  Info Modal */}
      <Modal
        show={showInfoModal}
        onHide={handleInfoModalClose}
        className="addAddressModal"
        id="addAddress"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("search_your_location")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCustomerSubmit} id="profileSetupForm">
            <div className="modal-body">
              <div className="mapContent">
                <div className="inputdiv col-lg-12 mb-0 relative ">
                  <input type="hidden" id="customer_id" name="customer_id" />
                  <input type="hidden" id="latitude" name="latitude" value="" />
                  <input
                    type="hidden"
                    id="longitude"
                    name="longitude"
                    value=""
                  />
                  <input type="hidden" id="country" name="country" value="" />{" "}
                  <input
                    type="hidden"
                    id="country_id"
                    name="country_id"
                    value={localStorage.getItem("userCountryId")}
                  />
                </div>
                <div className="allInputDiv col-lg-12 mb-0 mapToogleDiv addressDetailsHide">
                  <div className="mapToggle toast hide">
                    <button
                      type="button"
                      className="btnclose"
                      data-bs-dismiss="toast"
                    >
                      {" "}
                      <span className="fa fa-angle-down"></span>{" "}
                    </button>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="address_nickname"
                          placeholder={t("address_nickname")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="area"
                          placeholder={t("area")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <label>{t("address_type")}</label>
                        <select
                          className="form-select"
                          name="address_type"
                          onChange={onChange}
                        >
                          <option>{t("address_type")} </option>
                          <option value="Home">{t("home")} </option>
                          <option value="Apartment">{t("apartment")} </option>
                          <option value="Office">{t("office")}</option>
                        </select>
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="street"
                          placeholder={t("street")}
                        />
                        <div className="help-block"></div>
                      </div>
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="building"
                          placeholder={t("building")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="floor"
                          placeholder={t("floor")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="home_number"
                          name="house_no"
                          placeholder={t("number")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1 ">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          placeholder={t("additional_directions")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv ">
                        <div className="row loginMobileInput mb-2">
                          <div className="inputgroup phonecodeselect col-lg-5">
                            <ReactPhoneInput
                              inputProps={{
                                className: "login_country_code form-control",
                                name: "country_code",
                                id: "country_code",
                                autoFocus: true,
                                placeholder: t("enter_mobile_number"),
                                readOnly: "readonly"
                              }}
                              country={userCountryCode.toLocaleLowerCase()}
                              localization={
                                currentLanguageCode === "en" ? es : ar
                              }
                              countryCodeEditable={false}
                              placeholder=""
                              disableCountryCode={false}
                              searchClass="search-class"
                              searchStyle={{
                                margin: "0",
                                width: "97%",
                                height: "30px"
                              }}
                              enableSearch
                              disableSearchIcon
                            />
                            <div className="help-block text-danger"></div>
                          </div>
                          <div className="inputgroup phonecodeselect col-lg-7">
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              className="form-control rounded-pill"
                              placeholder={t("mobile_number")}
                            />
                            <div className="help-block text-danger"></div>
                          </div>
                        </div>
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="landline_number"
                          placeholder={t("landline_number")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                  </div>
                  {/* <p>Delivery location</p>
                    <h3>This location is outside UAE. Would you like to 
                    change your country to Qatar?</h3> */}
                  <button className="btn btn-primary addressSubmit">
                    {t("continue_btn")}
                  </button>
                </div>
              </div>
              <div className="divMap">
                <LocationSearchModal translatedValue={t} saveContinue={true} />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ModifiedToastContainer />
    </>
  );
};

export default SignupModal;
