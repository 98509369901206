import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";
import { useSelector, useDispatch } from "react-redux";
import { getHomeMainService } from "../actions/homeAction";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

const Dropdown = () => {
  const { t } = useTranslation();
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services, loading } = getAllServicesState;
  const [initialServices, setInitialServices] = useState(null);
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    // Only set once, when services are loaded for the first time
    if (!initialServices && services && services.length > 0) {
      setInitialServices(services);
    }
  }, [services, initialServices]);

  useEffect(() => {
    // Fetch categories and their respective services
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);

  if (loading && !services) {
    return <Loader />;
  }
  return (
    <div className="dropdown">
      <div className="dropdown-category navbar-brand p-0 d-none d-md-block">
        <Link to="/services">
          {t("all_services")}{" "}
          <FontAwesomeIcon
            icon={faAngleUp}
            className="down-icon-show"
            style={{ marginLeft: "2px" }}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            className="up-icon-show"
            style={{ marginLeft: "2px" }}
          />
        </Link>
        <div className="dropdown-menu">
          <div
            className="d-flex"
            style={{
              marginTop: "2%",
              marginBottom: "2%",
              flexWrap: "row-wrap",
            }}
          >
            {initialServices?.slice(0, 3).map((service, i) => {
              return (
                <ul>
                  <li
                    style={{
                      fontSize: "14px",
                      color: "#9e6dc8",
                      fontWeight: "bold",
                      cursor: "text",
                    }}
                    key={service._id}
                  >
                    {currentLanguage === "en"
                      ? service.name
                      : service.name_arabic}
                  </li>
                  {service.serviceTypes?.map((service, i) => {
                    return (
                      <li
                        className="li-services"
                        style={{ paddingLeft: "5px" }}
                        key={service._id}
                      >
                        <Link
                          style={{ fontSize: "14px" }}
                          to={`/service/${service.id}`}
                        >
                          {currentLanguage === "en"
                            ? service.name
                            : service.name_arabic}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
          <Link
            to="/services"
            className="booking-continue-button btn-continue initial-height btn btn-primary"
            style={{ width: "30%", marginLeft: "35%" }}
          >
            {t("see_all_services")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
