import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";
const ShowTime = ({
  available_time,
  setBookingDateTime,
  visitTimeInformation,
  options2,
  checkoutData,
  carouselKey
}) => {
  // const {t} = useTranslation();

  // return (
  //   <>
  //     {available_time?.length > 0 ? (
  //       <div className="timeButtons">
  //         <h3>
  //           {t("what_time_to_start")}{" "}
  //           <img
  //             src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
  //             className="static_info"
  //             alt=""
  //             onClick={() => visitTimeInformation()}
  //           />
  //         </h3>
  //         <div className="buttonArea">
  //           <div className="w-full pt-2 ">
  //             <OwlCarousel
  //               className="owl-theme"
  //               key={carouselKey}
  //               items={3}
  //               loop={false}
  //               margin={25}
  //               {...options2}
  //             >
  //               {available_time?.length > 0
  //                 ? available_time?.map((time, index) => {
  //                     // debugger;
  //                     let selectTime = false;
  //                     if (checkoutData?.start_time_between === time?.time) {
  //                       selectTime = true;
  //                     }

  //                     if (
  //                       index === 0 &&
  //                       checkoutData?.start_time_between === ""
  //                     ) {
  //                       setBookingDateTime(
  //                         time?.start_time,
  //                         time?.time,
  //                         "time"
  //                       );
  //                       selectTime = true;
  //                     }
  //                     return (
  //                       <div className="item" key={index}>
  //                         <div
  //                           className="buttonItem"
  //                           onChange={() =>
  //                             setBookingDateTime(
  //                               time?.start_time,
  //                               time?.time,
  //                               time?.totalSlotPrice,
  //                               "time"
  //                             )
  //                           }
  //                         >
  //                           <label>
  //                             <input
  //                               type="radio"
  //                               className="start_time_between_checkbox"
  //                               name="start_time"
  //                               defaultChecked={
  //                                 checkoutData?.start_time_between ===
  //                                 time?.time
  //                               }
  //                               // checked={}
  //                             />
  //                             <span>{time?.time}</span>
  //                           </label>
  //                           {time?.totalSlotPrice > 0 ? (
  //                             <p className="helper-text-item has-margin-top has-text-alternate-dark">
  //                               QAR {time?.totalSlotPrice} EXTRA
  //                             </p>
  //                           ) : (
  //                             ""
  //                           )}
  //                         </div>
  //                       </div>
  //                     );
  //                   })
  //                 : ""}
  //             </OwlCarousel>
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <center className="no_slot_available">{t("no_slot_available")}</center>
  //     )}
  //   </>
  // );
};

export default ShowTime;
