import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHomeMainService } from "../../actions/homeAction";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const HomeAllServices = () => {
  const { t } = useTranslation();
  const [selectedCat, setSelectedCat] = useState(null);
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { loading, services, success } = getAllServicesState;
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  const nameToLogo = {
    "home-cleaning": "images/home-cleaning.webp",
    babysitting: "images/babysitting.webp",
    "trades-jobs": "images/handyman.webp",
    "hospitality-services": "images/healthcare.webp",
    "moving-services": "images/packers_movers.webp",
    "general-cleaning": "images/general_cleaning.webp",
    "dry-cleaning": "images/drycleaning.webp",
    "pest-control": "images/pest_control.webp",
    "bird-control": "images/birdcontrol.webp",
  };

  const findLogoIcon = (serviceName, icon) => {
    switch (serviceName.toLowerCase()) {
      case "home":
      case "home-cleaning":
      case "home cleaning":
      case "house":
      case "house-cleaning":
      case "house cleaning":
        return nameToLogo["home-cleaning"];
      case "babysitting":
      case "baby-sitting":
      case "baby sitting":
      case "baby":
        return nameToLogo["babysitting"];
      case "trades":
      case "trades-jobs":
      case "trades jobs":
      case "odd jobs":
      case "odd-jobs":
      case "odd":
      case "trades & odd jobs":
        return nameToLogo["trades-jobs"];
      case "hospitality":
      case "hospitality-services":
      case "hospitality services":
      case "healthcare":
      case "health-care":
        return nameToLogo["hospitality-services"];
      case "moving":
      case "moving-services":
      case "moving services":
      case "packers":
      case "movers":
      case "packers & movers":
      case "movers & packers":
        return nameToLogo["moving-services"];
      case "general":
      case "general-cleaning":
      case "general cleaning":
      case "cleaning":
        return nameToLogo["general-cleaning"];
      case "dry":
      case "dry-cleaning":
      case "dry cleaning":
      case "laundry":
        return nameToLogo["dry-cleaning"];
      case "pest":
      case "pest-control":
      case "pest control":
        return nameToLogo["pest-control"];
      case "bird":
      case "bird-control":
      case "bird control":
      case "pet":
      case "pet-care":
      case "pet care":
      case "bird & pigeon control":
        return nameToLogo["bird-control"];
      default:
        return icon;
    }
  };

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    // Fetch categories and their respective services
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          RightHands | Reliable Home Services, Cleaning, Babysitting, Pet Care,
          Dry cleaning, Upholstery, Iron clothes & many more | خدمات منزلية موثوقة، تنظيف، جليسة أطفال، رعاية الحيوانات الأليفة، تنظيف جاف، تنجيد، كي الملابس والمزيد
        </title>
        <meta
          name="description"
          content="Looking for reliable home services? We offer professional home cleaning, dry cleaning, babysitting, pet care, and more! Fast, affordable, and hassle-free. Book today!"
        />
        <link rel="canonical" href="https://betaweb.myrighthands.com/" />
      </Helmet>
      <div className="container">
        <div className="home-all-services" style={{ cursor: "pointer" }}>
          {services?.slice(0, 9).map((icon, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "space-evenly",
                alignSelf: "flex-start",
                width: "105px",
              }}
              onClick={()=> {setSelectedCat(icon)}} 
            >
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  overflow: "hidden", // Ensures the image is cropped to a circle
                  border: "1px solid grey", // Border around the circle
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                {/* Replace with an <img src={icon.image} alt={icon.name} /> for image icons */}
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Makes the image cover the circular area
                  }}
                  src={findLogoIcon(icon.name, icon.icon)}
                  alt={currentLanguage === "en" ? icon.name : icon.name_arabic}
                  title={
                    currentLanguage === "en" ? icon.name : icon.name_arabic
                  }
                />
              </div>
              <span style={{ fontSize: "13px" }}>
                {currentLanguage === "en" ? icon.name : icon.name_arabic}
              </span>
            </div>
          ))}
        </div>
        {selectedCat && (<div>
          <button className="btn btn-primary" 
          onClick={() => {setSelectedCat(null); setShowMore(false)}}
          style={{ width: "10%", fontSize: '75%' }}
          >View All</button>
              <h2 style={{ marginTop: "5%" }}>
                {currentLanguage === "en" ? selectedCat.name : selectedCat.name_arabic}
              </h2>
              <div className="services-grid home-services">
                {selectedCat.serviceTypes?.map((service, idx) => (
                  <Link
                    key={service.id}
                    to={`/service/${service.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${service.id}`}
                  >
                    <img
                      src={service.icon_web ? service.icon_web : service.icon}
                      alt={
                        currentLanguage === "en"
                          ? service.name
                          : service.name_arabic
                      }
                      title={
                        currentLanguage === "en"
                          ? service.name
                          : service.name_arabic
                      }
                      className="card-img"
                    />
                    <div className="card-body" style={{ paddingLeft: "0" }}>
                      <h5 className="card-title">
                        {currentLanguage === "en"
                          ? service.name
                          : service.name_arabic}
                      </h5>
                    </div>
                  </Link>
                ))}
              </div>
        </div>)}
        {!selectedCat && <div>
          {services?.slice(0, 3).map((icon, index) => (
            <div>
              <h2 style={{ marginTop: "5%" }}>
                {currentLanguage === "en" ? icon.name : icon.name_arabic}
              </h2>
              <div className="services-grid home-services">
                {icon.serviceTypes?.map((service, idx) => (
                  <Link
                    key={service.id}
                    to={`/service/${service.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${service.id}`}
                  >
                    <img
                      src={service.icon_web ? service.icon_web : service.icon}
                      alt={
                        currentLanguage === "en"
                          ? service.name
                          : service.name_arabic
                      }
                      title={
                        currentLanguage === "en"
                          ? service.name
                          : service.name_arabic
                      }
                      className="card-img"
                    />
                    <div className="card-body" style={{ paddingLeft: "0" }}>
                      <h5 className="card-title">
                        {currentLanguage === "en"
                          ? service.name
                          : service.name_arabic}
                      </h5>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>}
        {!showMore && !selectedCat && (
          <button
            className="btn btn-primary left-space"
            style={{ width: "100%" }}
            onClick={() => {
              setShowMore(true);
            }}
          >
            Show More
          </button>
        )}
        {showMore && !selectedCat && (
          <div>
            {services?.slice(3).map((icon, index) => (
              <div>
                <h2 style={{ marginTop: "5%" }}>
                  {currentLanguage === "en" ? icon.name : icon.name_arabic}
                </h2>
                <div
                  className="services-grid"
                  style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
                >
                  {icon.serviceTypes?.map((service, idx) => (
                    <Link
                      to={`/service/${service.name
                        .replace(/\s+/g, "-")
                        .toLowerCase()}-${service.id}`}
                    >
                      <img
                        src={service.icon_web ? service.icon_web : service.icon}
                        alt={
                          currentLanguage === "en"
                            ? service.name
                            : service.name_arabic
                        }
                        title={
                          currentLanguage === "en"
                            ? service.name
                            : service.name_arabic
                        }
                        className="card-img"
                      />
                      <div className="card-body" style={{ paddingLeft: "0" }}>
                        <h5 className="card-title">
                          {currentLanguage === "en"
                            ? service.name
                            : service.name_arabic}
                        </h5>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default HomeAllServices;
