import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";
import Loader from "../../../../components/Loader";
const ProDates = ({
  selectedSlots,
  options,
  carouselKey,
  getDayFromDate,
  handelSelectedDate,
  selectedDate,
  currentLanguage,
  isProLoad
}) => {
  const {t} = useTranslation();

  const styles = {
    color: "#fff",
    background: "#432375",
    border: "2px solid #432375"
  };
  const styles2 = {
    margin: "0 auto"
  };
  const stylesDate = {
    color: "#999",
    background: "#fff",
    border: "2px solid #999999"
  };

  const disableStyles = {
    color: "#fff",
    background: "#999999",
    border: "2px solid #999999"
  };
  const stylesDateChecked = {
    color: " #000",
    background: "#fcd500",
    border: "2px solid #fcd500"
  };

  const stylesTime = {
    background: "#432375",
    border: "2px solid #432375",
    color: "#fff"
  };

  const settings = {
    ...options,
    infinite: false,
    touchMove: false,
    draggable: false,
    slidesToShow: 3,  // You can show 3 slides at once like in OwlCarousel
    slidesToScroll: 1, // Scroll one slide at a time
    arrows: true,     // Show arrows
    dots: true,       // Show dots for navigation
    autoplay: true,   // Enable autoplay
    autoplaySpeed: 3000,  // Set autoplay speed (in ms)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Adjust for tablet screen size
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Adjust for mobile screen size
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <>
      {isProLoad ? (
        <Loader />
      ) : (
        <div className="datesec">
          <h3>{t("like_your_service")}</h3>
          <Slider {...settings} key={carouselKey} className="owl-theme mt-4">
            <>
              {selectedSlots?.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <>
                      <span className="dia_semana">
                        <label>
                          <input
                            type="radio"
                            name="booking_date"
                            defaultValue={item?.date}
                            onClick={() => handelSelectedDate(item)}
                            defaultChecked={item?.date == selectedDate}
                            disabled={!item?.isAvailable}
                            // defaultChecked={
                            //   item.date ===
                            //   checkoutData?.appointment_date?.month_day
                            // }
                          />
                          <span>
                            {currentLanguage === "en"
                              ? getDayFromDate(item)
                              : getDayFromDate(item)}
                          </span>
                          <span
                            style={
                              item?.isAvailable === false
                                ? disableStyles
                                : item?.date === selectedDate
                                ? stylesDateChecked
                                : stylesDate
                            }
                            className="dia"
                          >
                            {item?.date}
                          </span>
                        </label>
                      </span>
                      <span className="mes">{item?.currentMonth}</span>
                    </>
                  </div>
                );
              })}
            </>
          </Slider>
        </div>
      )}
    </>
  );
};

export default ProDates;
