import axios from "axios";
import { toast } from "react-toastify";
import { authHeader, handlingNonauthErrors } from "../helpers/helpers";

export const updateCountryAction =
  (baseUrl, values) => (dispatch) => {
    dispatch({ type: "POST_UPDATE_LANGUAGE_REQUEST" });
      let countryId= {country_id: values}
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: countryId
    })
      .then((res) => {
        dispatch({
          type: "POST_UPDATE_COUNTRY_SUCCESS",
          payload: {
            countryId : res.data.user.country_id,
             current_language: res.data.user.current_language,
          },
        });
        // changeLanguage(res.data.user.current_language);
      })
      .catch((err) => {
        dispatch({ type: "POST_UPDATE_COUNTRY_FAILED", payload: err });
        // handlingNonauthErrors(err);
      });
  };
