import React, {useState, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {getAppointmentInfoAction} from "../../actions/appointmentAction";
import {rescheduleAppointmentAction} from "../../actions/rescheduleAppointmentAction";
import {getCleanerTimeAvailability} from "../../actions/cleanerTimeAvailability";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
const moment = require("moment");

const ReschduleAppointment = () => {
  const [appointment_date, updateAppointmentDate] = useState("");
  const [appointment_time, updateAppointmentTime] = useState("");
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const {loading, available_time} = getCleanerTimeAvailabilitySlot;
  const {state} = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/appointment/admin/reschedule/slots";

  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );
  const {appointment} = getAppointmentInfoState;
  useEffect(() => {
    const infoUrl = baseUrl + `/appointment/edit/` + state?.appointmentId;
    dispatch(getAppointmentInfoAction(infoUrl));
  }, [state]);

  useEffect(() => {
    if (appointment) {
      setTimeout(() => {
        let booking_date = moment(appointment.appointment_date_start).format(
          "YYYY-MM-DD"
        );
        setBookingDate(booking_date);
        jQuery(".booking_date[value='" + booking_date + "']").prop(
          "checked",
          true
        );
      }, 1000);
    }
  }, [appointment]);

  async function rescheduleMyAppointment() {
    if (appointment_date && appointment_time) {
      let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
      let appointmentDetailsId = state?.appointmentId;
      const cancelUrl =
        baseUrl + `/appointment/reschedule/` + appointmentDetailsId;

      var _appointment_time = appointment_time.split("-");
      var bookingStartTime = _appointment_time[0];
      var values = {
         source_id: "",
         payment_mode: "",
        appointment_date_start: appointment_date + " " + bookingStartTime,
        start_time_between: appointment_time,
        payment_id : ""
      };
      dispatch(rescheduleAppointmentAction(cancelUrl, values, navigate, state));
    }
  }

  const availableDatesInput = [];
  function availableDates() {
    let startDate = moment();
    let endDate = moment().add(30, "days");
    while (endDate.isSameOrAfter(startDate)) {
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
      ];
      var week_day_arabic = days[startDate.day()];
      availableDatesInput.push({
        week_day: startDate.format("dd"),
        week_day_arabic: week_day_arabic,
        month_day: startDate.format("D"),
        month: startDate.format("MMM"),
        date: startDate.format("YYYY-MM-DD")
      });
      startDate = startDate.add(1, "day");
    }
  }
  availableDates();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  function setBookingDate(dt) {
    updateAppointmentTime("");
    var data =
      localStorage.getItem("bookAppointmentDetails") &&
      localStorage.getItem("bookAppointmentDetails") !== "null"
        ? JSON.parse(localStorage.getItem("bookAppointmentDetails"))
        : {};
    data["appointment_date"] = dt;
    localStorage.setItem("bookAppointmentDetails", JSON.stringify(data));
    getTimeSlots(dt);
    setTimeout(() => {
      updateAppointmentDate(dt);
    }, 500);
  }

  function setBookingTime(time) {
    updateAppointmentTime(time);
    var data =
      localStorage.getItem("bookAppointmentDetails") &&
      localStorage.getItem("bookAppointmentDetails") !== "null"
        ? JSON.parse(localStorage.getItem("bookAppointmentDetails"))
        : {};
    data["appointment_time"] = time;
    localStorage.setItem("bookAppointmentDetails", JSON.stringify(data));
  }

  function updateCheckoutData(dt, data) {}

  function getTimeSlots(dt) {
    let dataObject = {
      cleaner_id: "",
      is_auto_assign: true,
      appointment_date: dt,
      frequency_id: "",
      hours_count: 0,
      latitude: "",
      longitude: "",
      customer_id: "",
      address_id: ""
    };
    if (appointment) {
      dataObject = {
        cleaner_id:
          appointment?.cleaners_id?.length > 0 ? appointment.cleaners_id : "",
        // is_auto_assign:appointment?.is_auto_assign ? true : false,
        appointment_date: dt,
        // hours_count:(appointment && appointment?.hours_count > 0 ) ? appointment.hours_count : 4,
        // latitude: (appointment?.latitude)?appointment.latitude: "",
        // longitude: (appointment?.longitude)?appointment.longitude : "",
        // customer_id:(appointment?.customer_id)?appointment.customer_id : "",
        // address_id:(appointment?.booking_address?._id)?appointment.booking_address?._id: "",
        // service_id:appointment?.service_id ?
        // appointment?.service_id :""
        appointment_id: appointment?._id ? appointment?._id : ""
      };
    }
    let from = "Rescheduled"

    dispatch(
      getCleanerTimeAvailability(listUrl, dataObject, updateCheckoutData, t, "", "", from),
    );
  }

  const options2 = {
    margin: 25,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,

    navText: [
      "<i class='fa fa-angle-left' aria-hidden='true'></i>",
      "<i class='fa fa-angle-right' aria-hidden='true'></i>"
    ],

    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      700: {
        items: 4
      },
      1000: {
        items: 7
      }
    }
  };

  const options = {
    margin: 25,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: [
      "<i class='fa fa-angle-left' aria-hidden='true'></i>",
      "<i class='fa fa-angle-right' aria-hidden='true'></i>"
    ],
    nxet: "",
    smartSpeed: 100,
    responsive: {
      0: {
        items: 2,
        slideBy: 7
      },
      400: {
        items: 3,
        slideBy: 7
      },
      600: {
        items: 4,
        slideBy: 7
      },
      700: {
        items: 4,
        slideBy: 7
      },
      1000: {
        items: 7,
        slideBy: 7
      }
    }
  };


  const settings = {
    infinite: false,           // equivalent to loop={false}
    touchMove: false,          // equivalent to touchDrag={false}
    draggable: false,          // equivalent to mouseDrag={false}
    ...options,                // Spread any additional options passed to the component
  };

  const settings2 = {
    infinite: false,         // equivalent to loop={false}
    slidesToShow: 3,         // equivalent to items={3}
    slidesToScroll: 1,       // Scroll 1 slide at a time
    arrows: true,            // Show arrows (for navigation)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,   // Show 2 slides on tablet-sized screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,   // Show 1 slide on smaller mobile screens
        },
      },
    ],
    margin: 25,             // Use custom CSS to manage margin as needed
    ...options2,            // Spread the additional options if any are provided
  };


  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="reschduleAppoiintments">
            <div className="heading">
              <Link to="/my-account/appointments">
                <h5>{t("appointments")}</h5>
              </Link>
              <h2>{t("reschedule_appointment")}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="datesec">
                  <h3>{t("like_your_service")}</h3>
                  <Slider {...settings}>
                    {availableDatesInput?.length > 0
                      ? availableDatesInput.map((item, index) => {
                          return (
                            <div className="item" key={index}>
                              <span className="dia_semana">
                                <label>
                                  <input
                                    type="radio"
                                    className="booking_date"
                                    name="booking_date"
                                    defaultValue={item.date}
                                    onClick={() => setBookingDate(item.date)}
                                    defaultChecked={
                                      item.date === appointment_date
                                    }
                                  />
                                  <span>
                                    {" "}
                                    {currentLanguage === "en"
                                      ? item.week_day
                                      : item.week_day_arabic}
                                  </span>
                                  <span className="dia ">{item.month_day}</span>
                                </label>
                              </span>
                              <span className="mes">{item.month}</span>
                            </div>
                          );
                        })
                      : ""}
                  </Slider>
                </div>
                <div className="timeButtons">
                  <h3>{t("what_time_to_start")}</h3>
                  <div className="buttonArea">
                    <div className="w-full pt-2 ">
                      {available_time?.length > 0 ? (
                        <Slider {...settings2}>
                          {available_time?.length > 0
                            ? available_time.map((time, index) => {
                                return (
                                  <div className="item" key={index}>
                                    <div
                                      className="buttonItem"
                                      onClick={() => setBookingTime(time.time)}
                                    >
                                      <label>
                                        <input
                                          type="radio"
                                          value="11:00 - 11:30"
                                          name="start_time"
                                          defaultChecked={false}
                                        />
                                        <span>{time.time}</span>
                                      </label>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </Slider>
                      ) : (
                        <center>{t("no_slot")}</center>
                      )}
                    </div>
                  </div>
                </div>
                <p>
                  {appointment_time
                    ? t("professional_arrive_time") +
                      " " +
                      appointment_time +
                      " " +
                      t("at_doorstep")
                    : ""}
                </p>
                <button
                  className={
                    "btn btn-primary w-full " +
                    (appointment_date === "" || appointment_time === ""
                      ? "disabled"
                      : "")
                  }
                  onClick={() => rescheduleMyAppointment()}
                >
                  {t("reschedule")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReschduleAppointment;
