import React, {useEffect, useRef, useState} from "react";
import {ModifiedToastContainer} from "../../../components/ModifiedToastContainer";
import Header from "../../../components/Header";
import {Link} from "react-router-dom";
import AddressAddModal from "./AddressAddModal";
import Footer from "../../../components/Footer";
import {useDispatch, useSelector} from "react-redux";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";
import {
  customerAddressListsAction,
  customerAddressDeleteAction,
  makeDefaultCustomerAddressAction
} from "../../../actions/customerDetails";
import {countryListAction} from "../../../actions/userActions";
import EditAddress from "./EditAddress";
import {
  ArabicAddAddresArow,
  EnglishAddAddresArow
} from "../../../helpers/helpers";
import AddressComponent from "./AddressComponent";

const Address = () => {
  const {t} = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [editableAddress, setEditableAddress] = useState();
  const [isAddAddress, setIsAddress] = useState(false);
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getCustomerAllAddressesState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {loading, customerAddresses} = getCustomerAllAddressesState;
  const dispatch = useDispatch();
  const setAddAddressRef = useRef();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/customer/address/list";

  useEffect(() => {
    const isLoggedIn =  localStorage.getItem("currentToken") &&
    localStorage.getItem("currentToken") !== "null"
      ? true
      : false
      if(isLoggedIn){
        dispatch(customerAddressListsAction(listUrl));
      }
  }, [listUrl]);

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  let countryListUrl = baseUrl + "/country/list";
  useEffect(() => {
    dispatch(countryListAction(countryListUrl, {}));
  }, [countryListUrl]);

  const country_arr = [];
  for (var i = 0; i < country_list?.length; i++) {
    if (country_list[i]?.isActive) {
      country_arr.push(country_list[i]?.name);
    }
  }

  async function handleRecordDelete(id) {
    let url = baseUrl + "/customer/address/delete/" + id;
    dispatch(customerAddressDeleteAction(url, listUrl));
    jQuery("#swal2-title").html(t("are_you_sure"));
    jQuery("#swal2-html-container").html(
      t("are_you_sure_want_to_delete_address")
    );
    jQuery(".swal2-confirm").html(t("delete"));
    jQuery(".swal2-cancel").html(t("cancel"));
  }

  async function makeDefaultAddress(id) {
    let actionUrl = baseUrl + "/customer/address/default/" + id;
    dispatch(makeDefaultCustomerAddressAction(actionUrl));
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }

  return (
    <>
      <Header />
      {isEdit && (
        <EditAddress
          editableAddress={editableAddress}
          setEditableAddress={setEditableAddress}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {loading ? <Loader /> : ""}
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="editAddress">
            <div className="heading">
              <Link to="/my-account/account-details">
                <h5>{t("account_details")}</h5>
              </Link>
              <h2>{t("address")}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="addNew">
                  <h3>{t("your_saved_address")}</h3>
                  <div
                    className="loginbtn"
                    onClick={() => {
                      // setAddAddressRef.current.handleAddress();
                      setIsAddress(true);
                    }}
                  >
                    {currentLanguageCode === "en" ? (
                      <EnglishAddAddresArow name={t("add_new_address")} />
                    ) : (
                      <ArabicAddAddresArow name={t("add_new_address")} />
                    )}
                  </div>
                </div>
                {customerAddresses?.length > 0 ? (
                  customerAddresses.map((address, index) => {
                    return (
                      <>
                        {(
                          <div
                            className="checkboxItem justify-content-between d-flex address"
                            key={index}
                          >
                            <div>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="radio1"
                                  name="optradio"
                                  defaultChecked={address.isDefault}
                                  onChange={() =>
                                    makeDefaultAddress(address._id)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  {address.address_nickname}
                                  {address.area}
                                </label>
                              </div>
                              <p>{address.address}</p>
                              <p>
                                {address.street}
                                {address.address_type}
                                {address.floor}
                                {address.house_no}
                              </p>
                              <p>{address.mobile}</p>
                              <p>{address.street}</p>
                            </div>
                            <div>
                              <p
                                onClick={() => {
                                  //  setAddAddressRef.current.handleAddress(address);
                                  setIsEdit(true);
                                  setEditableAddress(address);
                                }}
                              >
                                {t("edit")}
                              </p>
                              {customerAddresses?.length > 1 &&
                              !address.isDefault ? (
                                <p
                                  className="text-danger"
                                  onClick={() =>
                                    handleRecordDelete(address._id)
                                  }
                                >
                                  {t("delete")}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <p className=""></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {!isEdit && <AddressComponent  isAddAddress={isAddAddress} setIsAddress={setIsAddress}/>}
      {/* {!isEdit && <AddressAddModal ref={setAddAddressRef} />} */}
    </>
  );
};

export default Address;
