import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import { fromLatLng } from "react-geocode";
import { IoChevronBack } from "react-icons/io5";

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Polygon,
} from "react-google-maps";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng
// } from "react-places-autocomplete";
import SetUpMap from "./SetupMap";
import SearchLocation from "../../../pages/my_account/Address/Search";
import SetupProfile from "./SetupProfile";
import { useRef } from "react";
// import Search from "./Search";


const AddressSetupComponent = forwardRef(({
  from,
  showSetupAddressModal,
  setShowSetupAddressModal,
  showBackButton,
  handleBackButton,
  selectedCountryCode,
}, ref) => {
  const { t } = useTranslation();
  // const ref = useRef();
  // Expose this function to parent components using ref
  useImperativeHandle(ref, () => ({
    handleSetupAddressModalShow: () => setShowSetupAddressModal(true),
    handleSetupAddressModalHide: () => setShowSetupAddressModal(false),
  }));
  const handleAddressAddModalClose = () => {
    setShowSetupAddressModal(false);
  };
  const handleSetupAddressModalShow = () => setShowSetupAddressModal(true);
  const handleSetupAddressModalClose = () => setShowSetupAddressModal(false);
  const setProfileRef = useRef();
  const [marker, setMarker] = useState(null);
  const [center, setCenter] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const [mounted, setMounted] = useState(true);
  
  const [map, setMap] = useState(null);
  const countryCode = selectedCountryCode ? selectedCountryCode : localStorage.getItem("userCountryCode") ? localStorage.getItem("userCountryCode"): localStorage.getItem("currentCountryCode");

  const [addressObject, setAddressObject] = useState();

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const [shortName, setShortName] = useState("");
  const currentLocationOneGetter = async () => {
    try {
      const latitude = sessionStorage.getItem("selectedCountryLat") ? sessionStorage.getItem("selectedCountryLat") : localStorage.getItem("latitude");
      const longitude = sessionStorage.getItem("selectedCountryLng") ? sessionStorage.getItem("selectedCountryLng") :  localStorage.getItem("longitude");

      if (latitude && longitude) {
        setCenter({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
        setMarker({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
      }

      if (!latitude && !longitude && mounted) {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        });

        const { latitude, longitude } = position.coords;
        const lat = latitude;
        const lng = longitude;
        setCenter({ lat, lng });
        setMarker({ lat, lng });
        setAddressObject({
          ...addressObject,
          latitude: latitude,
          longitude: longitude,
        });
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);

        const response = await fromLatLng(lat, lng);
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const countryComponent = addressArray?.find((component) =>
          component.types.includes("country")
        );
        let street = getStreet(addressArray);
        let area = getArea(addressArray);
        let newShortName = countryComponent?.short_name;

        setShortName(countryComponent?.short_name);
        setAddressObject({
          ...addressObject,
          address: address,
          area: area,
          street: street,
          latitude: lat,
          longitude: lng,
        });
      }
    } catch (error) {
      console.error("Error getting current location:", error);
    }
  };

  const currentLocationGetter = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setCurrentLocation({lat: latitude, lng: longitude});
        setCenter({ lat: latitude, lng: longitude });
        setAddressObject({
          ...addressObject,
          latitude: latitude,
          longitude: longitude,
        });
        const lat = latitude;
        const lng = longitude;
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        sessionStorage.setItem("selectedCountryLat", latitude)
        sessionStorage.setItem("selectedCountryLng", longitude)
        fromLatLng(lat, lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            const countryComponent = addressArray?.find((component) =>
              component.types.includes("country")
            );
            let street = getStreet(addressArray);
            let area = getArea(addressArray);
            let newShortName = countryComponent?.short_name;
            setShortName(countryComponent?.short_name);
            setAddressObject({
              ...addressObject,
              address: address,
              area: area,
              street: street,
              latitude: lat,
              longitude: lng,
            });
            // setIsToggle(true);
          },
          (error) => {
            console.error(error);
          }
        );
        // setLocationCurrent({latitude: latitude, longitude: longitude});
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };

  useEffect(() => {
    // Fetch the user's current location when the component mounts
    currentLocationOneGetter();
    // Cleanup function
    // return () => {
    //   // Update the mounted state to false when the component is unmounted
    //   setMounted(false);
    // };
  }, []);

  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let j = 0; j < addressArray[i]?.types?.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  };

  const getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray?.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i]?.types?.length; j++) {
          if (addressArray[i].types[j] === "route") {
            street = addressArray[i].long_name;
            return street;
          }
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={showSetupAddressModal}
        onHide={handleAddressAddModalClose}
        id="addAddress"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
        {showBackButton && (
            <div style={{ width: "10%" }} onClick={handleBackButton}>
              <IoChevronBack />
            </div>
          )}
          <Modal.Title style={{ marginLeft: '30%' }}>{t("your_location")}</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <div className="searchPlace" style={{ zIndex: 1 }}>
            <SearchLocation
              center={center}
              setCenter={setCenter}
              addressObject={addressObject}
              setShortName={setShortName}
              setAddressObject={setAddressObject}
              setMarker={setMarker}
              setIsToggle={setIsToggle}
              currentLocationGetter={currentLocationGetter}
              restrictCountry={countryCode}
            />
          </div>
          <SetUpMap
            addressObject={addressObject}
            setShortName={setShortName}
            setAddressObject={setAddressObject}
            setCenter={setCenter}
            setMarker={setMarker}
            center={center}
            marker={marker}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            handleAddressAddModalClose={handleAddressAddModalClose}
            setProfileRef={setProfileRef}
            signupFrom={"bookAppointments"}
            countryCode={countryCode}
          />
        </Modal.Body>
      </Modal>
      <SetupProfile ref={setProfileRef} />
    </>
  );
});

export default AddressSetupComponent;
