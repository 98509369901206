import axios from "axios";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
const momentTimezone = require("moment-timezone");

const MySwal = withReactContent(Swal);
let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

export function deleteAnyRecord(sendApi, fetchApi, toUsed) {
  MySwal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete!"
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .delete(sendApi)
        .then((res) => {
          toast.success(res.data.message, {
            duration: 2000,
            position: "top-right"
          });
          setTimeout(() => {
            getAllList(fetchApi, toUsed).catch((err) => console.log(err));
          }, 1000);
        })
        .catch((err) => {
          toast.success(err.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        });
    }
  });
}

export function getAllList(sendApi, toBeUsedState) {
  return axios
    .get(sendApi)
    .then((res) => {
      toBeUsedState(res.data.list);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getEditData(sendApi, toBeUsedState) {
  return axios
    .get(sendApi, {
      headers: authHeader()
    })
    .then((res) => {
      toBeUsedState({
        country_code: res.data.user.country_code,
        mobile: res.data.user.mobile,
        name: res.data.user.name,
        email: res.data.user.email,
        gender: res.data.user.gender,
        dob: res.data.user.dob,
        referral_code: res.data.user.referral_code,
        otp: ""
      });
      localStorage.setItem("currentUser", JSON.stringify(res.data.user));
    })
    .catch((err) => {
      console.log(err.response);
    });
}

export function getEditDataForPayment(sendApi, toBeUsedState) {
  return axios
    .get(sendApi, {
      headers: authHeader()
    })
    .then((res) => {
      toBeUsedState(res.data.user);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function saveForm(sendApi, _form, method) {
  let frm = _form;

  try {
    return await axios({
      method: method,
      url: sendApi,
      headers: authHeader(),
      data: frm
    });
  } catch (e) {
    return commonErrorBag(frm, e);
  }
}

export async function postForm(sendApi, _form, method) {
  let frm = jQuery(_form);
  console.log("frm", frm)
  frm?.find(".inputdiv").removeClass("error");
  frm?.find(".help-block").html("");
  try {
    return await axios({
      method: method,
      url: sendApi,
      headers: authHeader(),
      data: frm.serialize()
    });
  } catch (e) {
    return handlingAuthErrors(e);
  }
}

export async function saveFormMultipart(sendApi, formData, _form, method) {
  let frm = jQuery(_form);
  frm?.find(".inputdiv").removeClass("error");
  frm?.find(".help-block").html("");

  try {
    let currentToken = localStorage.getItem("currentToken")
      ? JSON.parse(localStorage.getItem("currentToken"))
      : null;
    return await axios({
      url: sendApi,
      data: formData,
      method: method,
      headers: {
        "x-auth-token": currentToken,
        "Content-Type": "multipart/form-data"
      }
    });
  } catch (e) {
    return commonErrorBag(frm, e);
  }
}

export async function saveProfile(sendApi, _form, values, method) {
  let frm = jQuery(_form);
  frm?.find(".inputdiv").removeClass("error");
  frm?.find(".help-block").html("");
  try {
    return await axios({
      method: method,
      url: sendApi,
      data: values
    });
  } catch (e) {
    return commonErrorBag(_form, e);
  }
}

export async function apiAction(sendApi, data, method) {
  try {
    return await axios({
      method: method,
      url: sendApi,
      data: data
    });
  } catch (e) {
    return e;
  }
}

export function commonErrorBag(frm, error, from) {
  if (error?.response?.status === 400 || error?.response?.status === 422) {
    var formerrors = error.response.data;
    var checkFirstEle = 0;
    jQuery(".mapToogleDiv").addClass("addressDetailsHide");
    jQuery(".mapToggle").removeClass("hide");
    jQuery(".mapToggle").addClass("show");
    jQuery.each(formerrors, function (i, _msg) {
      var newMessage = _msg.replace('"', "");
      newMessage = newMessage.replace('"', "");
      if(from !== "newComponent"){
        var el = frm?.find("[name=" + i + "]");
        if (checkFirstEle === 0) {
          el.focus();
          checkFirstEle++;
        }
        el.parents(".inputdiv").addClass("error");
        el.parents(".inputdiv").find(".help-block").html(newMessage);
      }
    
    });
  }
}

export function getBrowserTimezone() {
  return momentTimezone.tz.guess();
}

export function authHeader() {
  let currentToken = localStorage.getItem("currentToken")
    ? JSON.parse(localStorage.getItem("currentToken"))
    : null;
  let currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let time_zone = getBrowserTimezone();
  if (currentToken) {
    return {
      "x-auth-token": currentToken,
      "Accept-Language": currentLanguage,
      "x-time-zone": time_zone
    };
  } else {
    return {
      "Accept-Language": currentLanguage,
      "x-time-zone": time_zone
    };
  }
}

export async function customerUpdateMobile(baseUrl, values) {
  try {
    return await axios({
      method: "post",
      url: baseUrl,
      headers: authHeader(),
      data: values
    });
  } catch (e) {
    return e;
  }
}

export function authToken() {
  return localStorage.getItem("currentToken")
    ? JSON.parse(localStorage.getItem("currentToken"))
    : null;
}

export function destroyTokenDetails() {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("currentToken");
  localStorage.clear();
}

export async function createUpdateQnbCustomer(sendApi, cardToken) {
  jQuery(".inputdiv").removeClass("error");
  jQuery(".help-block").html("");
  try {
    return await axios({
      method: "POST",
      url: sendApi,
      headers: authHeader(),
      data: {cardToken: cardToken}
    });
  } catch (e) {
    if (e.response && e.response?.status === 422 && e.response.data) {
      var formerrors = e.response.data.errors;
      jQuery.each(formerrors, function (i, item) {
        if (item.field === "card.number") {
          jQuery(".cardNumberError").html(item.message);
        }
        if (item.field === "card.expYear" && item.field === "card.expMonth") {
          jQuery(".expiryDateError").html(item.message);
        }
        if (item.field === "card.cvc") {
          jQuery(".cvvError").html(item.message);
        }
      });
    }
  }
}

export async function createUpdateCustCreditCard(sendApi, cardToken) {
  jQuery(".inputdiv").removeClass("error");
  jQuery(".help-block").html("");
  try {
    return await axios({
      method: "POST",
      url: sendApi,
      headers: authHeader(),
      data: {cardToken: cardToken}
    });
  } catch (e) {
    if (e.response && e.response?.status === 422 && e.response.data) {
      var formerrors = e.response.data.errors;
      jQuery.each(formerrors, function (i, item) {
        if (item.field === "card.number") {
          jQuery(".cardNumberError").html(item.message);
        }
        if (item.field === "card.expYear" && item.field === "card.expMonth") {
          jQuery(".expiryDateError").html(item.message);
        }
        if (item.field === "card.cvc") {
          jQuery(".cvvError").html(item.message);
        }
      });
    }
  }
}

export async function updateCustomerCreditCard(sendApi, expiry_year) {
  jQuery(".inputdiv").removeClass("error");
  jQuery(".help-block").html("");
  try {
    return await axios({
      method: "PUT",
      url: sendApi,
      headers: authHeader()
      // data: {expiry_year:expiry_year}
    });
  } catch (e) {
    if (e.response && e.response?.status === 422 && e.response.data) {
      var formerrors = e.response.data.errors;
      jQuery.each(formerrors, function (i, item) {
        if (item.field === "card.number") {
          jQuery(".cardNumberError").html(item.message);
        }
        if (item.field === "card.expYear" && item.field === "card.expMonth") {
          jQuery(".expiryDateError").html(item.message);
        }
        if (item.field === "card.cvc") {
          jQuery(".cvvError").html(item.message);
        }
      });
    }
  }
}

export async function paymentConfirmation(sendApi, data) {
  try {
    return await axios({
      method: "POST",
      url: sendApi,
      headers: authHeader(),
      data: data
    });
  } catch (e) {
    return e;
  }
}

export function checkCurrentLanguage() {
  if (localStorage.getItem("currentLanguage")) {
    return localStorage.getItem("currentLanguage");
  } else {
    return "en";
  }
}

export function handlingNonauthErrors(err) {
  if (!err.response) {
    toast.error("Network error", {
      duration: 2000,
      position: "top-right"
    });
  }
  if (
    err?.response?.status === 400 ||
    err?.response?.status === 401 ||
    err?.response?.status === 422
  ) {
    //    console.log(err.response.data.message, 676767);
    toast.error(err.response.data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }

  if (err?.response?.status === 404) {
    toast.error(err.response.data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
}

export function handlingNonauthStaticContentErrors(err) {
  if (!err.response) {
    toast.error("Network error", {
      duration: 2000,
      position: "top-right"
    });
  }
  if (err?.response?.status === 400) {
    toast.error(err.response.data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
}

export function handlingAuthErrors(err) {
  if (!err.response) {
    destroyTokenDetails();
    toast.error("Network error", {
      duration: 2000,
      position: "top-right",
      duplicate: false,
    });
    /* setTimeout(() => {
            window.location.href = "/";
        }, 100) */
  }
  if (err.response?.status === 401) {
    destroyTokenDetails();
    //window.location.href = "/";
  }
  
  if (err.response?.status === 422) {
    toast.error(err.response.data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      duplicate: false,
    });
  }
  if (err.response?.status === 400) {
    toast.error(err.response.data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      duplicate: false,
    });
  }
}

export function handlingTranslatedAuthErrors(err, t) {
  if (!err.response) {
    destroyTokenDetails();
    toast.error("Network error", {
      duration: 2000,
      position: "top-right"
    });
    /* setTimeout(() => {
            window.location.href = "/";
        }, 100) */
  }
  if (err.response?.status === 401) {
    destroyTokenDetails();
    //window.location.href = "/";
  }
  if (err.response?.status === 422) {
    toast.error(t(err.response.data.message), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
  if (err.response?.status === 400) {
    toast.error(t(err.response.data.message), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
}
export const hasStripePayment =(countryId, country_list) => {
  const selectedCountry = country_list?.find(country => country?._id === countryId);
  if (selectedCountry && selectedCountry.payment_type) {
      return selectedCountry.payment_type.some(type => type.id === "stripe");
  }
  return false;
}
export function commonLoginSuccess(
  token,
  cart,
  msg,
  handleInfoModalClose,
  setAddressRef,
  setProfileRef,
  appointmentCartRef
) {
  const currentUserDetail =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : "";
  handleInfoModalClose();
  if (currentUserDetail?.profile_status === 1) {
    setAddressRef?.current?.handleSetupAddressModalShow();
  } else if (currentUserDetail?.profile_status === 2) {
    setProfileRef.current.handleSetProfileModalShow();
  } else if (currentUserDetail?.profile_status === 3) {
    localStorage.setItem("currentToken", JSON.stringify(token));
    if (cart) {
      localStorage.setItem("bookAppointmentId", cart._id);
      if (cart?.is_home_cleaning) {
        localStorage.setItem("frequencyTypeServiceDetailId", cart._id);
        //localStorage.setItem('frequencyCheckoutData', null);
      } else {
        localStorage.setItem("bookAppointmentId", cart._id);
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== cart._id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", cart._id);
      }
      // appointmentCartRef?.current?.handleShowAppointmentCartItem();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }
  customerAddress()
}
export const EnglishAddAddresArow = ({name}) => {
  const t = useTranslation();
  return (
    <>
      <i className="fa fa-plus mx-1"></i>
      {name}
      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/arrow-right2.png.webp" />{" "}
    </>
  );
};
export const ArabicAddAddresArow = ({name}) => {
  const t = useTranslation();
  return (
    <>
      <i className="fa fa-plus mx-1"></i> {name}
      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/arrow-left.png.webp"  />
    </>
  );
};

  const customerAddress = async () => {
    let addressListUrl =
      `${baseUrl}/customer/address/list?country_id=${localStorage.getItem("userCountryId")}`;
    try {
      // Make the API request and wait for the response
      const response = await axios.get(addressListUrl, {
        headers: authHeader(),
      });

      let defaultAddress = response?.data?.address?.find((f) => f.isDefault === true)
      if (defaultAddress) {
        sessionStorage.setItem("address", defaultAddress?.address)
        localStorage.setItem("mapLatitude", defaultAddress?.latitude)
        localStorage.setItem("mapLongitude", defaultAddress?.longitude)
        window.location.reload()
      }
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        closeOnClick: true,
      });
    }
  };

export async function getRhValid(data) {
  let listUrl = `${baseUrl}/isRhValid`;

  try {
    return await axios({
      method: "POST",
      headers: authHeader(),
      url: listUrl,
      data: data
    });
  } catch (err) {
    console.log("error: ", err);
  }
}

export async function getRhPolicyDetails(listUrl, values) {
  try {
    return await axios({
      method: "GET",
      headers: authHeader(),
      url: listUrl,
      data: values
    });
  } catch (err) {
    console.log("error: ", err);
  }
}

export async function saveAddress(sendApi, data, method) {
  try {
    return await axios({
      method: method,
      url: sendApi,
      headers: authHeader(),
      data: data
    });
  } catch (e) {
    console.log("error: ", e);
  }
}

const getcountry = (addressArray) => {
  let country = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (addressArray[i].types[j] === "country") {
          country = addressArray[i].address_components[0].long_name;
          return country;
        }
      }
    }
  }
};
const getCountryDetail = (position) => {
  let lat = position.coords.latitude;
  let long = position.coords.longitude;
  // console.log(lat, long);
  axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
    .then((res) => {
      // setCountryData(res.data.results);
      let countryName = getcountry(res.data.results);
      // console.log(countryName);
      return countryName;
    });
};

export function getCountryName() {
  if (navigator.geolocation) {
    let getCountryName =
      navigator.geolocation.getCurrentPosition(getCountryDetail);
    // console.log(getCountryName);
    return getCountryName;
  }
}

export async function holidayList(data) {
  let listUrl = `${baseUrl}/holidays/list`;
  try {
    return await axios({
      method: "POST",
      url: listUrl,
      headers: authHeader(),
      data: data
    });
  } catch (err) {
    handlingAuthErrors(err)
    console.log("error: ", err);
  }
}

export async function pendingAppointment() {
  let listUrl = `${baseUrl}/appointment/cart/detail`;
  try {
    return await axios({
      method: "GET",
      url: listUrl,
      headers: authHeader()
    });
  }  catch (err) {
    handlingAuthErrors(err);
    console.log("error: ", err);
  }
}

export async function cancelAppointment(appointmentId) {
  let listUrl = `${baseUrl}/customer/remove-cart-appointment/${appointmentId}`;
  try {
    return await axios({
      method: "GET",
      url: listUrl,
      headers: authHeader()
    });
  }  catch (err) {
    handlingAuthErrors(err)
    console.log("error: ", err);
  }
}

export async function getInviteHistory() {
  let listUrl = `${baseUrl}/freecredit/invite/list`;
  try {
    const response = await axios({
      method: "GET",
      headers: authHeader(),
      url: listUrl
    });
    // Handel the api response
    if (response?.data?.code === 200) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      return response;
    }
  } catch (err) {
    console.error(err);
    handlingAuthErrors(err);
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
}
