import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { authHeader } from "../helpers/helpers";

const EmbeddedPayment = () => {
  const location = useLocation();

  console.log('location:', location); 
  const { appointmentData, fatoorahLinkData, totalAmount } = location.state || {};

  const [fatoorahCountryCode, setFatoorahCountryCode] = useState("");

  console.log("fatoorahLinkData", fatoorahLinkData);
  console.log("appointmentData", appointmentData);
  const sessionsId = fatoorahLinkData?.Data?.SessionId;
  const countryId = appointmentData?.country_id;
  const appointmentId = appointmentData?.appointment_id;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://demo.myfatoorah.com/payment/v1/session.js";
    script.async = true;
    script.onload = () => {
      const sessionId = sessionsId;
      const countryCode = fatoorahLinkData?.Data?.CountryCode;
      const currencyCode = appointmentData?.currencyCode
      const amount = totalAmount;

      const config = {
        sessionId: sessionId,
        countryCode: countryCode,
        currencyCode: currencyCode,
        amount: amount,
        callback: payment,
        containerId: "unified-session",
        paymentOptions: ["Card"],
        supportedNetworks: ["visa", "masterCard"],
        language: "en",
        settings: {
          card: {
            onCardBinChanged: handleCardBinChanged,
            style: {
              hideNetworkIcons: false,
              cardHeight: "180px",
              tokenHeight: "180px",
              input: {
                color: "black",
                fontSize: "15px",
                fontFamily: "Times",
                inputHeight: "32px",
                inputMargin: "-1px",
                borderColor: "black",
                borderWidth: "1px",
                borderRadius: "30px",
                outerRadius: "10px",
                placeHolder: {
                  holderName: "Name On Card",
                  cardNumber: "Number",
                  expiryDate: "MM/YY",
                  securityCode: "CVV",
                },
              },
              text: {
                saveCard: "Save card info for future payments",
                addCard: "Use another Card!",
                deleteAlert: {
                  title: "Delete",
                  message: "Are you sure?",
                  confirm: "YES",
                  cancel: "NO",
                },
              },
              label: {
                display: false,
                color: "black",
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                text: {
                  holderName: "Card Holder Name",
                  cardNumber: "Card Number",
                  expiryDate: "Expiry Date",
                  securityCode: "Security Code",
                },
              },
              error: {
                borderColor: "red",
                borderRadius: "8px",
              },
              button: {
                useCustomButton: false,
                textContent: "Pay",
                fontSize: "16px",
                fontFamily: "Times",
                color: "white",
                backgroundColor: "#4daee0",
                height: "30px",
                borderRadius: "8px",
                width: "70%",
                margin: "0 auto",
                cursor: "pointer",
              },
              separator: {
                useCustomSeparator: false,
                textContent: "Enter your card",
                fontSize: "20px",
                color: "#4daee0",
                fontFamily: "sans-serif",
                textSpacing: "2px",
                lineStyle: "dashed",
                lineColor: "black",
                lineThickness: "3px",
              },
            },
          },
        },
      };

      window.myfatoorah.init(config);

      function payment(response) {
        if (response.isSuccess) {
          switch (response.paymentType) {
            case "Card":
              let paymentId = response.sessionId;
              axios
                .post(
                  `${process.env.REACT_APP_LOCAL_API_URL}/appointment/create/payment`,
                  {
                    appointment_id: appointmentId,
                    appointment_process: 5,
                    discount_type:appointmentData?.discount_type,
                    payment_mode: "Card",
                    pay_type: "online",
                    voucher: appointmentData?.voucher,
                    promo_code: appointmentData?.promo_code,
                    country_id: appointmentData?.country_id,
                    is_home_cleaning: appointmentData?.service?.ui_type === "Package" ? false : true,
                    service_type: "Home",
                    isWallet: false,
                    paymentId: paymentId,
                  },
                  {
                    headers: authHeader(),
                  }
                )
                .then((response) => {
                  localStorage.setItem("appointmentDetailsId", appointmentId);
                  window.location.href = "/my-account/appointment-details";
                })
                .catch((error) => {
                  console.error("Payment error:", error);
                });
              break;
            default:
              console.log("Unknown payment type");
              break;
          }
        }
      }

      function sessionCanceled() {
        console.log("Failed");
      }

      function sessionStarted() {
        console.log("Start");
      }

      function handleCardBinChanged(response) {
        console.log(response);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [sessionsId, fatoorahCountryCode]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ width: "400px", margin: "auto" }}>
        <div id="unified-session"></div>
      </div>
      <div style={{ width: "400px", margin: "auto" }}>
        <div id="apple-pay"></div>
      </div>
      <div
        style={{
          width: "400px",
          margin: "0",
          position: "absolute",
          top: "0",
          left: "0",
        }}
      >
        <div id="google-pay"></div>
      </div>
    </div>
  );
};

export default EmbeddedPayment;
