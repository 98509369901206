import React, {useState, useEffect, useRef} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import Modal from "react-bootstrap/Modal";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {getHomeServiceCategoryAction} from "../../../../actions/homeAction";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Loader from "./../../../../components/Loader";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
import {postBookAppointmentAction} from "../../../../actions/appointmentAction";
import AddressAddModal from "../../../my_account/Address/AddressAddModal";
import {useNavigate} from "react-router-dom";

const ServiceDetails = ({
  cart,
  updateCart,
  checkoutData,
  updateCheckoutData
}) => {
  // const {t} = useTranslation();
  // const countryCurrency = useCountryCurrency(
  //   localStorage.getItem("userCountryId")
  // );
  // const currency = countryCurrency?.length > 0
  //   ? countryCurrency[0]?.currency
  //   : localStorage.getItem("currency");
  // const currentLanguage = localStorage.getItem("currentLanguage")
  //   ? localStorage.getItem("currentLanguage")
  //   : "en";
  // const getAllServicesCategoryState = useSelector((state) => state.homeReducer);
  // let navigate = useNavigate();
  // const {servicesCategory} = getAllServicesCategoryState;

  // const [serviceDetailsItem, setServiceDetailsItem] = useState();
  // const [newItemCount, setNewItemCount] = useState(0);
  // const [pageLoading, setPageLoading] = useState(false);
  // const [active, setActive] = useState("0");

  // const [serviceCategoryId, setServiceCategoryId] = useState();
  // // const [serviceCategoryId,setServiceCategoryId] = useState();
  // const dispatch = useDispatch();
  // const [serviceDetailsModal, setServiceDetailsModal] = useState(false);
  // const handleServiceDetailsModalShow = () => setServiceDetailsModal(true);
  // const handleServiceDetailsModalHide = () => setServiceDetailsModal(false);
  // const currentUser =
  //   localStorage.getItem("currentUser") &&
  //   localStorage.getItem("currentUser") !== "null"
  //     ? JSON.parse(localStorage.getItem("currentUser"))
  //     : false;
  // let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/home/service/category";
  // var serviceDetailId = localStorage.getItem("serviceDetailId");

  // let values = {
  //   service_id: serviceDetailId,
  //   country_id: localStorage.getItem("userCountryId")
  // };

  // const setAddAddressRef = useRef();

  // useEffect(() => {
  //   if (currentUser === false) {
  //     setAddAddressRef.current.handleAddress();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("serviceDetailId")) {
  //     window.location.href = "/";
  //   }
  //   dispatch(getHomeServiceCategoryAction(listUrl, values));
  //   localStorage.removeItem("discoverBookingPage");
  // }, [listUrl]);

  // const removeItem = (index) => {
  //   if (index > -1) {
  //     updateCart((prevState) => prevState.splice(index, 1));
  //   }
  // };
  // const [showService, setShowService] = useState(true);
  // useEffect(() => {
  //   if (servicesCategory) {
  //     // console.log(servicesCategory, 7878);
  //     // console.log(servicesCategory?.length > 0, 1212);
  //     servicesCategory?.forEach((service) => {
  //       // console.log(service);
  //       // console.log( service?.servicePackage?.length, 7878);
  //       service?.servicePackage?.forEach((package_Data) => {
  //         // console.log(package_Data?.packagePrices)
  //         if (package_Data?.packagePrices?.length === 0) {
  //           setShowService(true);
  //           // navigate("/")
  //         } else {
  //           setShowService(false);
  //         }
  //       });
  //     });
  //   }
  // }, [servicesCategory]);

  // // console.log(servicesCategory, 'servicesCategory');
  // const toggleTab = (e, categoryId) => {
  //   e.preventDefault();
  //   localStorage.removeItem("serviceCategoryPackageId");
  //   jQuery(".serviceCategoryBtn").removeClass("tabBtn-active");
  //   jQuery(e.target).addClass("tabBtn-active");
  //   setServiceCategoryId(categoryId);
  // };

  // async function checkCartItem(action, item) {
  //   setPageLoading(true);
  //   let index = cart.findIndex((x) => x._id === item._id);
  //   if (index !== -1) {
  //     let temporaryCartItems = cart.slice();
  //     if (
  //       temporaryCartItems[index]["item_count"] === 1 &&
  //       action === "remove"
  //     ) {
  //       temporaryCartItems.splice(index, 1);
  //       removeItem(index);
  //     } else {
  //       temporaryCartItems[index]["item_count"] =
  //         action === "add"
  //           ? temporaryCartItems[index]["item_count"] + 1
  //           : temporaryCartItems[index]["item_count"] - 1;
  //       let totalItemPrice =
  //         action === "add"
  //           ? temporaryCartItems[index]["item_count"] * item.price
  //           : temporaryCartItems[index]["total_items_price"] - item.price;
  //       temporaryCartItems[index]["total_items_price"] = totalItemPrice;
  //     }
  //     updateCart(temporaryCartItems);
  //   } else {
  //     var newItem = {
  //       _id: item._id,
  //       item_count: 1,
  //       name: item.name,
  //       name_arabic: item.name_arabic,
  //       price: item.price !== "null" ? item.price : "0",
  //       new_price: item.new_price !== "null" ? item.new_price : "0",
  //       price_arabic: item.price_arabic !== "null" ? item.price_arabic : "0",
  //       sub_service_id: item.sub_service_id,
  //       total_items_price: item.price,
  //       prices: item.prices
  //     };
  //     updateCart([...cart, newItem]);
  //   }
  //   setTimeout(() => {
  //     setPageLoading(false);
  //   }, 1000);
  // }

  // async function updateServiceCount() {
  //   handleServiceDetailsModalHide();
  // }

  // async function showServiceDetails(item) {
  //   setNewItemCount(0);
  //   setServiceDetailsItem(item);
  //   let itemCount = getItemCounts(item._id);
  //   setNewItemCount(itemCount);
  //   handleServiceDetailsModalShow();
  // }

  // async function addCardItem(item, itemCount, servicePrice) {
  //   let prices = {price: servicePrice?.price, new_price: servicePrice?.new_price, minOrderAmount: localStorage.getItem("minOrderAmount")};
  //   item.prices = Object.keys(prices).length > 0 ? prices : 0;
  //   if (itemCount <= 3) {
  //     setNewItemCount(newItemCount + 1);
  //     await checkCartItem("add", item)
  //       .then((res) => {
  //         localStorage.setItem(
  //           "bookAppointmentCartData",
  //           JSON.stringify({
  //             cart_items: cart
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }

  // async function removeCartItem(item, itemCount) {
  //   setNewItemCount(newItemCount - 1);
  //   await checkCartItem("remove", item)
  //     .then((res) => {
  //       localStorage.setItem(
  //         "bookAppointmentCartData",
  //         JSON.stringify({
  //           cart_items: cart
  //         })
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // function getItemCounts(id) {
  //   let newCartItem = checkoutData.cart;
  //   let index = newCartItem.findIndex((x) => x._id === id);
  //   let cartItem = 0;
  //   if (index !== -1) {
  //     let temporaryCartItems = cart.slice();
  //     cartItem = temporaryCartItems[index]["item_count"];
  //   }
  //   return cartItem;
  // }

  // const options = {
  //   margin: 15,
  //   responsiveClass: true,
  //   nav: true,
  //   dots: false,
  //   autoplay: false,
  //   autoWidth: false,

  //   navText: [
  //     "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
  //     "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
  //   ],

  //   nxet: "",
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     400: {
  //       items: 1
  //     },
  //     600: {
  //       items: 2
  //     },

  //     700: {
  //       items: 2
  //     },

  //     701: {
  //       items: 3,

  //       margin: 10
  //     },
  //     1200: {
  //       items: 3
  //     }
  //   }
  // };

  // return (
  //   <>
  //     {pageLoading ? <Loader /> : ""}
  //     <div className="w-full pt-2 ">
  //       <div className="divsticky">
  //         <div className="container p-0">
  //           {/* {console.log("serviceCategory",serviceCategoryId)} */}
  //           {servicesCategory?.length > 0 ? (
  //             <OwlCarousel
  //               className="owl-theme"
  //               items={4}
  //               loop={false}
  //               touchDrag={false}
  //               mouseDrag={false}
  //               nav
  //               {...options}
  //             >
  //               {servicesCategory?.length > 0 ? (
  //                 <>
  //                   {servicesCategory.map((serviceCategory, index) => {
  //                     let itemKey = index + 1;
  //                     // console.log(serviceCategory, itemKey, 8989);
  //                     // if(index === 0){
  //                     //   setServiceCategoryId(serviceCategory._id)
  //                     // }

  //                     return (
  //                       <div className="item" key={itemKey}>
  //                         <button
  //                           // className={`serviceCategoryBtn serviceCategoryBtn-active`}
  //                           className={
  //                             "serviceCategoryBtn " +
  //                             (itemKey === 1
  //                               ? "tabBtn-active firstServiceCategory"
  //                               : "")
  //                           }
  //                           onClick={(e) => toggleTab(e, serviceCategory?._id)}
  //                         >
  //                           {currentLanguage === "en"
  //                             ? serviceCategory?.name
  //                             : serviceCategory?.name_arabic}
  //                         </button>
  //                       </div>
  //                     );
  //                   })}
  //                 </>
  //               ) : (
  //                 ""
  //               )}
  //             </OwlCarousel>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       </div>
  //       {/* {console.log(servicesCategory?.length > 0, 1313)} */}
  //       <div className="serviceTabContent">
  //         {servicesCategory?.length > 0 ? (
  //           servicesCategory.map((serviceCategory, index) => {
  //             let servicePackage = serviceCategory?.servicePackage;
  //             let itemKey = index + 1;
  //             return (
  //               <>
  //                 {serviceCategory?._id ===
  //                 localStorage.getItem("serviceCategoryPackageId") ? (
  //                   <div key={itemKey}>
  //                     <h3>
  //                       {currentLanguage === "en"
  //                         ? serviceCategory?.name
  //                         : serviceCategory?.name_arabic}
  //                     </h3>
  //                     <img
  //                       src={
  //                         serviceCategory?.icon
  //                           ? serviceCategory?.icon
  //                           : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
  //                       }
  //                       className="w-full"
  //                       alt=""
  //                     />
  //                     {servicePackage?.length > 0
  //                       ? servicePackage.map((packageItem, key) => {
  //                           let itemCount = getItemCounts(packageItem._id);

  //                           return (
  //                             <>
  //                               {packageItem?.packagePrices?.length !== 0 &&
  //                               packageItem?.packagePrices[0]?.country_id ===
  //                                 localStorage.getItem("userCountryId") ? (
  //                                 <div className="serviceItem" key={key}>
  //                                   <div
  //                                     className="serviceDetailsModal"
  //                                     onClick={() =>
  //                                       showServiceDetails(packageItem)
  //                                     }
  //                                   >
  //                                     <h3>
  //                                       {currentLanguage === "en"
  //                                         ? packageItem?.name
  //                                         : packageItem?.name_arabic}
  //                                     </h3>
  //                                     <p>
  //                                       {currentLanguage === "en"
  //                                         ? packageItem?.description
  //                                         : packageItem?.description_arabic}
  //                                     </p>
  //                                   </div>
  //                                   <div className="amount align-items-center justify-content-between d-flex buttonadd">
  //                                     <p>
  //                                     {currency}{"  "}
  //                                       {currentLanguage === "en"
  //                                         ? packageItem?.packagePrices[0]
  //                                             ?.new_price
  //                                         : packageItem?.packagePrices[0]
  //                                             ?.new_price_arabic}
  //                                       :{" "}
  //                                       <span><del>
  //                                       {currency}{"  "}
  //                                         {currentLanguage === "en"
  //                                           ? packageItem?.packagePrices[0]
  //                                               ?.price
  //                                           : packageItem?.packagePrices[0]
  //                                               ?.price_arabic}
  //                                      </del> </span>
  //                                     </p>
  //                                     <button
  //                                       className={
  //                                         "btn btn-primary addBtn " +
  //                                         (itemCount > 0
  //                                           ? " addItemBtnHide"
  //                                           : "")
  //                                       }
  //                                       onClick={() =>
  //                                         addCardItem(
  //                                           packageItem,
  //                                           0,
  //                                           packageItem?.packagePrices[0]
  //                                         )
  //                                       }
  //                                     >
  //                                       {t("add")} +
  //                                     </button>
  //                                     <div
  //                                       className={
  //                                         "button-with-counter" +
  //                                         (itemCount >= 1
  //                                           ? " "
  //                                           : " manageCardItemBtnHide")
  //                                       }
  //                                     >
  //                                       <i
  //                                         className="fa fa-minus"
  //                                         onClick={() =>
  //                                           removeCartItem(
  //                                             packageItem,
  //                                             itemCount
  //                                           )
  //                                         }
  //                                       ></i>
  //                                       <span>{itemCount}</span>
  //                                       <i
  //                                         className={
  //                                           "fa fa-plus cartItemCountBtn " +
  //                                           (itemCount >= 4 ? "disabled" : "")
  //                                         }
  //                                         onClick={() =>
  //                                           addCardItem(
  //                                             packageItem,
  //                                             itemCount,
  //                                             packageItem.packagePrices[0]
  //                                           )
  //                                         }
  //                                       ></i>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               ) : (
  //                                 ""
  //                               )}
  //                             </>
  //                           );
  //                         })
  //                       : ""}
  //                   </div>
  //                 ) : (
  //                   <>
  //                     {serviceCategory?._id === serviceCategoryId ? (
  //                       <div>
  //                         <h3>
  //                           {currentLanguage === "en"
  //                             ? serviceCategory.name
  //                             : serviceCategory.name_arabic}
  //                         </h3>
  //                         <img
  //                           src={
  //                             serviceCategory?.icon
  //                               ? serviceCategory?.icon
  //                               : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
  //                           }
  //                           className="w-full"
  //                           alt=""
  //                         />
  //                         {servicePackage?.length > 0
  //                           ? servicePackage.map((packageItem, key) => {
  //                               let itemCount = getItemCounts(packageItem._id);

  //                               return (
  //                                 <>
  //                                 {packageItem?.packagePrices?.length !== 0 &&
  //                               packageItem?.packagePrices[0]?.country_id ===
  //                                 localStorage.getItem("userCountryId") ? (
  //                                   <div className="serviceItem" key={key}>
  //                                   <div
  //                                     className="serviceDetailsModal"
  //                                     onClick={() =>
  //                                       showServiceDetails(packageItem)
  //                                     }
  //                                   >
  //                                     <h3>
  //                                       {currentLanguage === "en"
  //                                         ? packageItem.name
  //                                         : packageItem.name_arabic}
  //                                     </h3>
  //                                     <p>
  //                                       {currentLanguage === "en"
  //                                         ? packageItem?.description
  //                                         : packageItem?.description_arabic}
  //                                     </p>
  //                                   </div>
  //                                   <div className="amount align-items-center justify-content-between d-flex buttonadd">
  //                                     <p>
  //                                       {countryCurrency[0]?.currency}{" "}
  //                                       {currentLanguage === "en"
  //                                         ? packageItem.packagePrices[0]
  //                                             ?.new_price
  //                                         : packageItem.packagePrices[0]
  //                                             ?.new_price_arabic}
  //                                       :{" "}
  //                                       <span><del>
  //                                         {" "}
  //                                         {countryCurrency[0]?.currency}{" "}
  //                                         {currentLanguage === "en"
  //                                           ? packageItem.packagePrices[0]?.price
  //                                           : packageItem.packagePrices[0]
  //                                               ?.price_arabic}
  //                                       </del></span>
  //                                     </p>
  //                                     {packageItem.packagePrices.length !== 0 ? (
  //                                       <button
  //                                       className={
  //                                         "btn btn-primary addBtn " +
  //                                         (itemCount > 0
  //                                           ? " addItemBtnHide"
  //                                           : "")
  //                                       }
  //                                       onClick={() =>
  //                                         addCardItem(
  //                                           packageItem,
  //                                           0,
  //                                           packageItem.packagePrices[0]
  //                                         )
  //                                       }
  //                                     >
  //                                       {t("add")} +
  //                                     </button>
  //                                     ) : "" }
  //                                     <div
  //                                       className={
  //                                         "button-with-counter" +
  //                                         (itemCount >= 1
  //                                           ? " "
  //                                           : " manageCardItemBtnHide")
  //                                       }
  //                                     >
  //                                       <i
  //                                         className="fa fa-minus"
  //                                         onClick={() =>
  //                                           removeCartItem(
  //                                             packageItem,
  //                                             itemCount
  //                                           )
  //                                         }
  //                                       ></i>
  //                                       <span>{itemCount}</span>
  //                                       <i
  //                                         className={
  //                                           "fa fa-plus cartItemCountBtn " +
  //                                           (itemCount >= 4 ? "disabled" : "")
  //                                         }
  //                                         onClick={() =>
  //                                           addCardItem(
  //                                             packageItem,
  //                                             itemCount,
  //                                             packageItem.packagePrices[0]
  //                                           )
  //                                         }
  //                                       ></i>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 ):""
  //                                 }
  //                                 </>
  //                               );
  //                             })
  //                           : ""}
  //                       </div>
  //                     ) : (
  //                       <center></center>
  //                     )}
  //                   </>
  //                 )}
  //               </>
  //             );
  //           })
  //         ) : (
  //           <center>{t("no_services_available")}</center>
  //         )}
  //       </div>

  //       <Modal
  //         show={serviceDetailsModal}
  //         onHide={handleServiceDetailsModalHide}
  //         id="addAddress"
  //         className="addAddressModal servicedetail"
  //       >
  //         <Modal.Header closeButton>
  //           <Modal.Title>
  //             {serviceDetailsItem
  //               ? currentLanguage === "en"
  //                 ? serviceDetailsItem.name
  //                 : serviceDetailsItem.name_arabic
  //               : ""}
  //           </Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <div className="modal-body p-0 ">
  //             <img
  //               src={
  //                 serviceDetailsItem?.icon
  //                   ? serviceDetailsItem?.icon
  //                   : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
  //               }
  //               className="w-full"
  //               alt=""
  //             />
  //             <div className="p-4 servicepopopList">
  //               <h3>
  //                 {serviceDetailsItem
  //                   ? currentLanguage === "en"
  //                     ? serviceDetailsItem.name
  //                     : serviceDetailsItem.name_arabic
  //                   : ""}
  //                 <div>
  //                   <p>
  //                     {/* {countryCurrency[0]?.currency} */}{" "}
  //                     {serviceDetailsItem
  //                       ? currentLanguage === "en"
  //                         ? serviceDetailsItem.new_price
  //                         : serviceDetailsItem.new_price_arabic
  //                       : 0}
  //                   </p>
  //                 </div>
  //               </h3>
  //               <p>
  //                 {serviceDetailsItem
  //                   ? currentLanguage === "en"
  //                     ? serviceDetailsItem.description
  //                     : serviceDetailsItem.description_arabic
  //                   : ""}
  //               </p>
  //               <p
  //                 dangerouslySetInnerHTML={{
  //                   __html: serviceDetailsItem
  //                     ? currentLanguage === "en"
  //                       ? serviceDetailsItem.additional_information
  //                       : serviceDetailsItem.additional_information_arabic
  //                     : ""
  //                 }}
  //               ></p>

  //               {/* <p>
  //                 <b>Duration:</b> 30 mins
  //               </p>
  //               <h4>What is included:</h4>
  //               <ul>
  //                 <li>Sterilized & sealed tool kit</li>
  //                 <li>Disposable items</li>
  //                 <li>Plastic floor sheet cover</li>
  //               </ul>
  //               <p>
  //                 Note: Standard Men's salon does not include barber's chair and
  //                 mirror. Kindly ensure that seating for the service is
  //                 available at home.
  //               </p> */}
  //               <div className="amount align-items-center justify-content-between d-flex buttonadd">
  //                 <p>
  //                   {checkoutData.currency}{" "}
  //                   {serviceDetailsItem
  //                     ? currentLanguage === "en"
  //                       ? serviceDetailsItem.new_price
  //                       : serviceDetailsItem.new_price_arabic
  //                     : 0}
  //                   :{" "}
  //                   <span>
  //                     {checkoutData.currency}{" "}
  //                     {serviceDetailsItem
  //                       ? currentLanguage === "en"
  //                         ? serviceDetailsItem.price
  //                         : serviceDetailsItem.price_arabic
  //                       : 0}
  //                   </span>
  //                 </p>
  //                 <button
  //                   className={
  //                     "btn btn-primary addBtn " +
  //                     (newItemCount > 0 ? " addItemBtnHide" : "")
  //                   }
  //                   onClick={() => addCardItem(serviceDetailsItem, 0, "11")}
  //                 >
  //                   {t("add")} +
  //                 </button>
  //                 <div
  //                   className={
  //                     "button-with-counter" +
  //                     (newItemCount >= 1 ? " " : " manageCardItemBtnHide")
  //                   }
  //                 >
  //                   <i
  //                     className="fa fa-minus"
  //                     onClick={() =>
  //                       removeCartItem(serviceDetailsItem, newItemCount)
  //                     }
  //                   ></i>
  //                   <span>{newItemCount}</span>
  //                   <i
  //                     className={
  //                       "fa fa-plus cartItemCountBtn " +
  //                       (newItemCount >= 4 ? "disabled" : "")
  //                     }
  //                     onClick={() =>
  //                       addCardItem(serviceDetailsItem, newItemCount, "3333")
  //                     }
  //                   ></i>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="p-4  mt-2">
  //               <button
  //                 className="btn btn-primary"
  //                 onClick={() => updateServiceCount()}
  //               >
  //                 {t("update")}
  //               </button>
  //             </div>
  //           </div>
  //         </Modal.Body>
  //       </Modal>
  //       <AddressAddModal
  //         ref={setAddAddressRef}
  //         updateCheckoutData={updateCheckoutData}
  //       />
  //     </div>
  //   </>
  // );
};

export default ServiceDetails;
