import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey); // Replace with your Stripe publishable key
// Card logos for different brands
const cardBrandLogos = {
  visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
  mastercard:
    "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
  amex: "https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg",
  discover:
    "https://upload.wikimedia.org/wikipedia/commons/5/52/Discover_Card_logo.svg",
  diners:
    "https://upload.wikimedia.org/wikipedia/commons/0/04/Diners_Club_Logo5.png",
  jcb: "https://upload.wikimedia.org/wikipedia/commons/1/1b/JCB_logo.svg",
};

const CustomPaymentForm = ({
  setPayType,
  setPaymentMode,
  updateAppointmentData,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardBrand, setCardBrand] = useState("");
  const [addNewCard, setAddNewCard] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (error) {
      setIsProcessing(false);
      toast.error(error.message, {
        position: "top-right",
      });
    } else {
      console.log("Payment Method Created:", paymentMethod);
      setIsProcessing(false);
      setPaymentMode("card");
      setPayType("online");
      updateAppointmentData("payment", paymentMethod.card);
    }
  };

  const handleCardNumberChange = (event) => {
    if (event.complete) {
      setCardBrand(event.brand);
    } else {
      setCardBrand("");
    }
  };

  return (
    <>
      {!addNewCard && (
        <button
          className="right-space btn btn-appointmentSummary"
          onClick={() => {
            setAddNewCard(true);
          }}
        >
          Add New Card
        </button>
      )}
      {addNewCard && (
        <form onSubmit={handleSubmit} style={{ width: "300px" }}>
          <div className="d-flex justify-content-between">
            <div
              style={{ marginBottom: "20px", marginRight: "5%", width: "80%" }}
            >
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5%",
                  padding: "10px",
                }}
              >
                <CardNumberElement
                  onChange={handleCardNumberChange}
                  placeholder="Card Number"
                />
              </div>
            </div>
            <div style={{ marginBottom: "20px", alignContent: "center" }}>
              {cardBrand && (
                <img
                  src={cardBrandLogos[cardBrand] || ""}
                  alt={`${cardBrand} logo`}
                  title="Service payment cards"
                  style={{ width: "50px", height: "auto" }}
                />
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div style={{ marginBottom: "20px", width: "80%" }}>
              <label>Expiry</label>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5%",
                  padding: "10px",
                  width: "50%",
                }}
              >
                <CardExpiryElement />
              </div>
            </div>

            <div style={{ marginBottom: "20px", width: "80%" }}>
              <label>CVV</label>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5%",
                  padding: "10px",
                  width: "50%",
                }}
              >
                <CardCvcElement placeholder="111" />
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={!stripe || isProcessing}
            style={{ width: "40%" }}
            className="right-space btn btn-appointmentSummary"
          >
            {isProcessing ? "Processing..." : "Submit"}
          </button>

          <button
            type="button"
            disabled={!stripe || isProcessing}
            style={{ width: "40%", backgroundColor: "red" }}
            className="right-space btn btn-appointmentSummary"
            onClick={() => {
              setAddNewCard(false);
            }}
          >
            Cancel
          </button>
        </form>
      )}
    </>
  );
};

const StripePay = ({ setPayType, setPaymentMode, updateAppointmentData }) => {
  return (
    <Elements stripe={stripePromise}>
      <CustomPaymentForm
        setPayType={setPayType}
        setPaymentMode={setPaymentMode}
        updateAppointmentData={updateAppointmentData}
      />
    </Elements>
  );
};

export default StripePay;
