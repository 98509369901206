import { useEffect, useState } from "react";
import SuccessBooking from "../pages/appointment_booking/service/components/SuccessBooking";
import AppointmentSummary from "./AppointmentSummary";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "../helpers/helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ApptSummaryRedirect = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [bookingSuccessModal, setBookingSuccessModal] = useState(false);
  const [bookedAppt, setBookedAppt] = useState({});
  const [isTxnFailed, setIsTxnFailed] = useState(false);
  const [isTxnSuccess, setIsTxnSuccess] = useState(false);

  const baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const appointmentId = urlParams.get("appointmentId");
  console.log("abc ", { appointmentId });

  useEffect(() => {
    if (status === "Failed") {
      setIsTxnFailed(true);
    }
    if (status === "Paid") {
      axios
        .get(`${baseUrl}/appointment/edit/${appointmentId}`, {
          headers: authHeader(),
        })
        .then((response) => {
          setBookedAppt(response.data?.appointment);
          setIsTxnSuccess(true);
          setBookingSuccessModal(true);
          return <></>;
        })
        .catch((error) => {
          console.error("Error fetching appointment details:", error);
          toast.error("Failed to fetch appointment details", {
            position: "top-right",
            closeOnClick: true,
          });
        });
    }
  }, [appointmentId]);

  function XCircleIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="red"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="m15 9-6 6" />
        <path d="m9 9 6 6" />
      </svg>
    );
  }

  return (
    <>
      {status === "Paid" ? (
        <SuccessBooking
          bookingSuccessModal={bookingSuccessModal}
          appointment={bookedAppt}
          bookingData={bookedAppt}
        />
      ) : (
        <AppointmentSummary />
      )}

      {status === "Failed" && (
        <>
          <div className="card">
            <XCircleIcon className="check-icon" />
            <h1 className="title"> {t("payment_fail")}</h1>
            <p className="message">{t("payment_fail_msg")}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary"
                style={{ width: 120 }}
                onClick={() => navigate("/")}
              >
                {t("home")}
              </button>
            </div>

            {/* <div className="link">Please check your email for further details.</div> */}
          </div>
        </>
      )}
    </>
  );
};

export default ApptSummaryRedirect;
