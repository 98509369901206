import React, { useState, useEffect, useRef } from "react";
import "../HamburgerMenu.css";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ModifiedToastContainer } from "./ModifiedToastContainer";
// import CountryModal from './CountryModal'
import Modal from "react-bootstrap/Modal";
import jQuery from "jquery";
import { updateLanguageAction } from "../actions/updateLanguageAction";
import { countryListAction } from "../actions/userActions";
import ReactCountryFlag from "react-country-flag";
import { updateCountryAction } from "../actions/updateCountryAction";
import MapSearch from "./MapSearch";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import { ValidateCountry } from "../helpers/bookingAPIsteps";
import axios, { Axios } from "axios";
import LoginModal2 from "./LoginModal2";

const HamburgerMenu = ({ isLoggedIn = false }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const loginSignupRef = useRef();
  const [countryData, setCountryData] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [currentCountryCode, setCurrentCountryCode] = useState("QA");
  const [loginModalShow, setLoginModalShow] = useState(false);
  const handleLoginModalShow = () => {
    setLoginModalShow(true);
  };
  const handleLoginModalHide = () => setLoginModalShow(false);

  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  const getLanguageState = useSelector((state) => state.updateLanguageReducer);
  // const {loading} = getLanguageState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let selectedCountryId = "";

  async function updateLanguage() {
    let listUrl = baseUrl + "/customer/update/language";
    var selectedLanguage = "";
    if (currentLanguage && currentLanguage === "ar") {
      selectedLanguage = "en";
    } else {
      selectedLanguage = "ar";
    }
    dispatch(
      updateLanguageAction(
        listUrl,
        {
          current_language: selectedLanguage,
        },
        changeLanguage
      )
    );
  }

  async function changeLanguage(current_language) {
    localStorage.setItem("currentLanguage", current_language);
    i18next.changeLanguage(current_language);
    window.location.reload();
  }

  const logout = (e) => {
    toast.success(t("logout_successfully"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentToken");
      localStorage.removeItem("currentLatitude");
      localStorage.removeItem("currentLongitude");
      window.location.href = "/";
    }, 1000);
  };

  const [showCountryModal, setShowCountryModal] = useState(false);
  const handleCountryModalShow = () => setShowCountryModal(true);
  const handleCountryModalClose = () => setShowCountryModal(false);

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  const getUpdateData = useSelector((state) => state.updateCountryReducer);
  const [locationSearch, setLocationSearch] = useState("");

  let listUrl = baseUrl + "/country/list";
  let countryUpdateUrl = baseUrl + "/customer/update/country";

  useEffect(() => {
    setLocationSearch(sessionStorage.getItem("address"));
    dispatch(countryListAction(listUrl, {}));
  }, [listUrl, dispatch]);

  const countryInformation = () => {
    handleCountryModalShow();
  };

  if (
    localStorage.getItem("userCountryId") &&
    localStorage.getItem("userCountryCode")
  ) {
    selectedCountryId = localStorage.getItem("userCountryId");
  } else {
    if (currentUser) {
    }
    // selectedCountryId = "638452497da67f7c6bf4fdcd";
    // localStorage.setItem('userCountryId',selectedCountryId);
    // localStorage.setItem('userCountryCode',"AE");
    // console.log(countryData)
    // selectedCountryId = countryData._id;
    // localStorage.setItem('userCountryId',selectedCountryId);
    //  localStorage.setItem('userCountryCode',countryData.country_flag);
  }

  async function updateCountry(e) {
    const country_and_flag = "123";
    const country_id = country_and_flag.substring(
      0,
      country_and_flag.indexOf(",")
    );
    const country_code = country_and_flag.split(",")[1].trim();
    dispatch(updateCountryAction(countryUpdateUrl, country_id));

    setTimeout(() => {
      localStorage.removeItem("userCountryId");
      localStorage.removeItem("userCountryCode");
      localStorage.setItem("userCountryId", country_id);
      localStorage.setItem("userCountryCode", country_code);
      localStorage.setItem("currentCountryCode", country_code);
      window.location.reload();
    }, 500);

    if (country_list?.length > 0) {
      let country_obj = country_list.find(
        (o) => o.country_flag === localStorage.getItem("currentCountryCode")
      );
      if (country_obj && country_obj?.isActive) {
        localStorage.setItem("userCountryId", country_obj?._id);
        localStorage.setItem("userCountryCode", country_obj?.country_flag);
        localStorage.setItem("currentCountryCode", country_obj?.country_flag);
      }
    }
  }

  async function changeLanguage() {
    var selectedLanguage = "";
    if (currentLanguage && currentLanguage === "ar") {
      selectedLanguage = "en";
    } else {
      selectedLanguage = "ar";
    }
    localStorage.setItem("currentLanguage", selectedLanguage);
    i18next.changeLanguage(selectedLanguage);
  }

  const getcountry = (addressList) => {
    let country_name = "";
    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i].types[0]) {
        for (let j = 0; j < addressList[i].types.length; j++) {
          if (addressList[i].types[j] === "country") {
            country_name = addressList[i].address_components[0];
            return country_name;
          }
        }
      }
    }
  };
  const getCountryFlagName = async () => {
    if (countryData) {
      let countryList = countryData?.find((f) => f.name === countryName);
      if (countryList?._id) {
        setCountryId(countryList?._id);

        setCurrentCountryCode(countryList.country_flag);
        localStorage.setItem("userCountryId", countryList._id);
        localStorage.setItem("userCountryId", countryList._id);
        localStorage.setItem("userCountryCode", countryList.country_flag);
        localStorage.setItem("currentCountryCode", countryList.country_flag);
      }
    }
  };
  const getCountryName = async () => {
    if (localStorage.getItem("defaultLocation") !== "false") {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
            },

            (error) => {
              if (error.message === "User denied Geolocation") {
                alert("Please allow location access.");
                window.location.href = "app-settings:location";
              }
            }
          );
        } catch (error) {
          console.log("Error", error);
        }
        navigator.geolocation.getCurrentPosition(async function (position) {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let data = await axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            )
            .then(async (res) => {
              let countryComponent = await getcountry(res.data.results);
              let newShortName = countryComponent?.short_name;
              let url = baseUrl + `/validate/location`;
              let data = { country_code_short: newShortName };
              const isCountryValid = await ValidateCountry({
                baseUrl: url,
                data,
              });
              console.log("isCountryValid", isCountryValid);
              if (isCountryValid) {
                setCountryName(countryComponent?.long_name);
              } else {
                localStorage.setItem(
                  "userCountryId",
                  "636cd9cb43c4dcc60ce2fbd7"
                );
                setCountryName("Qatar");
              }
            })
            .catch((e) => {
              console.log("Error", e);
            });
        });
      }
    }
  };

  const country_list_data = async () => {
    await axios({
      method: "GET",
      url: baseUrl + "/country/list",
    }).then((res) => {
      if (res?.data?.list?.length > 0) {
        setCountryData(res.data.list);
      }
    });
  };
  useEffect(() => {
    if (countryId) {
      localStorage.setItem("userCountryId", countryId);
    }
    if (localStorage.getItem("userCountryCode")) {
      setCurrentCountryCode(localStorage.getItem("userCountryCode"));
    } else {
      localStorage.setItem("userCountryId", "636cd9cb43c4dcc60ce2fbd7");
      localStorage.setItem("userCountryCode", "QA");
      setCountryName("Qatar");
    }
  }, [countryId]);

  useEffect(() => {
    country_list_data();
  }, [countryName]);

  useEffect(() => {
    if (countryData) {
      getCountryFlagName();
    }
  }, [countryData]);

  if (
    localStorage.getItem("userCountryId") &&
    localStorage.getItem("userCountryCode")
  ) {
    selectedCountryId = localStorage.getItem("userCountryId");
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar-hb">
        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`bar`}></div>
          <div className={`bar`}></div>
          <div className={`bar`}></div>
        </div>

        {/* Menu Items */}
        <ul className={`menu ${isOpen ? "show" : ""}`}>
          <Link
            to="/services"
            className="navbar-brand p-0  d-md-block navItems"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p className="hb-items">All Services</p>
          </Link>
          <Link
            to="/blog"
            className="navbar-brand p-0  d-md-block navItems"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p className="hb-items">Blog</p>
          </Link>
          <Link
            to="https://righthandssupport.zendesk.com/hc/en-us"
            className="navbar-brand p-0  d-md-block navItems"
            onClick={() => window.scrollTo(0, 0)}
          >
            <p className="hb-items">Help</p>
          </Link>
          <hr style={{ color: "black" }} />
          <Link
            className="d-flex align-items-center px-1"
            style={{ marginRight: "15px", color: "black" }}
            to="https://righthands.applytojob.com/apply"
          >
            Become Pro
          </Link>
          <hr />
          {isLoggedIn && (
            <div
              className="loginbtn me-3 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {currentUser && currentUser.name ? currentUser.name : "User"}{" "}
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/arrow-right2.webp"
                alt=""
              />
            </div>
          )}
          {!isLoggedIn && (
            <div
              className="loginbtn me-3"
              onClick={() => handleLoginModalShow()}
              style={{ width: '50%' }}
            >
              {t("login_btn")}{" "}
              <img
                alt=""
                src="https://dvdljkkxpxqo3.cloudfront.net/images/user.webp"
              />
            </div>
          )}
          <hr />
          <ul className="dropdown-menu z-index-5">
            <li style={{ padding: "0px" }}>
              <NavLink
                to="/my-account/account-details"
                className="dropdown-item"
              >
                {t("account_details")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/profile.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li style={{ padding: "0px" }}>
              <NavLink to="/my-account/appointments" className="dropdown-item">
                {t("appointments")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/appointments.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li style={{ padding: "0px" }}>
              <NavLink
                to="/my-account/righthandcredit"
                className="dropdown-item"
              >
                {t("rh_credits")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/rh-credit2.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li style={{ padding: "0px" }}>
              <NavLink
                to="/my-account/righthandwallet"
                className="dropdown-item"
              >
                {t("rh_wallet")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/rh-credit2.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li style={{ padding: "0px" }}>
              <NavLink to="/membership" className="dropdown-item">
                {t("rh_club")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/rhclub.png.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li style={{ padding: "0px" }}>
              <NavLink to="/my-account/my-fav-pros" className="dropdown-item">
                {t("my_favourite_pros")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/myfav.webp"
                  alt=""
                />
              </NavLink>
            </li>

            {/* <li>
                <NavLink
                  to="/my-account/settings"
                  className="dropdown-item"
                >
                  {t('settings')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/settings.png.webp" />
                </NavLink>
              </li> */}
            {/* <li>
                <NavLink
                  to="/my-account/appointments"
                  className="dropdown-item"
                >
                  {t('appointments')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/appointments.png.webp" />
                </NavLink>
              </li>              */}
            {/* <li>
                <NavLink
                  to="/my-account/free/credit"
                  className="dropdown-item"
                >
                  {t('free_credit')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/Free-Credit.png.webp" />
                </NavLink>
              </li> */}
            {/* <li>
                <a
                  href="https://righthandssupport.zendesk.com/hc/en-us" 
                  target="_blank"
                  className="dropdown-item"
                >
                {t('help')} <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/help.png.webp" />
                </a>
              </li> */}

            <li style={{ padding: "0px" }}>
              <NavLink to="/my-account/free-service" className="dropdown-item">
                {t("invite_and_earn")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/invite-earn.webp"
                  alt=""
                />
              </NavLink>
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://righthandssupport.zendesk.com/hc/en-us")
              }
              style={{ padding: "0px" }}
            >
              <div
                // to=""
                className="dropdown-item"
              >
                {t("get_support")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/get-support.webp"
                  alt=""
                />
              </div>
            </li>

            <li style={{ padding: "0px" }}>
              <NavLink to="/my-account/settings" className="dropdown-item">
                {t("settings")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/settings.webp"
                  alt=""
                />
              </NavLink>
            </li>

            {/* <NavLink
                  to="#"
                  className="dropdown-item"
                  onClick={logout}
                >{t('logout')} <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/logout.png.webp" />
                </NavLink> */}
          </ul>
          <div className="roundedCircle d-flex align-items-center px-1">
            <ReactCountryFlag
              svg
              style={{ fontSize: "1.5em", lineHeight: "1.5em" }}
              countryCode={localStorage.getItem("userCountryCode")}
              onClick={countryInformation}
            />
            <div className="language ms-3">
              <div className="selectedLang" onClick={updateLanguage}>
                {currentLanguage && currentLanguage === "ar"
                  ? "English"
                  : "عربي"}
              </div>
            </div>
            {/* <Link className="p-0 d-block bellIcon pl-3" to="/announcement">
                   <span className="fa fa-bell"></span>
              </Link> */}
          </div>
        </ul>
        <LoginModal2
          showLoginModal={loginModalShow}
          hideLoginModal={handleLoginModalHide}
        />
        <Modal
        show={showCountryModal}
        onHide={handleCountryModalClose}
        className="changeCountry"
        id="changeCountry"
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">{t("select_your_country")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {country_list?.length > 0
              ? country_list.map((country, index) => {
                return (
                  <div key={index}>
                    {country?.isActive && (
                      <div>
                        <div className="w-full languageChecked">
                          {/* <div className="roundedCircle d-flex align-items-center px-1"> */}
                          <input
                            type="radio"
                            value={country?.id + "," + country?.country_flag}
                            name="country_id"
                            className="language"
                            defaultChecked={country?.id === selectedCountryId}
                            style={{ position: 'static', width: '10%' }}
                          />
                          <ReactCountryFlag
                            svg
                            style={{ fontSize: "1.5em", lineHeight: "1.5em", width: '1.5em', height: '1.5em', marginRight: '5%' }}
                            countryCode={country.country_flag}
                            onClick={countryInformation}
                          />
                          {/* </div> */}
                          <p>
                            {currentLanguageCode === "en"
                              ? country?.name
                              : country?.name_arabic}
                          </p>
                          {/* <i className="fa fa-check" /> */}
                        </div>
                        <hr />
                      </div>
                    )}
                  </div>
                );
              })
              : ""}
            <button
              type="button"
              className="btn btn-primary w-full mt-2"
              onClick={(e) => updateCountry(e)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      </nav>
    </>
  );
};

export default HamburgerMenu;
