import React, {useRef} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import {t} from "i18next";
import { useNavigate } from "react-router-dom";

const FullImageSlider = () => {
  const loginSignupRef = useRef();
  const navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;

  const options = {
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    rtl: true,
    ltr: false,
    autoplayTimeout: 3000,
    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 1000,
    responsive: [{
      0: {
        items: 1,
        margin: 0
      },
      1200: {
        items: 1
      }
    }]
  };

  const settings = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true, // for next/prev buttons
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides on tablet
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
        },
      },
    ],
    ...options,
  };

  return (
    <>
      <div className="fullSlider mt-5">
        <Slider {...settings}>
          <div className="item">
            <div
              className="SliderbgImage"
              style={{
                backgroundImage: `url("https://dvdljkkxpxqo3.cloudfront.net/images/bnr.webp")`
              }}
            >
              <div className="container">
                <div className="sliderContent">
                  <h2>{t("unlock_world_of_rewards")}</h2>
                  <h3>
                    {t("With_right_hands_club")} <br></br>{" "}
                    {t("best_home_services_Provider")}{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div
              className="SliderbgImage"
              style={{
                backgroundImage: `url("https://dvdljkkxpxqo3.cloudfront.net/images/bnr2.webp")`
              }}
            >
              <div className="container">
                <div className="sliderContent">
                  <h2>{t("your_rh_club_benefits")}</h2>
                  <h3>{t("log_access_your_rewards")}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div
              className="SliderbgImage"
              style={{
                backgroundImage: `url("https://dvdljkkxpxqo3.cloudfront.net/images/bnr3.webp")`
              }}
            >
              <div className="container">
                <div className="sliderContent">
                  <h2>{t("your_rh_club_benefits")}</h2>
                  <h3>{t("log_access_your_rewards")}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div
              className="SliderbgImage"
              style={{
                backgroundImage: `url("https://dvdljkkxpxqo3.cloudfront.net/images/bnr4.webp")`
              }}
            >
              <div className="container">
                <div className="sliderContent">
                  <h2>{t("unlock_world_of_rewards")}</h2>
                  <h3>
                    {t("With_right_hands_club")} <br></br>{" "}
                    {t("best_home_services_Provider")}{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr2.jpg.webp" />
            <div className='container'>
              <div className='sliderContent'>
                <h2>{t('unlock_world_of_rewards')}</h2>
                <h3>{t('With_right_hands_club')} <br></br> {t('best_home_services_Provider')} </h3>
              </div>
            </div>
          </div> */}
          {/* <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr.jpg.webp" />
            <div className='container'>
              <div className='sliderContent'>
                <h2>{t('your_rh_club_benefits')}</h2>
                <h3>{t('log_access_your_rewards')}</h3>
              </div>
            </div>
          </div>
          <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr3.jpg.webp" />
            <div className='container'>
              <div className='sliderContent'>
                <h2>{t('your_rh_club_benefits')}</h2>
                <h3>{t('log_access_your_rewards')}</h3>
              </div>
            </div>
          </div>
          <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr4.jpg.webp" />
            <div className='container'>
              <div className='sliderContent'>
                <h2>{t('your_rh_club_benefits')}</h2>
                <h3>{t('log_access_your_rewards')}</h3>
              </div>
            </div>
          </div> */}
        </Slider>
        {(() => {
          return (
            <div>
              <div className="container">
                <div className="loginButton">
                  <div className="form-group">
                    {!currentUser && (
                      <button
                        className="btn btn-primary btnLarge "
                        onClick={() => {
                          loginSignupRef.current.handleLoginSignupModalShow();
                        }}
                      >
                        {t("login")}
                      </button>
                    )}
                    <button
                      className="btn btn-secondary btnLarge "
                      onClick={() => {
                        !currentUser ? navigate('/membership') : 
                        navigate("/my-account/righthandcredit")
                      }}
                    >
                      {t("join_today")}
                    </button>
                  </div>
                </div>
              </div>
              <LoginSignupModal ref={loginSignupRef} />
            </div>
          );
        })()}
      </div>
    </>
  );
};

export default FullImageSlider;
