import Header from "./Header";
import { useTranslation } from "react-i18next";
import ScreenedProfessionals from "./ScreenedProfessionals";
import HappynessGuarantee from "./HappynessGuarantee";
import AppDownload from "./AppDownload";
import ManyReason from "./ManyReason";
import Testimonilas from "./Testimonilas";
import HomeServices from "./HomeServices";
import ServicesCard from "./ServicesCard";
import RatedPros from "./RatedPro";
import { useEffect, useRef, useState } from "react";
import HomeAllServices from "../pages/all_services/HomeAllServices";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { countryListAction } from "../actions/userActions";
import ReactCountryFlag from "react-country-flag";
import jQuery from "jquery";
import { updateCountryAction } from "../actions/updateCountryAction";
import AddressSetupComponent from "./LoginSignup/SetupAccountModals/AddressSetupComponent";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";

const HomeNew = () => {
  const { t } = useTranslation();
  const [searchedLocation, setSearchedLocation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  const dispatch = useDispatch();
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const selectedCountryId = localStorage.getItem("userCountryId");
  const loginSignupRef = useRef();
  const [showSetupAddressModal, setShowSetupAddressModal] = useState(false);
  const [locationPermissionLoader, setLocationPermissionLoader] = useState(
    !sessionStorage.getItem("hasVisited")
  ); // Blocks interaction until response

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/country/list";
  let countryUpdateUrl = baseUrl + "/customer/update/country";

  useEffect(() => {
    dispatch(countryListAction(listUrl, {}));
  }, [listUrl, dispatch]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setLocationPermissionLoader(false);
    setShowModal(true);
  };
  const handleBackButton = () => {
    setShowModal(true);
    setShowSetupAddressModal(false);
    sessionStorage.setItem("hasVisited", false);
  };

  async function updateCountry(e) {
    const country_and_flag = jQuery("input[name=country_id]:checked").val();
    const country_id = country_and_flag.substring(
      0,
      country_and_flag.indexOf(",")
    );
    const country_code = country_and_flag.split(",")[1].trim();
    dispatch(updateCountryAction(countryUpdateUrl, country_id));

    const selectedCountry = country_list.find(
      (country) => country.id === country_id
    );
    localStorage.setItem("latitude", selectedCountry?.capitalLatitude);
    localStorage.setItem("longitude", selectedCountry?.capitalLongitude);
    localStorage.setItem("currentLatitude", selectedCountry?.capitalLatitude);
    localStorage.setItem("currentLongitude", selectedCountry?.capitalLongitude);
    localStorage.setItem("mapLatitude", selectedCountry?.capitalLatitude);
    localStorage.setItem("mapLongitude", selectedCountry?.capitalLongitude);
    sessionStorage.setItem(
      "selectedCountryLat",
      selectedCountry?.capitalLatitude
    );
    sessionStorage.setItem(
      "selectedCountryLng",
      selectedCountry?.capitalLongitude
    );

    setShowModal(false);
    setShowSetupAddressModal(true);
    sessionStorage.setItem("hasVisited", true);
    loginSignupRef.current.handleLoginSignupModalShow();

    setTimeout(() => {
      localStorage.removeItem("userCountryId");
      localStorage.removeItem("userCountryCode");
      localStorage.setItem("userCountryId", country_id);
      localStorage.setItem("userCountryCode", country_code);
      localStorage.setItem("currentCountryCode", country_code);
      sessionStorage.setItem("selectedCountry", country_code);
    }, 500);

    if (country_list?.length > 0) {
      let country_obj = country_list.find(
        (o) => o.country_flag === localStorage.getItem("currentCountryCode")
      );
      if (country_obj && country_obj?.isActive) {
        localStorage.setItem("userCountryId", country_obj?._id);
        localStorage.setItem("userCountryCode", country_obj?.country_flag);
        localStorage.setItem("currentCountryCode", country_obj?.country_flag);
        sessionStorage.setItem("selectedCountry", country_code);
        setShowModal(false);
        setShowSetupAddressModal(true);
        sessionStorage.setItem("hasVisited", true);
        loginSignupRef.current.handleLoginSignupModalShow();
      }
    }
  }

  useEffect(() => {
    if (country_list?.length > 0) {
      setSearchedLocation(sessionStorage.getItem("address"));
      handleEnableLocation();
    }
  }, [country_list]);

  const handleEnableLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();

            if (data.results.length > 0) {
              // Find country in address components
              const countryComponent = data.results[0].address_components.find(
                (component) => component.types.includes("country")
              );

              if (countryComponent && sessionStorage.getItem("hasVisited") !== "true") {
                const country = country_list?.find(
                  (c) => c.country_flag === countryComponent.short_name
                );

                if (!country) {
                  handleModalOpen();
                } else {
                  setShowModal(false);
                  sessionStorage.setItem("hasVisited", true);
                  setLocationPermissionLoader(false);
                  localStorage.setItem("latitude", country?.capitalLatitude);
                  localStorage.setItem("longitude", country?.capitalLongitude);
                  localStorage.setItem(
                    "currentLatitude",
                    country?.capitalLatitude
                  );
                  localStorage.setItem(
                    "currentLongitude",
                    country?.capitalLongitude
                  );
                  localStorage.setItem("mapLatitude", country?.capitalLatitude);
                  localStorage.setItem(
                    "mapLongitude",
                    country?.capitalLongitude
                  );
                  sessionStorage.setItem(
                    "selectedCountryLat",
                    country?.capitalLatitude
                  );
                  sessionStorage.setItem(
                    "selectedCountryLng",
                    country?.capitalLongitude
                  );
                  localStorage.setItem("userCountryId", country?.id);
                  localStorage.setItem(
                    "userCountryCode",
                    country?.country_flag
                  );
                  localStorage.setItem(
                    "currentCountryCode",
                    country?.country_flag
                  );
                  sessionStorage.setItem(
                    "selectedCountry",
                    country?.country_flag
                  );
                  if (sessionStorage.getItem("hasVisited") !== "true") {
                    loginSignupRef.current.handleLoginSignupModalShow();
                  }
                }
              } else {
                console.log("Could not determine country");
                handleModalOpen();
              }
            } else {
              console.log("No location data found");
              handleModalOpen();
            }
          } catch (error) {
            console.log("Error fetching country:", error);
            handleModalOpen();
          }
        },
        (error) => {
          console.log("No"); // User denied location
          handleModalOpen();
        }
      );
    } else {
      console.log("No"); // Geolocation not supported
      handleModalOpen();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          RightHands | Home Services in Qatar, UAE and Saudi Arabia | خدمات
          منزلية في قطر، الإمارات العربية المتحدة والمملكة العربية السعودية
        </title>
        <meta
          name="description"
          content="Looking for reliable home services? We offer professional home cleaning, dry cleaning, babysitting, pet care, and more! Fast, affordable, and hassle-free in Qatar, UAE and Saudi Arabia. Book today! | تبحث عن خدمات منزلية موثوقة؟ نحن نقدم خدمات تنظيف المنازل، التنظيف الجاف، رعاية الأطفال، رعاية الحيوانات الأليفة والمزيد! سريعة، ميسورة التكلفة، وخالية من المتاعب في قطر، الإمارات العربية المتحدة والمملكة العربية السعودية. احجز اليوم!"
        />
        <link rel="canonical" href="https://betaweb.myrighthands.com/" />
      </Helmet>
      <Header />
      {searchedLocation ? (
        <HomeAllServices />
      ) : (
        <>
          <HomeServices />
          <ServicesCard />
        </>
      )}
      <ScreenedProfessionals />
      <HappynessGuarantee />
      <AppDownload />
      {/* <div>
      <h3>Are you a top-rated pro?</h3>
      <p>RightHand is always on the lookout for talented service professionals, from cleaners and handymen to smart home installers, who are committed to delivering outstanding customer service.
         The best in the business rely on Right Hand for job opportunities with no lead fees and flexible scheduling.</p>
         <button> Become a Pro</button>
    </div> */}
      <RatedPros />
      <ManyReason />
      <Testimonilas />
      <Footer />
      <Modal
        show={showModal && sessionStorage.getItem("hasVisited") !== "true"}
      >
        <Modal.Header>
          <Modal.Title>{t("where_to_serve")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "5px 25px 0px" }}>
          <div className="modal-body">
            {country_list?.length > 0
              ? country_list.map((country, index) => {
                  return (
                    <div key={index}>
                      {country?.isActive && (
                        <div>
                          <label
                            className="w-full languageChecked-homepage-modal"
                            style={{ borderBottom: "1px solid #e5e5e5", cursor:"pointer" }}
                          >
                            <div className="d-flex align-items-center px-1">
                              {/* <div className="roundedCircle d-flex align-items-center px-1"> */}
                              <ReactCountryFlag
                                svg
                                style={{
                                  fontSize: "1.5em",
                                  lineHeight: "1.5em",
                                  width: "1.5em",
                                  height: "1.5em",
                                  marginRight: "15%",
                                  borderRadius: "50%",
                                }}
                                countryCode={country.country_flag}
                              />
                              <p
                                style={{ width: "166px" }}
                                className="text-left"
                              >
                                {currentLanguageCode === "en"
                                  ? country?.name
                                  : country?.name_arabic}
                              </p>
                            </div>

                            <input
                              type="radio"
                              value={country?.id + "," + country?.country_flag}
                              name="country_id"
                              className="language"
                              defaultChecked={country?.id === selectedCountryId}
                              style={{ position: "static", width: "10%" }}
                            />
                          </label>
                        </div>
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0px 5px 0px 5px" }}>
          <button
            type="button"
            className="btn btn-primary w-full mt-2"
            onClick={(e) => updateCountry(e)}
          >
            {t("select_country")}
          </button>
        </Modal.Footer>
      </Modal>
      <LoginSignupModal
        ref={loginSignupRef}
        showBackButton={true}
        handleBackButton={handleBackButton}
      />
      <Modal show={locationPermissionLoader} centered>
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50px" }}
          >
            <div className="spinner-border" role="status"></div>
            <br />
            <p>
              <br />
              Please select location permission
            </p>
          </div>
        </Modal.Body>
      </Modal>
      )
    </>
  );
};

export default HomeNew;
