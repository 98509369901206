import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const RatedPros = () => {
    const { t } = useTranslation();
    return (
        <div className="container"  style={{ marginTop: '1%', marginBottom: '1%' }}>
            <div className="become-pro">
                <div className="grid-container">
                    <div className="grid-x align-middle">
                        <div className="cell small-12 medium-5 become-pro-container">
                            <div className="cell become-pro-title">{t("rated_pro")}</div>
                            <div className="cell become-pro-para">{t("lookout_talented_pros")}
                               {/* The best in the business rely on Right Hand for job opportunities with no lead fees and flexible scheduling. */}
                            </div>
                            <div className="cell">
                                <Link to="/become-pro" className="pro-button color-rh mixpanel">
                                    {t("become_pro")}
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src="/images/pro.webp" alt="loading pro images" title="professionals" style={{ height: '720px', width: '480px'}} className="hide-small-screen"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatedPros