import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBlogsAction } from "../actions/BlogsAction";
import NavMenu from "./NavMenu";
import Header from "./Header";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getAllBlogsState = useSelector((state) => state.blogsReducer);
  const { blogs_list } = getAllBlogsState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/blog/list";
  useEffect(() => {
    dispatch(getBlogsAction(listUrl));
  }, [listUrl, dispatch]);

  return (
    <>
      <Helmet>
        <title>RightHands | Discover and explore our cleaning services through Blogs</title>
        <meta
          name="description"
          content="Service details, view, explore and book services online in Qatar, UAE and Saudi Arabia. RightHand is the best service provider. Book now!"
        />
        <link
          rel="canonical"
          href="https://betaweb.myrighthands.com/blog"
        />
      </Helmet>
      <Header />
      <div className="blogSec pt-7">
        <div className="container">
          <div className="heading">
            <h2>{t("explore_our_blogs")}</h2>
            {/* <h2>{ t('explore_our_blogs') }</h2> */}
          </div>
          <div className="row">
            {blogs_list?.list?.length > 0
              ? blogs_list?.list.map((blog, index) => {
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  const created_at = new Date(
                    blog.createdAt
                  ).toLocaleDateString([], options);
                  const bodyEn = blog.body.replace(/<(.|\n)*?>/g, "");
                  const blogBodyEn = bodyEn.substring(0, 300);
                  const bodyAr = blog.body_arabic.replace(/<(.|\n)*?>/g, "");
                  const blogBodyAr = bodyAr.substring(0, 300);

                  return (
                    <div className="col-lg-4 col-sm-6 col-md-4" key={index}>
                      {
                        <div className="blogDiv">
                          <div className="blogSecImage">
                            <img
                              alt=""
                              src={
                                blog?.icon
                                  ? blog?.icon
                                  : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.webp"
                              }
                            />
                          </div>
                          <div className="blogContent text-center">
                            <NavLink
                              to={"/read/blog/" + blog._id}
                              state={{ blog: blog, created_at: created_at }}
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              <button className="btn btn-primary">
                                {t("read_more")}
                              </button>
                            </NavLink>
                            <h3>
                              {currentLanguage === "en"
                                ? blog.title
                                : blog.title_arabic}
                            </h3>
                            <span>{created_at}</span>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: blog
                                  ? currentLanguage === "en"
                                    ? blogBodyEn
                                    : blogBodyAr
                                  : "",
                              }}
                            ></p>
                          </div>
                        </div>
                      }
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
