import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useDispatch} from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ModifiedToastContainer } from "../../components/ModifiedToastContainer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import countries from "../../components/CountryCodes.json";
import { getEditData, saveForm, saveProfile} from "../../helpers/helpers";
import { profileUpdateAction } from "../../actions/userActions";

import { useEffect, useState } from "react";
import jQuery from "jquery";
import { useTranslation } from 'react-i18next';
import es from 'react-phone-input-2/lang/es.json'
import ar from 'react-phone-input-2/lang/ar.json'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import _ar from 'date-fns/locale/ar';
import _es from 'date-fns/locale/es';
const moment = require("moment");

let displayContent = {
  displayNone:{
      display:'none'
  },
  displayBlock:{
      display:'block'
  }
}
const EditPersonalDetails = () => {
  const currentLanguageCode = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  const [customer, setCustomer] = useState({
    country_code: "",
    mobile: "",
    name: "",
    email:"",
    gender: "",
    dob: "",
    otp:""
  });
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const handleOtpModalShow = () => setShowOtpModal(true);
  const handleOtpModalClose = () => { setShowOtpModal(false);
                                      window.location.reload(); };
  if(currentLanguageCode === 'en'){
    registerLocale('es', _es )
  }else{
    registerLocale('ar', _ar )
  }
  const dispatch = useDispatch();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let getUrl = baseUrl + "/customer/get/profile";
  let awaitUrl = baseUrl + "/customer/update/profile";
  useEffect(() => {
    getEditData(getUrl, setCustomer).catch((err) => console.log(err));
  }, []);
  const [startDate, setStartDate] = useState('');
  const [mobileState, setMobileState] = useState('');
  
  var dob = (customer && customer.dob) ? moment(customer.dob, ["YYYY-MM-DDT00:00:00.000Z"]).format("YYYY/MM/DD") : "";
  if(dob){
    dob = new Date(dob)
  }

  var timeOut;
  function startOTPTimeOut(){
    if(timeOut) {
      stopOTPTimeOut();
      clearInterval(timeOut);
    }
    var time1 = 60;
    var time2 = 0;
    timeOut = setInterval(function(){ 
      time2 = time1--;
      jQuery('.startTimer').html("00:"+((time2 < 10) ? '0'+time2:time2));
      if (time2 === 0) {
        clearInterval(timeOut);
        resendEvent();
      }
    }, 1000);
  }
  function stopOTPTimeOut() {
    clearInterval(timeOut);
  }

  const typeOtp = async (e) => {
      let otp = jQuery('#otp').val();
      if(otp.length > 0){
          jQuery('.otpContinueBtn').removeAttr("disabled");
          jQuery('.otpContinueBtn').removeClass("disabled");
          jQuery('.spinner-border').css("display", "block");
      }else{
          jQuery('.spinner-border').css("display", "none"); 
          jQuery('.otpContinueBtn').attr("disabled", "disabled");
          jQuery('.otpContinueBtn').addClass("disabled");
      }

      if((otp.length === 4 && customer.otp !== otp)){
        jQuery('.resendOtpError').css("display", "block");
        jQuery('.resendOtpError').html(t('invalid_otp'));
      }

      dispatch(profileUpdateAction(awaitUrl,customer,profileUpdateSuccess,setPageLoading));
  }
  
  const resendOtpSend = (e) => {
    jQuery('.resend-otp').css("display", "none");
    jQuery('.resendOtpError').css("display", "none");
    jQuery('.resendOtpError').html("");
    jQuery('.otpSent').css("display", "block");
    jQuery('.otpSent').html(t('otp_sent_on_your_mobile'));
    startOTPTimeOut();
}

const resendEvent = (e) => {
    jQuery('.resendOtpError').css("display", "block");
    jQuery('.resend-otp').css("display", "block");
}

const userCountryCode = localStorage.getItem('userCountryCode') ? localStorage.getItem('userCountryCode') : 'en';
var country = userCountryCode.toLocaleLowerCase();
  if (currentUser && currentUser.mobile) {
    const mobileNumber1 = currentUser.mobile;
    const mobileNumber = mobileNumber1.split(" ");
    if (mobileNumber[1] && mobileNumber[1].length > 0) {
      jQuery.each(countries.countries, function (i, value) {
        if(mobileNumber[0] === value.dial_code){
          var code = value.code;
          country = code.toLowerCase();
          return false;
        }
      });
    }
  }

  const changeMobile = (phone) => {
    updateCustomerData("mobile",phone);
  };

  const changeMobileState = (phone) => {
    setMobileState(phone);
    updateCustomerData("country_code",phone)
  };

  const changeDob = (dob) => {
    setStartDate(dob);
    updateCustomerData("dob",moment(dob, ["DD/MM/YYYY",]).format("YYYY-MM-DD"))
  };

  const profileUpdateSuccess = (response) => {
    setPageLoading(false)
    if(response.data.updateMobile){
      handleOtpModalShow();
      jQuery('.phone-number').html(customer.country_code+customer.mobile);
      // jQuery('#otp').val(response.data.otp);
      // updateCustomerData("otp",response.data.otp);
      jQuery('.otpContinueBtn').removeAttr("disabled");
      jQuery('.otpContinueBtn').removeClass("disabled");
      startOTPTimeOut();
    }else{
      handleOtpModalClose();
      //localStorage.setItem('currentUser', JSON.stringify(response.data.user))
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
       //window.location.href = "/my-account/personal-details"
      }, 1000);
    }
  };

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    /*   customer['mobile'] = jQuery('#mobile').val();
    console.log(customer) */
    dispatch(profileUpdateAction(awaitUrl,customer,profileUpdateSuccess,setPageLoading));
  };

  const updateCustomerData = (key,value) => {
      setCustomer((prevState) => ({
        ...prevState,
        [key]: value
      }));
  }

  const handleFormOtpSubmit = (e) => {
    e.preventDefault();
    dispatch(profileUpdateAction(awaitUrl,customer,profileUpdateSuccess,setPageLoading));
  };

  return (
    <>
      {pageLoading ? <Loader/> : ""}
      <Header />
      <HelmetProvider>
        <Helmet>
          <title>RightHands | Update Profile</title>
        </Helmet>
      </HelmetProvider>
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="editPersonalDetail">
            <div className="heading">
           <h2>Create Support Tickets</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                         

              <form onSubmit={handleCustomerSubmit} id="profileForm">
                  <div className="row loginMobileInput">
                  <div className="inputdiv col-lg-3 ">
                    <ReactPhoneInput
                      inputProps={{
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly",
                      }}
                      localization={currentLanguageCode === 'en' ? es : ar}
                      value={mobileState ? mobileState : (currentUser ? currentUser.country_code : null)}
                      onChange={(phone) => changeMobileState(phone)}
                      country={country}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      containerStyle={{ marginTop: "15px" }}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px",
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <input
                      type="hidden"
                      name="otp"
                      id="newotp"
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                    <div className="inputdiv col-lg-9 ">
                    <input
                        type="number"
                        name="mobile"
                        id="mobile"
                        className="form-control mt-3"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={e => 
                          changeMobile(e.target.value)
                        }
                        defaultValue={
                        customer && customer.mobile ? customer.mobile : ""
                        }
                        placeholder="33333 3333"
                      />
                      <div className="help-block text-danger"></div>
                    </div>
                  </div>

                  <div className="inputdiv col-lg-12 ">
                    <label>{t("email")}</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      defaultValue={
                        customer && customer.email ? customer.email : ""
                      }
                      onChange={e => updateCustomerData("email",e.target.value)}
                      className="form-control"
                      placeholder="e.g. name@example.com"
                    />
                    <span className="removeinput">
                      <i className="fa fa-close"></i>
                    </span>
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputdiv col-lg-12 ">
                    <label>{t("your_full_name")}</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      defaultValue={
                        customer && customer.name ? customer.name : ""
                      }
                      onChange={e => updateCustomerData("name",e.target.value)}
                      placeholder={t("your_full_name")}
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputdiv col-lg-12 ">
                    <label>{t('date_of_birth_optional')}</label>
                    <DatePicker
                      className="form-control"
                      name="dob"
                      id="dob"
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => changeDob(date)}
                      locale={(currentLanguageCode === 'en' ? '' : 'ar')}
                      selected={(startDate ? startDate : (dob ? dob : null))}
                      minDate={new Date("02-01-1974")}
                      maxDate={new Date("02-29-2020")}
                      placeholderText={t('date_of_birth_optional')}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="col-lg-12 inputdiv">
                    <label>{t("gender")}</label>
                    <select
                      className="form-select pl-0 genderSelect"
                      name="gender"
                      id="gender"
                      onChange={e => updateCustomerData("gender",e.target.value)}
                    >
                      <option
                        value={"Mr."}
                        selected={
                          customer &&
                          customer.gender &&
                          customer.gender === "Mr"
                            ? "selected"
                            : ""
                        }
                      >
                        {t("gender_mr")}
                      </option>
                      <option
                        value={"Ms."}
                        selected={
                          customer &&
                          customer.gender &&
                          customer.gender === "Ms"
                            ? "selected"
                            : ""
                        }
                      >
                        {t("gender_ms")}
                      </option>
                      <option
                        value={"Rather Not Say"}
                        selected={
                          customer &&
                          customer.gender &&
                          customer.gender === "Rather Not Say"
                            ? "selected"
                            : ""
                        }
                      >
                        {t("gender_rather_not_say")}
                      </option>
                    </select>
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                    <button className="btn btn-primary w-full ">
                      {t("save")}
                    </button>
                  </div>
                </form> 
              </div>
            </div>
          </div>
        </div>
      </div>
      
        {/*  OTP Modal */}
        <Modal show={showOtpModal} onHide={handleOtpModalClose} id="otpVerify">
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {t('verify_phone_number')}<i className="flag flag-us"></i>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFormOtpSubmit}>
              <div className="">
                <label>{t('enter_otp_code_text')}</label>
                <p className="phone-number mb-4 phoneNumberDir"></p>
                <input
                  type="text"
                  id="otp"
                  className="form-control"
                  placeholder=""
                  maxLength="4"
                />
                <p className="mb-0 py-3 resentOtpTime">
                  {t('resend_code_in')}:
                  <p className="startTimer">00:30</p>
                </p>
                <p
                  className="text-danger resendOtpError"
                  style={displayContent.displayNone}
                ></p>
                <p
                  className="otpSent text-success"
                  style={displayContent.displayNone}
                ></p>
                <p
                  className="btn btn-light resend-otp"
                  onClick={resendOtpSend}
                  style={displayContent.displayNone}
                >
                  {t('resend_code_btn')}
                </p>
                <div className="loader">
                    <button type="submit" className="btn btn-primary w-full disabled otpContinueBtn" disabled="">{t('continue_btn')}</button>
                  <div
                    className="spinner-border text-dark "
                    role="status"
                    style={displayContent.displayNone}
                  >
                    {" "}
                    <span className="sr-only">Loading...</span>{" "}
                  </div>
                </div>
              </div>
          </form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default EditPersonalDetails;