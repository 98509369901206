import React, {useEffect, useState} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useDispatch, useSelector} from "react-redux";
import ProfessionalSlider from "../components/ProfessionalSlider";
import {getCleanerTimeAvailability} from "../../../../actions/cleanerTimeAvailability";
import Loader from "../../../../components/Loader";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import {getFavProInfoAction} from "../../../../actions/tcDataAction";
import {getVisitTimeInfoAction} from "../../../../actions/tcDataAction";
import Modal from "react-bootstrap/Modal";
import {date} from "yup";
import {holidayList} from "../../../../helpers/helpers";
import {useLocation} from "react-router-dom";
import {getMonthSlotsAPI} from "../../../../helpers/bookingAPIsteps";
import ShowTime from "./ShowTime";
import ProDates from "./ProDates";
import {toast} from "react-toastify";
const moment = require("moment");
const SelectDateTime = ({
  checkoutData,
  updateCheckoutData,
  setCheckoutData
}) => {
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const [isProLoad, setIsProLoad] = useState(false);
  const [carouselKey, setCarouselKey] = useState(0);
  const [holidayLists, setHolidayLists] = useState();
  const [disabledHoliday, setDisabledHoliday] = useState(false);
  const [holidayStatus, setHolidayStatus] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment()?.date()?.toString()
  );
  const [selectedSlots, setSelectedSlots] = useState();
  const [isAutoSelected, setIsAutoSelected] = useState(true);
  const [selectedProId, setSelectedProId] = useState();
  const [available_time, setAvailable_time] = useState();
  const [available_timePro, setAvailable_timePro] = useState();
  const {state} = useLocation();
  const {
    loading,
    available_time: availabilityTime,
    status
  } = getCleanerTimeAvailabilitySlot;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/appointment/cleaner/time/availability";

  useEffect(() => {
    if (isAutoSelected === true) {
      setCheckoutData({
        ...checkoutData,
        ["is_auto_assign"]: true,
        ["cleaner_id"]: ""
      });
      if (availabilityTime?.length > 0) {
        setAvailable_time(availabilityTime);
        setCheckoutData({
          ...checkoutData,
          ["start_time_between"]: availabilityTime[0]?.time
        });
      } else {
        setAvailable_time(availabilityTime);
      }
    }
  }, [availabilityTime, isAutoSelected]);

  const handelSelectedDate = (item) => {
    setSelectedDate(item?.date);
    setAvailable_time(item?.availability);
    setCarouselKey((prevKey) => prevKey + 1);
    const currentDate = moment({
      year: item.currentYear,
      month: item.currentMonth - 1, // Moment months are 0-based
      date: item.date
    });
    const formattedDate = currentDate.format("YYYY-MM-DD");
    setCheckoutData({
      ...checkoutData,
      ["appointment_date"]: formattedDate,
      ["appointment_date_start"]:
        formattedDate + " " + item?.availability[0]?.time?.split("-")[0],
      ["start_time_between"]: item?.availability[0]?.time,
      ["holidayPrice"]: item?.holiday_all_price
    });
  };
  async function listHoliday() {
    let data = {country_id: localStorage.getItem("userCountryId")};
    await holidayList(data)
      .then((res) => {
        console.log("Holiday", res);
        setHolidayLists(res?.data?.holidayLists);
        setDisabledHoliday(res?.data?.holidayAllow);
        setHolidayStatus(res?.data?.status);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }
  useEffect(() => {
    listHoliday();
  }, []);
  const getSlotDataCurrentUser = () => {
    let values = {};
    if (currentUser) {
      let serviceId = localStorage.getItem("serviceId");
      // console.log("date ServiceId", serviceId)
      values = {
        cleaner_id: !checkoutData.is_auto_assign
          ? checkoutData?.cleaner_id
          : "",
        is_auto_assign: checkoutData.is_auto_assign,
        appointment_date: checkoutData.appointment_date,
        hours_count: checkoutData.hours_count,
        address_id: checkoutData.address_id ? checkoutData.address_id : "",
        customer_id: currentUser?.id ? currentUser?.id : " ",
        service_id:
          typeof checkoutData?.service_id === "string"
            ? checkoutData.service_id
            : checkoutData?.service_id?.length > 0
            ? checkoutData?.service_id[0]
            : "",
        frequency_id: checkoutData?.frequency_id
          ? checkoutData?.frequency_id
          : localStorage.getItem("FreqId"),
        is_home_cleaning: true,
        OnlyFav: false,
        professionalCount: checkoutData?.professionals_count,
        country_id: checkoutData?.country_id
          ? checkoutData.country_id
          : localStorage.getItem("country_id"),
        professional_id: checkoutData?.professional_id
        //  latitude:  !checkoutData.address_id ? checkoutData.latitude : "",
        //   longitude: !checkoutData.address_id ? checkoutData.longitude : ""
      };
    } else {
      let serviceId = localStorage.getItem("serviceId");
      // console.log("date ServiceId", serviceId)

      values = {
        cleaner_id: !checkoutData.is_auto_assign
          ? checkoutData?.cleaner_id
          : "",
        is_auto_assign: checkoutData.is_auto_assign,
        appointment_date: checkoutData.appointment_date,
        hours_count: checkoutData.hours_count,
        address_id: checkoutData.address_id ? checkoutData.address_id : "",
        customer_id: currentUser?.id ? currentUser?.id : " ",
        service_id:
          typeof checkoutData?.service_id === "string"
            ? checkoutData.service_id
            : checkoutData?.service_id?.length > 0
            ? checkoutData?.service_id[0]
            : "",
        frequency_id: checkoutData?.frequency_id
          ? checkoutData?.frequency_id
          : localStorage.getItem("FreqId"),
        is_home_cleaning: true,
        OnlyFav: false,
        professionalCount: checkoutData?.professionals_count,
        country_id: checkoutData?.country_id
          ? checkoutData.country_id
          : localStorage.getItem("country_id"),
        professional_id: checkoutData?.professional_id,
        latitude: checkoutData.latitude ? checkoutData.latitude : "",
        longitude: checkoutData.latitude ? checkoutData.longitude : ""
      };
    }

    dispatch(
      getCleanerTimeAvailability(
        listUrl,
        values,
        updateCheckoutData,
        t,
        setCheckoutData,
        checkoutData
      )
    );
  };

  const getSlotData = (date) => {
    let values = {
      cleaner_id: !checkoutData.is_auto_assign ? checkoutData?.cleaner_id : "",
      is_auto_assign: checkoutData.is_auto_assign,
      appointment_date: date ? date : checkoutData.appointment_date,
      hours_count: checkoutData.hours_count,
      service_id:
        typeof checkoutData?.service_id === "string"
          ? checkoutData.service_id
          : checkoutData?.service_id?.length > 0
          ? checkoutData?.service_id[0]
          : "",
      frequency_id: checkoutData?.frequency_id
        ? checkoutData?.frequency_id
        : localStorage.getItem("FreqId"),
      is_home_cleaning: true,
      professionalCount: checkoutData.professionals_count,
      country_id: checkoutData?.country_id
        ? checkoutData.country_id
        : localStorage.getItem("userCountryId"),
      latitude: checkoutData?.latitude ? checkoutData?.latitude : "",
      longitude: checkoutData?.latitude ? checkoutData?.longitude : ""
    };
    dispatch(
      getCleanerTimeAvailability(
        listUrl,
        values,
        updateCheckoutData,
        t,
        setCheckoutData,
        checkoutData
      )
    );
  };

  useEffect(() => {
    if (
      currentUser &&
      checkoutData?.is_auto_assign &&
      (checkoutData.cleaner_id ||
        (Array.isArray(checkoutData.cleaner_id) &&
          checkoutData.cleaner_id.length > 1))
    ) {
      getSlotDataCurrentUser();
    } else if (
      (checkoutData.cleaner_id ||
        (Array.isArray(checkoutData.cleaner_id) &&
          checkoutData.cleaner_id.length > 1)) &&
      checkoutData?.is_auto_assign
    ) {
      getSlotData();
    } else if (!isAutoSelected) {
      getMonthSlots(checkoutData?.cleaner_id);
    }
  }, []);

  const [favouriteProInfoShow, setFavouriteProInfoShow] = useState(false);
  const handleFavouriteProInfoShow = () => setFavouriteProInfoShow(true);
  const handleFavouriteProInfoHide = () => setFavouriteProInfoShow(false);

  const [visitTimeInfoShow, setVisitTimeInfoShow] = useState(false);
  const handleVisitTimeInfoShow = () => setVisitTimeInfoShow(true);
  const handleVisitTimeInfoHide = () => setVisitTimeInfoShow(false);

  const getfavProInfoState = useSelector((state) => state.getTCReducer);
  const {fav_pro_info} = getfavProInfoState;
  let favProUrl = baseUrl + "/staticPage/info/FavProfInfo";
  const favProInformation = () => {
    dispatch(getFavProInfoAction(favProUrl, {}));
    handleFavouriteProInfoShow();
  };

  const getStartTimeInfoState = useSelector((state) => state.getTCReducer);
  const {start_time_info} = getStartTimeInfoState;
  let startTimeUrl = baseUrl + "/staticPage/info/StartTimeInfo";
  const visitTimeInformation = () => {
    dispatch(getVisitTimeInfoAction(startTimeUrl, {}));
    handleVisitTimeInfoShow();
  };

  const availableDatesInput = [];
  // useEffect(() => {

  //     });
  //   }
  //   console.log("holidayDisabled", holidayDisabled, countryList);
  // }, [availableDatesInput]);
  function availableDates() {
    let startDate = moment();
    let endDate = moment().add(60, "days");
    while (endDate.isSameOrAfter(startDate)) {
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
      ];
      var week_day_arabic = days[startDate.day()];
      availableDatesInput.push({
        week_day: startDate.format("dd"),
        week_day_arabic: week_day_arabic,
        month_day: startDate.format("D"),
        month: startDate.format("MMM"),
        date: startDate.format("YYYY-MM-DD")
      });
      startDate = startDate.add(1, "day");
    }
  }
  availableDates();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getDayFromDate = (item) => {
    const daysEnglish = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const daysArabic = [
      "اﻷحد",
      "اﻷثنين",
      "الثلاثاء",
      "اﻷربعاء",
      "الخميس",
      "الجمعة",
      "السبت"
    ];

    const days = currentLanguage === "en" ? daysEnglish : daysArabic;
    const date = moment().set({
      year: item?.currentYear,
      month: item?.currentMonth - 1,
      date: item?.date
    });
    return days[date.day()];
  };

  const options = {
    margin: 8.1,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,

    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
        margin: 7
      },
      375: {
        items: 3,
        margin: 10
      },
      380: {
        items: 3,
        margin: 11
      },
      480: {
        items: 3,
        margin: 10
      },
      550: {
        items: 4,
        margin: 12
      },
      600: {
        items: 4,
        margin: 13
      },
      768: {
        items: 4,
        margin: 12
      },
      992: {
        items: 7,
        margin: 12
      },
      1200: {
        margin: 8.1
      },
      1400: {
        margin: 11
      }
    }
  };
  function setBookingDateTime(time, dateTime, price, type) {
    if (type === "date") {
      // updateCheckoutData("appointment_date", dateTime);
      setCheckoutData({
        ...checkoutData,
        ["appointment_date"]: dateTime
      });
      // updateCheckoutData("start_time_between", "");

      // jQuery(".start_time_between_checkbox").prop("checked", false);
      if (currentUser) {
        dispatch(
          getCleanerTimeAvailability(
            listUrl,
            {
              cleaner_id: !checkoutData.is_auto_assign
                ? checkoutData?.cleaner_id
                : "",
              is_auto_assign: checkoutData.is_auto_assign,
              appointment_date: dateTime
                ? dateTime
                : checkoutData.appointment_date,
              hours_count: checkoutData.hours_count,
              address_id: checkoutData.address_id
                ? checkoutData.address_id
                : "",
              customer_id: currentUser?.id ? currentUser?.id : " ",
              service_id:
                typeof checkoutData?.service_id === "string"
                  ? checkoutData.service_id
                  : checkoutData?.service_id?.length > 0
                  ? checkoutData?.service_id[0]
                  : "",
              frequency_id: checkoutData?.frequency_id
                ? checkoutData?.frequency_id
                : localStorage.getItem("FreqId"),
              is_home_cleaning: true,
              professionalCount: checkoutData?.professionals_count,
              country_id: localStorage.getItem("userCountryId"),
              professional_id: checkoutData.professional_id
              //   latitude:  !checkoutData.address_id ? checkoutData.latitude : "",
              //  longitude: !checkoutData.address_id ? checkoutData.longitude : ""
            },
            updateCheckoutData,
            t,
            setCheckoutData,
            checkoutData
          )
        );
      } else {
        getSlotData(dateTime);
      }
    } else {
      updateCheckoutData("rushSlot", price);
      updateCheckoutData(
        "appointment_date_start",
        checkoutData.appointment_date + " " + time
      );
      updateCheckoutData("start_time_between", dateTime);
    }
  }
  const getMonthSlots = async (id, professionals) => {
    setIsProLoad(true);
    if (id !== "") {
      const listUrl = `${baseUrl}/appointment/cleaner/month/slots`;
      const data = {
        cleaner_id: id,
        is_auto_assign: checkoutData.is_auto_assign,
        appointment_date: checkoutData.appointment_date,
        hours_count: checkoutData.hours_count,
        address_id: checkoutData.address_id ? checkoutData.address_id : "",
        customer_id: currentUser?.id ? currentUser?.id : " ",
        service_id:
          typeof checkoutData?.service_id === "string"
            ? checkoutData?.service_id
            : checkoutData?.service_id?.length > 0
            ? checkoutData?.service_id[0]
            : "",
        frequency_id: checkoutData?.frequency_id,
        is_home_cleaning: true,
        country_id: localStorage.getItem("userCountryId")
        //   latitude:  !checkoutData.address_id ? checkoutData.latitude : "",
        //  longitude: !checkoutData.address_id ? checkoutData.longitude : ""
      };
      const allMonthsData = await getMonthSlotsAPI(listUrl, data);

      if (allMonthsData?.monthSlots) {
        setSelectedSlots(allMonthsData?.monthSlots);
        const firstAvailableDate = allMonthsData?.monthSlots.find(
          (slot) => slot.isAvailable
        );

        const firstTime = firstAvailableDate?.availability[0]?.time;
        const currentDate = moment({
          year: firstAvailableDate?.currentYear,
          month: firstAvailableDate?.currentMonth - 1, // Moment months are 0-based
          date: firstAvailableDate?.date
        });
        // console.log("firstAvailableDate?.availability[0]?.totalSlotPrice", firstAvailableDate?.availability[0]?.totalSlotPrice)
        const formattedDate = currentDate.format("YYYY-MM-DD");
        setCheckoutData({
          ...checkoutData,
          ["is_auto_assign"]: false,
          ["cleaner_id"]:
            professionals !== undefined
              ? professionals?._id
              : checkoutData?.cleaner_id,
          ["professional_full_name"]:
            professionals !== undefined
              ? professionals?.first_name + " " + professionals?.last_name
              : checkoutData?.professional_full_name,
          ["professional_profile_image"]:
            professionals !== undefined
              ? professionals?.profile_pic
                ? professionals?.profile_pic
                : ""
              : checkoutData?.professional_profile_image,
          ["professional_id"]:
            professionals !== undefined
              ? professionals?._id
                ? professionals?._id
                : ""
              : checkoutData?.cleaner_id,
          ["appointment_date"]: formattedDate,
          ["start_time_between"]: firstTime,
          ["appointment_date_start"]:
            formattedDate + " " + firstTime?.split("-")[0],
          ["ActualCodCharges"]: allMonthsData?.codCharges,
          ["actionStatus"]: allMonthsData?.status,
          ["holidayPrice"]: allMonthsData.monthSlots[0]?.holiday_all_price,
          ["rushSlot"]: firstAvailableDate?.availability[0]?.totalSlotPrice
        });
        getDayFromDate(firstAvailableDate);
        setSelectedDate(firstAvailableDate?.date);
        setAvailable_time(firstAvailableDate?.availability);
        setIsProLoad(false);
        setIsAutoSelected(false);
      } else if (allMonthsData?.status === 400) {
        toast.error(allMonthsData?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setIsProLoad(false);
      }
    }
  };

  function getTimeSlots(id) {
    if (isAutoSelected) {
      setCheckoutData({
        ...checkoutData,
        ["is_auto_assign"]: isAutoSelected,
        ["cleaner_id"]: "",
        ["professional_full_name"]: "Auto assign",
        ["professional_profile_image"]: ""
      });
    }

    if (currentUser) {
      dispatch(
        getCleanerTimeAvailability(
          listUrl,
          {
            cleaner_id: isAutoSelected
              ? ""
              : !checkoutData.is_auto_assign
              ? checkoutData?.cleaner_id
              : "",
            is_auto_assign: isAutoSelected
              ? isAutoSelected
              : checkoutData.is_auto_assign,
            appointment_date: checkoutData.appointment_date,
            hours_count: checkoutData.hours_count,
            address_id: checkoutData.address_id ? checkoutData.address_id : "",
            customer_id: currentUser?.id ? currentUser?.id : " ",
            service_id:
              typeof checkoutData?.service_id === "string"
                ? checkoutData.service_id
                : checkoutData?.service_id?.length > 0
                ? checkoutData?.service_id[0]
                : "",
            frequency_id: checkoutData?.frequency_id
              ? checkoutData?.frequency_id
              : localStorage.getItem("FreqId"),
            is_home_cleaning: true,
            country_id: localStorage.getItem("userCountryId"),
            professionalCount: checkoutData?.professionals_count,
            country_id: localStorage.getItem("userCountryId"),
            professional_id: checkoutData.professional_id
            //   latitude:  !checkoutData.address_id ? checkoutData.latitude : "",
            //  longitude: !checkoutData.address_id ? checkoutData.longitude : ""
          },
          updateCheckoutData,
          t,
          setCheckoutData,
          checkoutData
        )
      );
    } else {
      // getSlotData();
    }
  }

  const options2 = {
    margin: 25,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,

    navText: [
      "<i class='fa fa-angle-left' aria-hidden='true'></i>",
      "<i class='fa fa-angle-right' aria-hidden='true'></i>"
    ],

    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      700: {
        items: 4
      },
      1000: {
        items: 7
      }
    }
  };

  const styles = {
    color: "#fff",
    background: "#432375",
    border: "2px solid #432375"
  };
  const styles2 = {
    margin: "0 auto"
  };
  const stylesDate = {
    color: "#999",
    background: "#fff",
    border: "2px solid #999999"
  };

  const disableStyles = {
    color: "#fff",
    background: "#999999",
    border: "2px solid #999999"
  };

  const stylesTime = {
    background: "#432375",
    border: "2px solid #432375",
    color: "#fff"
  };

  const settings = {
    ...options, // Spread additional options if passed
    infinite: false,   // Equivalent to loop={false} in OwlCarousel
    touchMove: false,  // Equivalent to touchDrag={false}
    draggable: false,  // Equivalent to mouseDrag={false}
    arrows: false,     // Optional: You can enable this to show arrows
    dots: true,        // Optional: Enable dots for pagination
  };

  return (
    <>
      <div className="checkboxSec">
        {checkoutData?.professionals_count === 1 && (
          <h3>
            {t("pro_you_prefer")}{" "}
            <img
              src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
              className="static_info"
              alt=""
              onClick={() => favProInformation()}
            />
            <p>{t("top_rated_professionals")}</p>
          </h3>
        )}
        {loading ? <Loader /> : ""}
        <ProfessionalSlider
          checkoutData={checkoutData}
          updateCheckoutData={updateCheckoutData}
          getTimeSlots={getTimeSlots}
          isAutoSelected={isAutoSelected}
          setIsAutoSelected={setIsAutoSelected}
          setSelectedProId={setSelectedProId}
          selectedProId={selectedProId}
          setCheckoutData={setCheckoutData}
          getMonthSlots={getMonthSlots}
        />
        {isAutoSelected ? (
          <>
            <div className="datesec">
              <h3>{t("like_your_service")}</h3>
              <Slider {...settings} key={carouselKey} className="owl-theme mt-4">
                {availableDatesInput?.length > 0
                  ? availableDatesInput?.map((item, index) => {
                      {
                        console.log("item", item);
                      }

                      return (
                        <div className="item" key={index}>
                          {(
                            <>
                              <span className="dia_semana">
                                <label>
                                  <input
                                    type="radio"
                                    name="booking_date"
                                    defaultValue={item?.date}
                                    onClick={() =>
                                      setBookingDateTime(
                                        "",
                                        item?.date,
                                        0,
                                        "date"
                                      )
                                    }
                                    defaultChecked={
                                      item.date ===
                                      checkoutData?.appointment_date
                                    }
                                  />
                                  <span>
                                    {currentLanguage === "en"
                                      ? item?.week_day
                                      : item?.week_day_arabic}
                                  </span>
                                  <span className="dia">{item?.month_day}</span>
                                </label>
                              </span>
                              <span className="mes">{item?.month}</span>
                            </>
                          )}
                        </div>
                      );
                    })
                  : null}
              </Slider>
            </div>
          </>
        ) : (
          <ProDates
            selectedSlots={selectedSlots}
            options={options}
            carouselKey={carouselKey}
            handelSelectedDate={handelSelectedDate}
            selectedDate={selectedDate}
            currentLanguage={currentLanguage}
            getDayFromDate={getDayFromDate}
            isProLoad={isProLoad}
          />
        )}
        {isProLoad ? (
          <Loader />
        ) : (
          <ShowTime
            available_time={available_time}
            setBookingDateTime={setBookingDateTime}
            visitTimeInformation={visitTimeInformation}
            options2={options2}
            checkoutData={checkoutData}
            available_timePro={available_timePro}
            isAutoSelected={isAutoSelected}
            carouselKey={carouselKey}
          />
        )}
        {/* {available_time?.length > 0 ? (
          <div className="timeButtons">
            <h3>
              {t("what_time_to_start")}{" "}
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
                className="static_info"
                alt=""
                onClick={() => visitTimeInformation()}
              />
            </h3>
            <div className="buttonArea">
              <div className="w-full pt-2 ">
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  loop={false}
                  margin={25}
                  {...options2}
                >
                  {available_time?.length > 0
                    ? available_time?.map((time, index) => {
                        let selectTime = false;
                        if (checkoutData?.start_time_between === time?.time) {
                          selectTime = true;
                        }

                        if (
                          index === 0 &&
                          checkoutData.start_time_between === ""
                        ) {
                          setBookingDateTime(
                            time.start_time,
                            time.time,
                            "time"
                          );
                          selectTime = true;
                        }
                        return (
                          <div className="item" key={index}>
                            <div
                              className="buttonItem"
                              onClick={() =>
                                setBookingDateTime(
                                  time.start_time,
                                  time.time,
                                  time?.totalSlotPrice,
                                  "time"
                                )
                              }
                            >
                              <label>
                                <input
                                  type="radio"
                                  className="start_time_between_checkbox"
                                  name="start_time"
                                  defaultChecked={selectTime}
                                />
                                <span>{time?.time}</span>
                              </label>
                              {time?.totalSlotPrice > 0 ? (
                                <p className="helper-text-item has-margin-top has-text-alternate-dark">
                                  QAR {time?.totalSlotPrice} EXTRA
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </OwlCarousel>
              </div>
            </div>
          </div>
        ) : (
          <center className="no_slot_available">
            {t("no_slot_available")}
          </center>
        )} */}
        <p>
          {checkoutData.start_time_between &&
          checkoutData.start_time_between !== ""
            ? t("professional_arrive_time") +
              " " +
              checkoutData.start_time_between +
              " " +
              t("at_doorstep")
            : ""}
        </p>
      </div>

      <Modal
        show={favouriteProInfoShow}
        onHide={handleFavouriteProInfoHide}
        id="cleaningMaterial"
        className="cleaningMaterial"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {fav_pro_info ? (
            <div
              className="accordion-body"
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguage === "en"
                    ? fav_pro_info?.content
                    : fav_pro_info?.content_arabic
              }}
            ></div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={visitTimeInfoShow}
        onHide={handleVisitTimeInfoHide}
        id="cleaningMaterial"
        className="cleaningMaterial"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {start_time_info ? (
            <div
              className="accordion-body"
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguage === "en"
                    ? start_time_info?.content
                    : start_time_info?.content_arabic
              }}
            ></div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectDateTime;
