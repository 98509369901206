import React, {useState, forwardRef, useImperativeHandle, useRef} from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {ModifiedToastContainer} from "../ModifiedToastContainer";
import SetupAddressModal from "./SetupAccountModals/SetupAddressModal";
import SetupProfile from "./SetupAccountModals/SetupProfile";
import AppointmentCartModal from "./SetupAccountModals/AppointmentCartModal";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {
  loginUserAction,
  loginUserResendOTPAction,
  loginEmailUserAction
} from "../../actions/userActions";
import {commonLoginSuccess} from "../../helpers/helpers";
import Loader from "../Loader";
import AddressSetupComponent from "./SetupAccountModals/AddressSetupComponent";

let displayContent = {
  displayNone: {
    display: "none"
  },
  displayBlock: {
    display: "block"
  }
};

const LoginWithMobileModal = forwardRef((props, ref) => {
  const getUserLoginState = useSelector((state) => state.userLoginReducer);
  const { implementedFrom, handelMobileLogin } = props;
  const setAddressRef = useRef();
  const setProfileRef = useRef();
  const appointmentCartRef = useRef();
  const {loading} = getUserLoginState;
  const [showSetupAddressModal, setShowSetupAddressModal] = useState(false);
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const [showInfoModal, setShowInfoModal] = useState(false);
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showLoginWithMobileModal, setShowLoginWithMobileModal] =
    useState(false);
  const handleShowLoginWithMobileShow = () => setShowLoginWithMobileModal(true);
  const handleshowLoginWithMobileClose = () =>
    setShowLoginWithMobileModal(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const handleOtpModalShow = () => setShowOtpModal(true);
  const handleOtpModalClose = () => {
    setShowOtpModal(false);
  };
  let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/login";

  var timeOut;
  function startOTPTimeOut() {
    if (timeOut) {
      stopOTPTimeOut();
      clearInterval(timeOut);
    }
    var time1 = 59;
    var time2 = 0;
    jQuery(".resentOtpTime").removeClass("timeNotVisible");
    // jQuery("#otp").val(otp);
    timeOut = setInterval(function () {
      time2 = time1--;
      jQuery(".startTimer").html("00:" + (time2 < 10 ? "0" + time2 : time2));
      if (time2 === 0) {
        clearInterval(timeOut);
        resendEvent();
      }
    }, 1000);
  }

  function stopOTPTimeOut() {
    clearInterval(timeOut);
  }

  const resendOtpSend = (e) => {
    jQuery(".resend-otp").css("display", "none");
    jQuery(".resendOtpError").css("display", "none");
    jQuery(".resendOtpError").html("");
    jQuery(".otpSent").css("display", "block");
    jQuery(".otpSent").html(t("otp_sent_on_your_mobile"));
    jQuery(".resentOtpTime").removeClass("timeNotVisible");
    let frm = document.getElementById("loginForm");
    dispatch(
      loginUserResendOTPAction(
        apiUrl,
        {
          mobile: currentUser.mobile,
          country_code: currentUser.country_code
        },
        loginSuccess
      )
    );
  };

  const resendEvent = (e) => {
    jQuery(".resendOtpError").css("display", "block");
    jQuery(".resend-otp").css("display", "block");
    jQuery(".otpSent").css("display", "none");
    jQuery(".resentOtpTime").addClass("timeNotVisible");
  };

  async function loginSuccess(otp) {
    setTimeout(() => {
      const currentUser =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? true
          : false;
      const newCurrentUser =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? JSON.parse(localStorage.getItem("currentUser"))
          : false;
      if (currentUser) {
        handleshowLoginWithMobileClose();
        handleOtpModalShow();
        jQuery("#otp").val(otp);
        jQuery(".otpContinueBtn").removeClass("disabled");
        jQuery(".otpContinueBtn").attr("disabled", false);
        jQuery(".phone-number").html(
          newCurrentUser.country_code + newCurrentUser.mobile
        );
        jQuery(".resend-otp").css("display", "none");
        jQuery(".resendOtpError").css("display", "none");
        jQuery(".resendOtpError").html("");
        jQuery(".resentOtpTime").addClass(" timeNotVisible");
        startOTPTimeOut();
      }
    }, 700);
  }

  const handleloginWithMobileSubmit = (e) => {
    e.preventDefault();
    let mobile = jQuery("#mobile").val();
    let country_code = jQuery("#country_code").val();
    let frm = document.getElementById("loginForm");
    var mobileNumberRange = 10;
    if (country_code === "+974") {
      mobileNumberRange = 8;
    }
    if (mobile && mobile.length === mobileNumberRange) {
      jQuery(".createOtpSubmit").removeClass("disabled");
      jQuery(".createOtpSubmit").removeAttr("disabled");
      dispatch(loginUserAction(apiUrl, frm, loginSuccess));
    } else {
      jQuery(".createOtpSubmit").addClass("disabled");
      jQuery(".createOtpSubmit").attr("disabled", "true");
    }
  };

  const enterMobile = (e) => {
    let mobile = jQuery("#mobile").val();
    let country_code = jQuery("#country_code").val();
    let frm = document.getElementById("loginForm");
    var mobileNumberRange = 10;
    if (country_code === "+974") {
      mobileNumberRange = 8;
    }
    if (mobile && mobile.length === mobileNumberRange) {
      jQuery(".createOtpSubmit").removeClass("disabled");
      jQuery(".createOtpSubmit").removeAttr("disabled");
      dispatch(loginUserAction(apiUrl, frm, loginSuccess));
    } else {
      jQuery(".createOtpSubmit").addClass("disabled");
      jQuery(".createOtpSubmit").attr("disabled", "true");
    }
  };

  async function onOTPVerificationSuccess(token, cart = null, msg) {
    if(implementedFrom === "bookingNotLogin"){
      const currentUserDetail =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : "";
          handleOtpModalClose();
          handelMobileLogin(token, currentUserDetail)
    }else{
      commonLoginSuccess(
        token,
        cart,
        msg,
        handleOtpModalClose,
        setAddressRef,
        setProfileRef,
        appointmentCartRef,
        setShowSetupAddressModal
      );
    }
  }

  const handleFormOtpSubmit = (e) => {
    e.preventDefault();
    let otp = e.target.elements.otp.value;
    if (otp && otp.length === 4) {
      let values = {
        otp: otp,
        fcm_token: "website",
        device_type: "website",
        mobile: currentUser.mobile,
        country_code: currentUser.country_code
      };
      let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
      dispatch(
        loginEmailUserAction(
          apiOtpUrl,
          values,
          onOTPVerificationSuccess,
          setShowInfoModal,
          implementedFrom
        )
      );
    } else {
      let message = !otp ? t("enter_otp") : t("invalid_otp");
      jQuery(".resendOtpError").html(message);
    }
  };

  const typeOtp = (e) => {
    let otp = jQuery("#otp").val();
    if (otp.length === 4) {
      jQuery(".otpContinueBtn").removeClass("disabled");
      jQuery(".spinner-border").css("display", "block");
    } else {
      jQuery(".otpContinueBtn").addClass("disabled");
      jQuery(".spinner-border").css("display", "none");
    }

    if (otp && otp.length === 4) {
      jQuery(".resendOtpError").css("display", "none");
      let values = {
        mobile: currentUser.mobile,
        country_code: currentUser.country_code,
        device_type: "website",
        fcm_token: "website",
        otp: otp
      };
      let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
      dispatch(
        loginEmailUserAction(
          apiOtpUrl,
          values,
          onOTPVerificationSuccess,
          setShowInfoModal,
          implementedFrom
        )
      );
    } else {
      if (otp.length >= 4 || otp.length === 4) {
        jQuery(".resendOtpError").css("display", "block");
        jQuery(".resendOtpError").html(t("invalid_otp"));
      } else {
        jQuery(".resendOtpError").css("display", "none");
        jQuery(".resendOtpError").html("");
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleLoginWithMobileModalShow() {
      handleShowLoginWithMobileShow(true);
    }
  }));

  return (
    <>
      {/* Login Modal */}
      {loading ? <Loader /> : ""}
      <ModifiedToastContainer />
      <Modal
        show={showLoginWithMobileModal}
        onHide={handleshowLoginWithMobileClose}
        id="loginModal"
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="loginHead ">
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center mb-3"> {t("welcome")}</h2>
          <p className="text-center">{t("login_detail")}</p>
          <form
            onSubmit={handleloginWithMobileSubmit}
            id="loginForm"
            className="mt-4 pt-2"
          >
            <div className="">
              <label className="mb-0">{t("your_mobile_number")}</label>
              <div className="row loginMobileInput">
                <div className="inputgroup phonecodeselect col-lg-5">
                  <ReactPhoneInput
                    inputProps={{
                      className: "login_country_code form-control",
                      name: "country_code",
                      id: "country_code",
                      autoFocus: true,
                      placeholder: t("enter_mobile_number"),
                      readOnly: "readonly"
                    }}
                    country={userCountryCode.toLocaleLowerCase()}
                    localization={
                      currentLanguageCode === "en" ? currentLanguageCode : ar
                    }
                    countryCodeEditable={false}
                    placeholder=""
                    disableCountryCode={false}
                    searchClass="search-class"
                    searchStyle={{
                      margin: "0",
                      width: "97%",
                      height: "30px"
                    }}
                    enableSearch
                    disableSearchIcon
                  />
                  <div className="help-block text-danger"></div>
                </div>

                <div className="inputgroup phonecodeselect col-lg-7">
                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    className="form-control login_mobile"
                    onChange={enterMobile}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={t("enter_mobile_number")}
                  />
                  <div className="help-block text-danger"></div>
                </div>
              </div>

              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full disabled createOtpSubmit"
                  disabled="disabled"
                >
                  {t("get_otp")}
                </button>
                <div
                  className="spinner-border text-dark mobileSubmitLoader"
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/*  OTP Modal */}
      <Modal
        show={showOtpModal}
        onHide={handleOtpModalClose}
        id="otpVerify"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormOtpSubmit}>
            <div className="">
              <h2 className="mb-3">{t("otp_verification")}</h2>
              <label>{t("otp_detail")}</label>
              <p className="phone-number mb-4 phoneNumberDir"></p>
              <input
                type="text"
                id="otp"
                className="form-control"
                placeholder=""
                maxLength="4"
                onKeyUp={typeOtp}
              />

              <p className="mb-0 py-3 resentOtpTime ">
                {t("resend_code_in")}:<p className="startTimer">00:59</p>
              </p>
              <p
                className="text-danger resendOtpError"
                style={displayContent.displayNone}
              ></p>

              <p
                className="otpSent text-success"
                style={displayContent.displayNone}
              ></p>

              <div
                className="resend-otp"
                onClick={resendOtpSend}
                style={displayContent.displayNone}
              >
                <p>Did not receive an OTP?</p>
                <p className="btn btn-light rounded-pill">
                  {" "}
                  {t("resend_code_btn")}
                </p>
              </div>

              <div className="loader">
                <button
                  type="submit"
                  className="btn btn-primary w-full disabled otpContinueBtn"
                  disabled=""
                >
                  {t("continue_btn")}
                </button>
                <div
                  className="spinner-border text-dark "
                  role="status"
                  style={displayContent.displayNone}
                >
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <AddressSetupComponent
        ref={setAddressRef}
        showSetupAddressModal={showSetupAddressModal}
        setShowSetupAddressModal={setShowSetupAddressModal}
      />
      {/* <SetupAddressModal showSetupAddressModal={showSetupAddressModal} setShowSetupAddressModal={setShowSetupAddressModal} ref={setAddressRef}/> */}
      <SetupProfile ref={setProfileRef} />
      <AppointmentCartModal ref={appointmentCartRef} />
    </>
  );
});

export default LoginWithMobileModal;
