import "./App.css";
import "./Customer.css";
import Home from "./pages/home/Home";
import jQuery from "jquery";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Settings from "./pages/my_account/Settings";
import ProSettings from "./pages/my_account/ProSettings";
import Help from "./pages/my_account/Help";
import HelpSubQuestions from "./pages/my_account/HelpSubQuestions";
import SeeAllHistory from "./pages/my_account/SeeAllHistory";
import HelpDetail from "./pages/my_account/HelpDetail";
import CreateSupportTicket from "./pages/my_account/CreateSupportTicket";
import EditPersonalDetails from "./pages/my_account/EditPersonalDetails";
import Address from "./pages/my_account/Address/Address";
import CreditCard from "./pages/my_account/CreditCard";
import AddCreditCard from "./pages/my_account/AddCreditCard";
import EditCreditCard from "./pages/my_account/EditCreditCard";
import Appointments from "./pages/my_account/Appointments";
import AppointmentDetails from "./pages/my_account/AppointmentDetails";
import RescheduleAppointment from "./pages/my_account/RescheduleAppointment";
import CancelMyAppointment from "./pages/my_account/CancelMyAppointment";
import CancelAppointment from "./pages/my_account/CancelAppointment";
import BookingCancel from "./pages/my_account/BookingCancel";
import WriteReview from "./pages/my_account/WriteReview";
import Review from "./pages/my_account/Review";
import RighthandCredit from "./pages/my_account/RighthandCredit";
import CreditPackages from "./pages/my_account/CreditPackages";
import CreditPackagesPayment from "./pages/my_account/CreditPackagesPayment";
import FreeCredit from "./pages/my_account/FreeCredit";
import CreditDetails from "./pages/my_account/CreditDetails";
import ServiceCheckout from "./pages/appointment_booking/service/ServiceCheckout";
import ProfessionalProfile from "./pages/appointment_booking/service/components/ProfessionalProfile";
import FrequencyCheckout from "./pages/appointment_booking/frequency/FrequencyCheckout";
import FAQ from "./components/FAQ";
import TermsConditions from "./components/TermsConditions";
import RHClubPolicy from "./components/RHClubPolicy";
import RHClubPolicyDetails from "./components/RHClubPolicyDetails";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ServiceDetails from "./components/ServiceDetails";
import Cancellation from "./components/Cancellation";
import ReschedulePolicy from "./components/ReschedulePolicy";
import BlogDetail from "./components/BlogDetail";
import BlogsList from "./components/BlogsList";
import RescheduleAppointmentPolicy from "./pages/my_account/RescheduleAppointmentPolicy";
import Covid from "./components/Covid";
import Success from "./components/Success";
import EULA from "./components/EULA";
import Disclaimer from "./components/Disclaimer";
import CookiesPolicy from "./components/CookiesPolicy";
import Deeplink from "./components/Deeplink";
import CommonCheckout from "./pages/my_account/CommonCheckout";
import About from "./pages/About";
import RighthandGuarntee from "./pages/RighthandGuarntee";
import Membership from "./pages/Membership";
import BuyMemberShip from "./pages/my_account/BuyMemberShip";
import Login from "./pages/customer/Login";
import AccountDetails from "./pages/my_account/AccountDetails";
import ProfileUpdate from "./pages/my_account/ProfileUpdate";
import ChangePassword from "./pages/my_account/ChangePassword";
import ResetPassword from "./pages/my_account/ResetPassword";
import MyfavPros from "./pages/my_account/MyfavPros";
import ForgotPassword from "./pages/customer/ForgotPassword";
import ClaimAccount from "./pages/customer/ClaimAccount";
import SignwithMobile from "./pages/customer/SignwithMobile";
import BlankLoading from "./components/BlankLoading";
import CardUpdating from "./pages/appointment_booking/frequency/components/BlankLoading";
import { Otp } from "react-otp-timer";
import Disinfection from "./pages/appointment_booking/service/servicestep/ServicePage";
import Popups from "./components/Popups";
import TabsServicePage from "./pages/customer/services/TabsServicePage";
import ServiceByCategorie from "./pages/customer/services/ServiceByCategorie";
import FreeServices from "./pages/customer/free-service/FreeServices";
import HowItworks from "./pages/customer/free-service/HowItworks";
import TrackInvites from "./pages/customer/free-service/TrackInvites";
import Announcements from "./pages/customer/announcements";
import RighthandsClub from "./pages/customer/righthandsClub";
import ExtendAppointments from "./pages/my_account/ExtendAppointments";
import ExtendedMembership from "./pages/my_account/ExtendedPay";
import AppointmentTrack from "./pages/appointment_booking/AppointmentTrack";
// import LocationSearch from "./components/LocationSearch";
import PaymentHistory from "./pages/my_account/PayementHistory";
import AllSwiperSlider from "./components/AllSwiperSlider";
import BirthDayAutoFill from "./components/BirthDayAutoFill";
import RighthandWallet from "./pages/my_account/RighthandWallet";
import PageNotFound from "./components/404";
import Zendesk, { ZendeskAPI } from "./ZendexConfig";
import HomeNew from "./components/HomeNew";
import NewServices from "./components/NewServices";
// import SuccessFullPayment from "./pages/appointment_booking/frequency/components/SuccessFullPayment";
import Blog from "./components/Blog";
import AllServices from "./pages/all_services/AllServices";
import AppointmentSummary from "./components/AppointmentSummary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import EmbeddedPayment from "./components/EmbeddedPayment";
import BecomePro from "./components/BecomePro";
import ApptSummaryRedirect from "./components/ApptSummaryRedirect";


function App() {
  if (!localStorage.getItem("currentLanguage")) {
    localStorage.setItem("currentLanguage", "en");
  }

  const ZENDESK_KEY = "16eb9093-5e86-4a77-8846-17ffcaa93e3c";
  const handleLoaded = () => {
    ZendeskAPI("messenger", "close");
  };

  const currentLanguageCode = localStorage.getItem("currentLanguage");
  const { t } = useTranslation();
  useEffect(() => {
    jQuery("body").attr("dir", currentLanguageCode === "ar" ? "rtl" : "ltr");
    jQuery("title").html(t("app_title"));
    var jqueryHtml = jQuery("html");
    if (currentLanguageCode === "ar") {
      jqueryHtml.addClass("translated-rtl");
    } else {
      jqueryHtml.removeClass("translated-rtl");
    }
    jqueryHtml.attr("lang", currentLanguageCode);

    localStorage.setItem("currentCountryCode", "AE");
  }, [currentLanguageCode, t]);

  jQuery(document).on("click", ".flag-dropdown", function () {
    jQuery(".search-class-box").attr("placeholder", t("search"));
  });

  return (
    <>
      <HelmetProvider>
        {/* Global SEO Tags */}
        <Helmet>
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<HomeNew />} />
          <Route exact path="/fatoorah-payment" element={<EmbeddedPayment />} />
          <Route exact path="/become-pro" element={<BecomePro />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/services" element={<AllServices />} />
          <Route exact path="/service/:serviceSlug" element={<NewServices />} />
          <Route exact path="/popups" element={<Popups />} />
          <Route exact path="/my-account/Settings" element={<Settings />} />
          <Route
            exact
            path="/my-account/prosettings"
            element={<ProSettings />}
          />
          <Route
            exact
            path="/my-account/account-details"
            element={<AccountDetails />}
          />
          <Route
            exact
            path="/my-account/profile-update"
            element={<ProfileUpdate />}
          />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route
            exact
            path="/reset/password/:token/:emailToken"
            element={<ResetPassword />}
          />
          <Route exact path="/eula" element={<EULA />} />
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route exact path="/cookies/policy" element={<CookiesPolicy />} />
          <Route exact path="/my-account/help" element={<Help />} />
          <Route
            exact
            path="/my-account/sub/questions/"
            element={<HelpSubQuestions />}
          />
          <Route
            exact
            path="/my-account/help/detail/"
            element={<HelpDetail />}
          />
          <Route
            exact
            path="/my-account/help/create/ticket"
            element={<CreateSupportTicket />}
          />
          <Route
            exact
            path="/my-account/see/all/history/"
            element={<SeeAllHistory />}
          />
          <Route exact path="/read/blog/:blogId" element={<BlogDetail />} />
          <Route exact path="/blogs/list/" element={<BlogsList />} />
          <Route
            exact
            path="/service-details/:serviceId"
            element={<AllSwiperSlider />}
          />
          <Route
            exact
            path="/birthday/setting/"
            element={<BirthDayAutoFill />}
          />
          <Route
            exact
            path="/my-account/personal-details"
            element={<EditPersonalDetails />}
          />
          <Route exact path="/my-account/address" element={<Address />} />
          <Route exact path="/my-account/creditcard" element={<CreditCard />} />
          <Route
            exact
            path="/my-account/add-credit-card"
            element={<AddCreditCard />}
          />
          <Route
            exact
            path="/my-account/edit-credit-card/:source_id"
            element={<EditCreditCard />}
          />
          <Route
            exact
            path="/my-account/appointments"
            element={<Appointments />}
          />
          <Route
            exact
            path="/my-account/appointment-details"
            element={<AppointmentDetails />}
          />
          <Route
            exact
            path="/my-account/reschedule-appointment"
            element={<RescheduleAppointment />}
          />
          <Route
            exact
            path="/my-account/cancel-my-appointment"
            element={<CancelMyAppointment />}
          />
          <Route
            exact
            path="/my-account/cancel-appointment"
            element={<CancelAppointment />}
          />
          <Route
            exact
            path="/my-account/bookingCancel"
            element={<BookingCancel />}
          />
          <Route
            exact
            path="/my-account/writereview"
            element={<WriteReview />}
          />
          <Route exact path="/my-account/review" element={<Review />} />
          <Route
            exact
            path="/my-account/track-appointment/:appointmentId"
            element={<AppointmentTrack />}
          ></Route>
          <Route
            exact
            path="/my-account/righthandcredit"
            element={<RighthandCredit />}
          />
          <Route
            exact
            path="/my-account/righthandwallet"
            element={<RighthandWallet />}
          />
          <Route
            exact
            path="/my-account/credit-packages"
            element={<CreditPackages />}
          />
          <Route
            exact
            path="/my-account/credit-packages/payment"
            element={<CreditPackagesPayment />}
          />
          <Route
            exact
            path="/my-account/free/credit"
            element={<FreeCredit />}
          />
          <Route
            exact
            path="/my-account/creditdetails"
            element={<CreditDetails />}
          />
          <Route
            exact
            path="/old-service/checkout/details"
            element={<ServiceCheckout />}
          />
          <Route
            exact
            path="/professional-profile"
            element={<ProfessionalProfile />}
          />
          <Route
            exact
            path="frequency/checkout/details"
            element={<FrequencyCheckout />}
          />
          <Route exact path="faq" element={<FAQ />} />
          <Route exact path="/terms/conditions" element={<TermsConditions />} />
          <Route exact path="/rh-club/policy" element={<RHClubPolicy />} />
          <Route
            exact
            path="/rh-club/policy/details"
            element={<RHClubPolicyDetails />}
          />
          <Route exact path="/privacy/policy" element={<PrivacyPolicy />} />
          <Route exact path="/cancellation/policy" element={<Cancellation />} />
          <Route
            exact
            path="/reschedule/policy"
            element={<ReschedulePolicy />}
          />
          <Route
            exact
            path="/service-details/:serviceDetailId"
            element={<ServiceDetails />}
          />
          <Route
            exact
            path="/my-account/reschedule-appointment/policy"
            element={<RescheduleAppointmentPolicy />}
          />
          <Route
            exact
            path="/my-account/common-checkout"
            element={<CommonCheckout />}
          />
          <Route exact path="/covid" element={<Covid />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/righthand-guarantee"
            element={<RighthandGuarntee />}
          />
          <Route exact path="/membership" element={<Membership />} />
          <Route exact path="/buy/membership" element={<BuyMemberShip />} />
          <Route
            exact
            path="/buy/extendmembership"
            element={<ExtendedMembership />}
          />
          <Route exact path="/deeplink" element={<Deeplink />} />
          <Route exact path="/deeplink/referral" element={<Deeplink />} />
          <Route
            exact
            path="/my-account/package-checkout/:packageId"
            element={<BlankLoading />}
          />
          <Route exact path="/my-account/my-fav-pros" element={<MyfavPros />} />
          <Route
            exact
            path="/my-account/card-updating"
            element={<CardUpdating />}
          />
          <Route exact path="/my-account/referral" element={<FreeServices />} />
          <Route
            exact
            path="/my-account/how-it-works"
            element={<HowItworks />}
          />
          <Route
            exact
            path="/my-account/track-invites"
            element={<TrackInvites />}
          />
          <Route path="/test-file">{"/css/slick-theme.css"}</Route>
          <Route exact path="/customer/login" element={<Login />} />
          <Route
            exact
            path="/customer/login/signin-mobile"
            element={<SignwithMobile />}
          />
          <Route exact path="/customer/login/otp" element={<Otp />} />
          <Route
            exact
            path="/customer/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/customer/claim-account"
            element={<ClaimAccount />}
          />
          <Route exact path="/add-more-service" element={<Disinfection />} />
          <Route exact path="/old-service" element={<TabsServicePage />} />
          <Route exact path="/rh-club" element={<RighthandsClub />} />
          <Route exact path="/announcement" element={<Announcements />} />
          <Route exact path="/summary" element={<AppointmentSummary />} />
          <Route exact path="/summary/status" element={<ApptSummaryRedirect />} />
          <Route
            exact
            path="/service/service-cat"
            element={<ServiceByCategorie />}
          />
          {/* <Route
          exact
          path="/checkout/successfulPayment"
          element={<SuccessFullPayment />}
        /> */}
          <Route
            exact
            path="my-account/appointment/extend"
            element={<ExtendAppointments />}
          />
          <Route
            extact
            path="/my-account/paymentHistory"
            element={<PaymentHistory />}
          />
          <Route path="/*" element={<PageNotFound />} exact />
        </Routes>
        <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
      </HelmetProvider>
    </>
  );
}

export default App;
