import React from "react";
import { NavLink,useNavigate
} from "react-router-dom";
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'; 
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {ModifiedToastContainer} from '../../components/ModifiedToastContainer';

const AccountDetails = () => {
  const { t } = useTranslation();
 
  let navigate = useNavigate();

  function changeLocation(placeToGo){
      navigate(placeToGo, { replace: true });
      window.location.reload();
  }

  const logout = (e) => {
    toast.success( t("logout_successfully"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentToken');
      localStorage.removeItem('currentLatitude');
      localStorage.removeItem('currentLongitude');
      // localStorage.clear();
      window.location.href = '/';
    }, 1000);
};

  return (
    <>
      <Header />
      <ModifiedToastContainer/>
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <h2>{t('account_details')}</h2>
            </div>
            <div className="card">
            <div className="accountSettings accountDetails">
              <ul className="list">
                <li>
                  <NavLink to="/my-account/profile-update">
                  <span><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/profile.png.webp" className="me-3 w-5"/>  {t('profile')}</span> <i className="fa fa-angle-right"></i>
                  </NavLink>
                </li>
               
                <li>
                  <NavLink to="/my-account/address" onClick={() => changeLocation('/my-account/address')}>
                  <span><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/location.png.webp" className="me-3 w-5" />  {t('_addresses')}</span>  <i className="fa fa-angle-right"></i>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/my-account/creditcard">
                  <span><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/credit-cards-payment.png.webp" className="me-3 w-5" /> {t('payments')}</span>  <i className="fa fa-angle-right"></i>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/my-account/paymentHistory">
                  <span><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/transaction-history.png.webp" className="me-3 w-5"  /> {t('Payment History')}</span>  <i className="fa fa-angle-right"></i>
                  </NavLink>
                </li>
               <li>
                <NavLink to="" className="slogout active" onClick={logout}>{t('logout')}</NavLink>
               </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountDetails;
