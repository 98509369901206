import React, { useEffect, useState } from "react";
import "./BecomePro.css";
import { useDispatch, useSelector } from "react-redux";
import { getHomeMainService } from "../actions/homeAction";
import { countryListAction } from "../actions/userActions";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "./Footer";
import { Modal } from "react-bootstrap";

const BecomePro = () => {
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { servicesResult } = getAllServicesState;
  const dispatch = useDispatch();
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [applied, setApplied] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [showHelpModal, setShowHelpModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Get the selected country code
    const selectedCountry = country_list.find(
      (country) => country.id === selectedCountryId
    );

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: phone,
      country_code: selectedCountry?.country_flag,
      service_id: selectedService,
      referral_code: referralCode,
    };

    axios
      .post(`${baseUrl}/applyJob/create`, formData)
      .then((response) => {
        toast.success(response.data.message);
        setErrorMsg(response.data.message);
        setApplied(true);
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
        toast.error(error.response.data.message, {
          position: "top-right",
        });
        setApplied(false);
      });
  };

  useEffect(() => {
    // Fetch categories and their respective services
    let listMainUrl =
      baseUrl + `/home/dashboard?country_id=${selectedCountryId}`;
    if (selectedCountryId) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, [selectedCountryId]);

  let listUrl = baseUrl + "/country/list";
  useEffect(() => {
    dispatch(countryListAction(listUrl, {}));
  }, [listUrl, dispatch]);

  return (
    <>
      <div className="landing-page" dir="ltr">
        {/* Header */}
        <header className="header">
          <p>
            Right Hand Pro is Jazz HR Services. <a href="https://info.jazzhr.com/job-seekers.html">Learn More</a>
          </p>
        </header>
        <p className="need-help" onClick={() => setShowHelpModal(true)}>
          Need Help?
        </p>
        {/* Hero Section */}
        <section className="hero">
          <h1>Start earning money this week!</h1>
          <p>Explore hundreds of job opportunities</p>
          <form className="signup-form" onSubmit={handleSubmit}>
            <div className="d-flex w-50">
              <input
                type="text"
                placeholder="First Name"
                required
                className="w-100 fw-bold"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ marginRight: "10%" }}
              />
              <input
                type="text"
                placeholder="Last Name"
                required
                className="w-100 fw-bold"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="d-flex w-50">
              <input
                type="email"
                placeholder="Email"
                required
                className="w-100 fw-bold"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginRight: "10%" }}
              />
              <input
                type="tel"
                placeholder="Phone Number"
                required
                className="w-100 fw-bold"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="d-flex w-50">
              <select
                value={selectedCountryId}
                onChange={(e) => {
                  setSelectedCountryId(e.target.value);
                }}
                required
                style={{ width: "100%", marginRight: "10%" }}
                className="fw-bold"
              >
                <option value="">Select your Country</option>
                {country_list?.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>

              <select
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                required
                style={{ width: "100%" }}
                className="fw-bold"
              >
                <option value="">Select your primary service</option>
                {servicesResult?.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-50">
              <input
                type="text"
                placeholder="Referral Code (Optional)"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="w-100 mt-2 fw-bold"
              />
              </div>

              <div className="w-50">
              <button type="submit" className="mt-3 fw-bold">
                Get Started
              </button>
              </div>
            
            {applied && (
              <p style={{ color: "green", fontSize: "small" }}>{errorMsg}</p>
            )}
            {applied === false && (
              <p style={{ color: "red", fontSize: "small" }}>{errorMsg}</p>
            )}
          </form>
          <p>
            Already applied?{" "}
            <a href="#" style={{ fontWeight: "bold" }}>
              Check your application status here.
            </a>
          </p>
        </section>

        {/* Features Section */}
        <section className="features">
          <div className="feature">
            <img src="/images/performance.png" width="31%" height="40%" />
            <h3>Performance-Based Rewards</h3>
            <p>
              Earn points for excellent service that can be redeemed for cash
              bonuses.
            </p>
          </div>
          <div className="feature">
            <img src="/images/tips.png" width="31%" height="40%" />
            <h3>Tips Directly to Wallet</h3>
            <p>
              Receive tips instantly into your digital wallet, accessible
              anytime.
            </p>
          </div>
          <div className="feature">
            <img src="/images/skills.png" width="31%" height="40%" />
            <h3>Skill Development Programs</h3>
            <p>
              Professional training to enhance your skills and improve earnings.
            </p>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>What professionals are saying</h2>
          <p style={{ marginLeft: "20%", marginRight: "20%" }}>
            Working with Right Hands has given me stability and peace of mind. I
            don't have to worry about finding customers or dealing with late
            payments. - all I focus is on delivering great service.
          </p>
          <div
            style={{ backgroundImage: "url(/images/reviewTag.png)" }}
            className="reviewTag d-flex"
          >
            <img
              src="/images/150.jpg"
              alt="Liezel"
              width="20%"
              height="80px"
              style={{ borderRadius: "50%" }}
            />
            <div>
              <h4>Liezel</h4>
              <p>Housekeeper, Philippines</p>
            </div>
          </div>
        </section>

        {/* Requirements Section */}
        <section className="requirements">
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "center" }}
          >
            <hr width="40%" />
            <img
              src="/images/comma.png"
              width="4%"
              style={{ paddingTop: "2%" }}
            />
            <hr width="40%" />
          </div>
          <h2>Requirements</h2>
          <ul>
            <li>Must be eligible for sponsorship under Right Hands.</li>
            <li>
              Must possess valid documents (passport, work experience, etc.).
            </li>
            <li>Preference for candidates with GCC work experience.</li>
          </ul>

          <br />
          <p style={{ textAlign: "center", fontSize: "small" }}>
            WE DON'T JUST PROMISE. WE SUPPORT YOU SUCCESS
          </p>
          <p style={{ textAlign: "center", fontSize: "small" }}>
            Righthands is committed to providing you with a stable work
            environment, ongoing training, and opportunities for growth -- all
            while ensuring fair treatment, support and competitive
          </p>
        </section>

        <Modal
          show={showHelpModal}
          onHide={() => {
            setShowHelpModal(false);
          }}
          id="profDetails"
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Need Help?"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="need-help-body">
              <h3>Right Hands Services for Pro</h3>
              <br />

              <p>
                To log in, open the RH Services for Pros app and enter the Email
                Address and Password on your account. Then hit the button
                labelled Login.
              </p>
              <p>
                We'll email you a one-time use, with a code to login. Do not
                share this code with anyone else, these codes are meant to
                provide you with secure access to your business account. Right
                Hands Services will never ask you for this code.
              </p>
              <p>Enter your Password and click Log In.</p>

              <p>
                Once you enter your Password, you'll be ready to go on the Right
                Hands Services for Pros App!
              </p>
              <p>
                If you're having trouble, make sure that the Email Address
                you're entering is the same as the Email on your account. If
                you're new this is the Email you gave us on your application. If
                your Email has changed, or you're having trouble logging in, you
                can contact our Customer Experience team for assistance. You can
                chat with us from the hours of 8 am to 6 pm ET via the blue chat
                icon, or email us any time at wecare@myrighth.com.
              </p>
              <p>
                Please note that if you have been removed from the Right Hands
                Services platform you won't be able to login.
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* Footer */}
      <Footer />
    </>
  );
};

export default BecomePro;
