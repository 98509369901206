import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getFrequencyListAction } from "../actions/frequencyAction";
import axios from "axios";
import { authHeader } from "../helpers/helpers";
import { toast } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import jQuery from "jquery";
import { useCountryCurrency } from "../helpers/useCountryCurrency";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import AddressSetupComponent from "./LoginSignup/SetupAccountModals/AddressSetupComponent";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import PackageDescription from "./DangerousHtmlContent";

const NewServices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceSlug } = useParams();
  // Extract `service.id` from the slug (last part after "-")
  const parts = serviceSlug?.split("-");
  const id = parts[parts?.length - 1];
  const serviceName = parts.slice(0, -1).join(" "); // Remove ID & join back
  const setAddressRef = useRef();
  const [showSetupAddressModal, setShowSetupAddressModal] = useState(false);
  const [showTotalSummary, setShowTotalSummary] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [favPros, setFavPros] = useState([]);
  const [showTimeSlotErr, setShowTimeSlotErr] = useState(true);
  const [autoSelect, setAutoSelect] = useState(true);
  const [service, setService] = useState(null);
  const [serviceCategory, setServiceCategory] = useState(null);
  const [isCategoryFetched, setIsCategoryFetched] = useState(false);
  const [hoursSelected, setHoursSelected] = useState("4");
  const [prosSelected, setProsSelected] = useState("1");
  const [timeSlotSelected, setTimeSlotSelected] = useState({});
  const [availableSlot, setAvailableSlot] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isSlotLoading, setIsSlotLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});
  const [showAnswer, setShowAnswer] = useState(-1);
  const [pkgSvcCountMap, setPkgSvcCountMap] = useState(new Map());
  const getAllFrequencyState = useSelector((state) => state.frequencyReducer);
  const { loading, frequencies } = getAllFrequencyState;
  const [frequency, setFrequency] = useState("Frequency");
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [serviceCategoryId, setServiceCategoryId] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState("");
  const countryCurrency = useCountryCurrency();
  const [minOrderAmount, setMinOrderAmount] = useState(0);
  const [cleanersSlot, setCleanersSlot] = useState({});
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  const loginSignupRef = useRef();
  const [professionalInfoModal, setProfessionalInfoModal] = useState(false);
  const [professionalInfo, setProfessionalInfo] = useState({});
  const [selectedPackageSvc, setSelectedPackageSvc] = useState({});
  const [showSelectedPackageSvc, setshowSelectedPackageSvc] = useState(false);
  const [isAddAddress, setIsAddress] = useState(false);

  const handleProfessionalInfoModalClose = () => {
    setProfessionalInfoModal(false);
  };

  async function viewProfessionalProfile(professional) {
    try {
      const response = await axios.get(
        `${baseUrl}/appointment/professional/info/${professional._id}`,
        {
          headers: authHeader(),
        }
      );
      setProfessionalInfo(response.data);
    } catch (error) {
      console.error("Error fetching professional info:", error);
    }

    setProfessionalInfoModal(true);
  }

  useEffect(() => {
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  useEffect(() => {
    countryCurrency?.map((cc, i) => {
      setCurrency(cc?.currency);
    });
  }, [countryCurrency]);

  const hideTotalSummary = () => {
    setShowTotalSummary(false);
  };

  const hideAddOns = () => {
    setShowAddons(false);
  };

  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let serviceByIDUrl = `${baseUrl}/service/view/${id}`;
  const today = new Date().toISOString().split("T")[0];

  const currentUser = JSON.parse(localStorage.getItem("currentUser")) || false;
  const updateShowAnswer = (i) => {
    setShowAnswer(i);
  };

  // Fetching frequency data on component mount
  useEffect(() => {
    const frequencyUrl = `${baseUrl}/get/frequency?country_id=${localStorage.getItem(
      "userCountryId"
    )}&service_type=Home`;
    dispatch(getFrequencyListAction(frequencyUrl, {}));
  }, [baseUrl, dispatch]);

  // Once frequencies are available, set the default frequency
  useEffect(() => {
    if (frequencies?.length > 0) {
      setFrequency(frequencies[0].id);
    }
  }, [frequencies]);

  // Fetching service and category data
  const fetchServiceData = async () => {
    try {
      const serviceResponse = await axios.get(serviceByIDUrl, {
        headers: authHeader(),
      });
      setService(serviceResponse.data.service);

      // Check if the service is a package and category isn't fetched yet
      if (
        serviceResponse.data.service.ui_type === "Package" &&
        !isCategoryFetched
      ) {
        fetchCategoryData(serviceResponse.data.service._id);
      }

      if (
        serviceResponse.data.service.ui_type === "Package" &&
        sessionStorage.getItem("address") === ""
      ) {
        setAddressRef?.current?.handleSetupAddressModalShow();
      }
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  const fetchCategoryData = async (serviceId) => {
    try {
      const categoryResponse = await axios.post(
        `${baseUrl}/home/service/category`,
        {
          country_id: localStorage.getItem("userCountryId"),
          service_id: serviceId,
        },
        { headers: authHeader() }
      );

      setServiceCategory(categoryResponse?.data?.serviceCategory);
      setIsCategoryFetched(true); // Mark category as fetched
      setServiceCategoryId(categoryResponse?.data?.serviceCategory[0]?._id);

      setMinOrderAmount(categoryResponse?.data?.minOrderAmount);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  useEffect(() => {
    fetchServiceData();
  }, [serviceByIDUrl]);
  // Memoizing the handlers to avoid re-creating them on each render
  const handleItemsIncr = useCallback((item) => {
    setPkgSvcCountMap((prevCounts) => {
      const newCounts = new Map(prevCounts);
      const currentCount = newCounts.get(item) || 0;
      const maxQty = item.maxOrderQty ? item.maxOrderQty : Infinity;
      const minQty = item.minOrderQty ? item.minOrderQty : 1;
      if (currentCount === 0) {
        newCounts.set(item, currentCount + minQty);
        updatePrice(item.package_new_price, minQty);
      } else if (currentCount < maxQty) {
        newCounts.set(item, currentCount + 1);
        updatePrice(item.package_new_price, 1);
      }
      sessionStorage.setItem("pkgSvcCountMap", JSON.stringify(newCounts));
      return newCounts;
    });
  }, []);

  const handleItemsDecr = useCallback((item) => {
    setPkgSvcCountMap((prevCounts) => {
      const newCounts = new Map(prevCounts);
      const currentCount = newCounts.get(item) || 0;
      const minQty = item.minOrderQty ? item.minOrderQty : 0;
      if (currentCount > minQty) {
        newCounts.set(item, currentCount - 1);
        updatePrice(item.package_new_price, -1);
      } else if (currentCount === minQty) {
        newCounts.set(item, currentCount - minQty);
        updatePrice(item.package_new_price, -minQty);
      }
      sessionStorage.setItem("pkgSvcCountMap", JSON.stringify(newCounts));
      return newCounts;
      // const newCounts = new Map(prevCounts);
      // const currentCount = newCounts.get(item) || 0;
      // if (currentCount > 0) {
      //   newCounts.set(item, currentCount - 1);
      //   updatePrice(item.package_new_price, -1);
      // }
      // return newCounts;
    });
  }, []);

  const handleDateChange = useCallback((event) => {
    setSelectedDate(event.target.value);
    updateCheckoutData("appointment_date", event.target.value);
  }, []);

  const handleFrequencyChange = useCallback((event) => {
    setFrequency(event.target.value);
    updateCheckoutData("frequency", event.target.value);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCleanersSlot = useCallback(
    async (cleanerId) => {
      setIsSlotLoading(true);
      let listUrl = `${baseUrl}/appointment/cleaner/month/slots`;
      let data = {
        cleaner_id: cleanerId,
        appointment_date: selectedDate,
        hours_count:
          service?.ui_type === "Package" ? 2 : parseInt(hoursSelected),
        service_id: id,
        is_home_cleaning: service?.ui_type === "Package" ? false : true,
        country_id: localStorage.getItem("userCountryId"),
        latitude: localStorage.getItem("mapLatitude"),
        longitude: localStorage.getItem("mapLongitude"),
        frequency_id:
          service?.ui_type !== "Package"
            ? frequency !== "Frequency"
              ? frequency
              : null
            : null,
        customer_id: currentUser ? currentUser._id : "",
      };

      try {
        const response = await axios.post(listUrl, data);
        updateCheckoutData("codCharges", response.data?.codCharges); 
        setCleanersSlot({
          [cleanerId]: response.data?.monthSlots,
        });
        let dateSplit = selectedDate.split("-");
        const result = response.data.monthSlots.find(
          (item) =>
            item.date === dateSplit[2] && item.currentMonth === dateSplit[1]
        );
        setAvailableSlot(result?.availability);
        setSelectedCleaners(cleanerId);
        setShowTimeSlotErr(false);
      } catch (error) {
        setShowTimeSlotErr(true);
        setAvailableSlot([]);
        setTimeSlotSelected({});
      }

      setIsSlotLoading(false);
    },
    [selectedDate, prosSelected, hoursSelected, frequency, id]
  );

  // Fetch time slots based on selected values
  const getTimeSlot = useCallback(async () => {
    setIsSlotLoading(true);
    let listUrl = `${baseUrl}/appointment/cleaner/time/availability`;
    let data = {
      cleaner_id: "",
      is_auto_assign: true,
      appointment_date: selectedDate,
      hours_count: service?.ui_type === "Package" ? 2 : parseInt(hoursSelected),
      service_id: id,
      is_home_cleaning: service?.ui_type === "Package" ? false : true,
      country_id: localStorage.getItem("userCountryId"),
      latitude: localStorage.getItem("mapLatitude"),
      longitude: localStorage.getItem("mapLongitude"),
      frequency_id:
        service?.ui_type !== "Package"
          ? frequency !== "Frequency"
            ? frequency
            : null
          : null,
      professionalCount:
        frequency !== "Frequency" ? parseInt(prosSelected) : null,
      customer_id: currentUser ? currentUser._id : "",
    };

    try {
      const response = await axios.post(listUrl, data);
      if (response.data.available_time.length > 0) {
        console.log("abc new service", response.data?.codCharges)
        updateCheckoutData("codCharges", response.data?.codCharges);
        setTimeSlotSelected(response.data.available_time[0]);
      } else {
        setTimeSlotSelected({});
      }
      setAvailableSlot(response.data.available_time);
      setSelectedCleaners(response.data.selectedCleaner);
      setShowTimeSlotErr(false);
      setCleanersSlot({});
    } catch (error) {
      setShowTimeSlotErr(true);
      setAvailableSlot([]);
      setTimeSlotSelected({});
    }
    setIsSlotLoading(false);
  }, [selectedDate, prosSelected, hoursSelected, frequency, id]);

  useEffect(() => {
    if (!autoSelect) {
      if (selectedCleaners?.length > 0) {
        getCleanersSlot(selectedCleaners);
        return;
      }
    }
    getTimeSlot();
  }, [selectedDate, prosSelected, hoursSelected, frequency, getTimeSlot]);

  // Updating checkout data
  const updateCheckoutData = (key, value) => {
    setCheckoutData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const updatePrice = (price, quantity) => {
    setTotalPrice((prevTotalPrice) => prevTotalPrice + price * quantity);
  };

  const getFavPros = useCallback(async () => {
    const favProsUrl = `${baseUrl}/appointment/professional/list`;
    axios
      .get(favProsUrl, {
        params: {
          service_id: id,
          customer_id: currentUser ? currentUser._id : "",
          longitude: localStorage.getItem("mapLongitude"),
          latitude: localStorage.getItem("mapLatitude"),
        },
        headers: authHeader(),
      })
      .then((response) => {
        setFavPros(response.data.list);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-right",
        });
      });
  });

  const handleShowAddInfoPopup = () => {
    if (
      !selectedDate ||
      !hoursSelected ||
      !prosSelected ||
      !sessionStorage.getItem("address")
    ) {
      if (!sessionStorage.getItem("address")) {
        setAddressRef?.current?.handleSetupAddressModalShow();
        return;
      } else {
        toast.error("Please fill all the required fields", {
          duration: 2000,
          position: "top-right",
        });
        return;
      }
    }

    getFavPros();
    getTimeSlot();
    setShowAddons(true);
  };

  const handleShowAddInfoPopupPackage = () => {
    if (
      !selectedDate ||
      !hoursSelected ||
      !prosSelected ||
      !timeSlotSelected ||
      !sessionStorage.getItem("address")
    ) {
      if (!sessionStorage.getItem("address")) {
        setAddressRef?.current?.handleSetupAddressModalShow();
        return;
      } else {
        toast.error("Please fill all the required fields", {
          duration: 2000,
          position: "top-right",
        });
        return;
      }
    }

    handleFormSubmit();
  };

  const handleFormSubmit = () => {
    if (!currentUser) {
      loginSignupRef.current.handleLoginSignupModalShow();
      return;
    } else if (
      !frequency ||
      frequency === "Frequency" ||
      !timeSlotSelected || // null or undefined
      Object.keys(timeSlotSelected).length === 0 ||
      !hoursSelected ||
      !prosSelected
    ) {
      toast.error("Fill/select all the fields to book the service", {
        duration: 2000,
        position: "top-right",
      });
    } else {
      const currentCheckoutData = {
        frequency_id: frequency,
        hours: hoursSelected,
        no_of_pros: prosSelected,
        appointment_date: selectedDate,
        service_id: id,
        country_id: localStorage.getItem("userCountryId"),
        time_slot: timeSlotSelected.time,
        start_time: timeSlotSelected.start_time,
        end_time: timeSlotSelected.end_time,
        slot_price: timeSlotSelected.totalSlotPrice,
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude"),
        customer_id: currentUser ? currentUser._id : "",
        service_price:
          service?.ui_type === "Package"
            ? totalPrice
            : service?.new_price * hoursSelected,
        service: service,
        customer: currentUser,
        selected_cleaners: selectedCleaners,
        cod_charges: checkoutData?.codCharges,
        selected_services: pkgSvcCountMap ? pkgSvcCountMap : null,
        currency: currency,
        is_cleaning_materials: checkoutData?.is_cleaning_materials,
        cod_charges: checkoutData?.codCharges
      };

      setCheckoutData(currentCheckoutData);

      navigate("/summary", {
        state: {
          checkoutData: currentCheckoutData,
        },
      });
    }
  };

  const toggleTab = (e, categoryId) => {
    e.preventDefault();
    localStorage.removeItem("serviceCategoryPackageId");
    jQuery(".serviceCategoryBtn").removeClass("tabBtn-active");
    jQuery(e.target).addClass("tabBtn-active");
    setServiceCategoryId(categoryId);
  };

  useEffect(() => {
    const category = serviceCategory?.find(
      (cat) => cat._id === serviceCategoryId
    );
    setSelectedCategory(category);
  }, [serviceCategoryId]);

  const settings = {
    infinite: false,
    slidesToShow: 3, // Show 6 slides initially
    slidesToScroll: 0,
    // centerMode: true,
    centerPadding: "0px",
    draggable: true,
    variableWidth: true,
    justifyContent: "center",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides on tablet
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  let minOrderError = t("min_order_amount_error");
  let amount = currency + "  " + Number(minOrderAmount - totalPrice).toFixed(2);
  const formattedMinOrderError = minOrderError.replace("${amount}", amount);

  if (loading) {
    return <Loader />;
  }

  let locationImgBackgroundPosition =
    currentLanguage === "en" ? "right" : "left";

  let serviceFaqs =
    currentLanguage === "en" ? service?.faqs : service?.faqs_arabic;

  const sharedStyles = {
    width: "45%",
    height: "3rem",
    borderRadius: "0.375rem", // rounded-md
    border: "1px solid #ccc",
    padding: "0 0.75rem",
    fontSize: "1rem",
    appearance: "none", // helps style select more consistently
    backgroundColor: "white",
    WebkitAppearance: "none",
    MozAppearance: "none",
  };

  return (
    <>
      <Helmet>
        <title>
          {`RightHands | ${serviceName} service details | ${service?.name_arabic} تفاصيل الخدمة`}
        </title>
        <meta
          name="description"
          content="Service details, view, explore and book services online in Qatar, United Arab Emirated(UAE) and Saudi Arabia. RightHand is the best service provider. Book now! | تفاصيل الخدمة، عرض، استكشاف وحجز الخدمات عبر الإنترنت في قطر، الإمارات العربية المتحدة والمملكة العربية السعودية. RightHand هو أفضل مزود خدمة. احجز الآن!"
        />
        <link
          rel="canonical"
          href={`https://betaweb.myrighthands.com/service/${serviceSlug}`}
        />
      </Helmet>
      <Header />
      {service?.ui_type !== "Package" ? (
        <>
          <div className="service-booking-marginBottom">
            <div
              className="background-image-service-booking"
              style={{
                backgroundImage: `url(${service?.icon_web ? service?.icon_web : service?.icon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="booking-form background-white-shadow">
                <div className="form-style-service">
                  <p>{isCategoryFetched}</p>
                  <h2 style={{ marginBottom: "30px", fontSize: "2.5rem" }}>
                    {currentLanguage === "en"
                      ? service?.name
                      : service?.name_arabic}
                  </h2>
                  <div style={{ marginBottom: "2%" }}>
                    {[0, 0, 0, 0, 0].map((_, index) => {
                      const isHalfStar = 3.5 - index === 0.5;
                      const isFullStar = 3.5 > index;
                      return (
                        <span
                          key={index}
                          className={`fa ${
                            isFullStar
                              ? "fa-star"
                              : isHalfStar
                              ? "fa-star-half-o"
                              : "fa-star-o"
                          }`}
                          style={{
                            color: isFullStar || isHalfStar ? "orange" : "gray",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        ></span>
                      );
                    })}
                    <span>
                      <u style={{ fontSize: "16px", marginLeft: "2%" }}>
                        7,400 {t("reviews")}
                      </u>
                    </span>
                  </div>
                  <form className="form-flex">
                    <div className="pro-selection" style={{ marginLeft: "6%" }}>
                      <select
                        className="form-element-border frequency-dropdown"
                        placeholder="Frequency"
                        value={frequency}
                        onChange={handleFrequencyChange}
                        style={{ borderRadius: "5px" }}
                      >
                        {frequencies?.map((f, i) => {
                          return (
                            <option value={f.id} key={f.id}>
                              {currentLanguage === "en"
                                ? f.package_name
                                : f.package_name_arabic}
                            </option>
                          );
                        })}
                      </select>
                      <div className="d-flex service-booking">
                        <select
                          className="select-dropdown-service"
                          value={hoursSelected}
                          onChange={(event) => {
                            setHoursSelected(event.target.value);
                            updateCheckoutData("hours", event.target.value);
                          }}
                          style={{ borderRadius: "5px" }}
                        >
                          <option value="2">2 {t("hours")}</option>
                          <option value="3">3 {t("hours")}</option>
                          <option value="4">4 {t("hours")}</option>
                          <option value="5">5 {t("hours")}</option>
                        </select>
                      </div>
                      <div className="d-flex service-booking">
                        <select
                          className="select-dropdown-service"
                          value={prosSelected}
                          onChange={(event) => {
                            setProsSelected(event.target.value);
                            updateCheckoutData(
                              "no_of_pros",
                              event.target.value
                            );
                          }}
                          style={{ borderRadius: "5px" }}
                        >
                          <option value="1">
                            1{" "}
                            {service?.ui_type === "Frequency"
                              ? t("pro")
                              : service?.ui_type === "Wheel"
                              ? t("babysitters")
                              : ""}
                          </option>
                          <option value="2">
                            2{" "}
                            {service?.ui_type === "Frequency"
                              ? t("pros")
                              : service?.ui_type === "Wheel"
                              ? t("babysitters")
                              : ""}
                          </option>
                          <option value="3">
                            3{" "}
                            {service?.ui_type === "Frequency"
                              ? t("pros")
                              : service?.ui_type === "Wheel"
                              ? t("babysitters")
                              : ""}
                          </option>
                          <option value="4">
                            4{" "}
                            {service?.ui_type === "Frequency"
                              ? t("pros")
                              : service?.ui_type === "Wheel"
                              ? t("babysitters")
                              : ""}
                          </option>
                        </select>
                      </div>
                      <div
                        className="d-flex service-booking"
                        style={{ marginRight: "12%" }}
                      >
                        <input
                          type="date"
                          value={selectedDate} // Bind to the state for controlled component
                          onChange={handleDateChange} // Update the state when a new date is selected
                          min={today} // Disable past dates
                          className="no-calendar select-dropdown-service"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                    <div className="" style={{ marginLeft: "6%" }}>
                      <button
                        className="booking-continue-button btn-continue initial-height btn btn-primary"
                        style={{
                          width: "22%",
                          backgroundColor: "rgb(158, 109, 200)",
                          borderRadius: "5px",
                          border: "none",
                          marginTop: "7px",
                          marginRight: "12%",
                          borderTopColor: "none",
                        }}
                        type="button"
                        onClick={handleShowAddInfoPopup}
                      >
                        <span style={{ color: "white", fontSize: "100%" }}>
                          {t("continue_btn")}
                        </span>
                      </button>
                    </div>
                    <div>
                      <p style={{ marginTop: "0%" }}>
                        {t("customise_n_book")}
                      </p>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        service?.ui_type === "Package" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundImage: `url(${service?.icon_web ? service?.icon_web : service?.icon})`,
              backgroundPosition: "center top",
              backgroundSize: "100% 300px",
              backgroundRepeat: "no-repeat",
            }}
            className="service-booking small-service-booking"
          >
            <div
              className="background-white-shadow why-book-service why-book-service-package"
              style={{ marginLeft: "5%" }}
            >
              <div className="why-book-service-main">
                {serviceCategory?.length > 0 ? (
                  <div style={{ width: "90%" }}>
                    <Slider {...settings} className="serviceCategorySlider">
                      {serviceCategory?.length > 0
                        ? serviceCategory.map((sc, index) => {
                            let itemKey = index + 1;
                            return (
                              <div key={itemKey} 
                              className={index === 0 ? 'first-pill' : index === serviceCategory?.length -1 ? 'last-pill': ''}>
                                <button
                                  className={
                                    "serviceCategoryBtn " +
                                    (itemKey === 1
                                      ? "tabBtn-active firstServiceCategory"
                                      : "")
                                  }
                                  onClick={(e) => toggleTab(e, sc?._id)}
                                  style={{
                                    marginRight: "5%",
                                    width: "max-content",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {currentLanguage === "en"
                                    ? sc?.name
                                    : sc?.name_arabic}
                                </button>
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <ul className="service-package">
                    {selectedCategory &&
                      selectedCategory.servicePackage?.map((sc, i) => {
                        return (
                          <div
                            key={i}
                            className="background-white-shadow service-package-items"
                          >
                            <img
                              src={sc.icon}
                              width="100%"
                              height="200px"
                              alt={"/images/no_image.jpg"}
                              title={sc.name}
                              style={{ objectFit: "cover" }}
                            />
                            {/*                          
                          <h4
                          style={{ textAlign: "center", marginTop: "10px", fontSize: 'medium' }}
                          >
                          {currentLanguage === "en"
                            ? sc.name
                            : sc.name_arabic}
                          </h4> */}
                            <div
                              className="d-inline-flex align-items-center border overflow-hidden"
                              style={{ width: "100%" }}
                            >
                              <button
                                className="btn px-3 border-end incr-decr-btn"
                                onClick={() => handleItemsDecr(sc)}
                                style={{ ":hover": "background-color: #grey" }}
                              >
                                –
                              </button>

                              <div
                                className="px-3 d-flex align-items-center justify-center"
                                style={{
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <span className="fw-bold me-1 small">
                                  {pkgSvcCountMap.get(sc) || 0}
                                </span>
                                <span
                                  className="text-muted small me-1"
                                  style={{ fontSize: "x-small" }}
                                >
                                  {currentLanguage === "en"
                                    ? sc.name
                                    : sc.name_arabic}
                                </span>
                                <i
                                  className="bi bi-info-circle text-secondary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={sc.description}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSelectedPackageSvc(sc);
                                    setshowSelectedPackageSvc(true);
                                  }}
                                ></i>
                              </div>

                              <button
                                className="btn px-3 border-start incr-decr-btn"
                                onClick={() => handleItemsIncr(sc)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div style={{ marginRight: "1%" }} className="package-right-fixed">
                <div
                  className="background-white-shadow package-service-right "
                  style={{ height: "fit-content", marginBottom: "10%" }}
                >
                  <h4>{t("booking_details")}</h4>
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey" }}>{t("address")}</p>
                    {!sessionStorage.getItem("address") ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setAddressRef?.current?.handleSetupAddressModalShow()
                        }
                        style={{
                          width: "50%",
                          fontSize: "small",
                          padding: "0",
                        }}
                      >
                        {t("add")} {t("address")}
                      </button>
                    ) : (
                      <p style={{ fontSize: "small", textAlign: "right" }}>
                        {sessionStorage.getItem("address")}
                      </p>
                    )}
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey" }}>{t("service")}</p>
                    <p style={{ fontSize: "small", textAlign: "right" }}>
                      {currentLanguage === "en"
                        ? service?.name
                        : service?.name_arabic}
                    </p>
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey", textAlign: "left" }}>
                      {t("service")} {t("details")}
                    </p>
                    <ul style={{ textAlign: "right" }}>
                      {[...pkgSvcCountMap.entries()].map(([key, value]) => {
                        return (
                          value !== 0 && (
                            <p
                              style={{
                                fontSize: "small",
                              }}
                            >
                              {value}
                              {" X "}
                              {key.name}
                            </p>
                          )
                        );
                      })}
                    </ul>
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey" }}>{t("date")}</p>
                    <input
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      min={today}
                      defaultValue={today}
                      className="no-calendar"
                      style={sharedStyles}
                    />
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey" }}>{t("time_slot")}</p>
                    {isSlotLoading ? (
                      "Loading ..."
                    ) : (
                      <select
                        style={sharedStyles}
                        onChange={(event) => {
                          let selectedSlot = JSON.parse(event.target.value);
                          setTimeSlotSelected(selectedSlot);
                          updateCheckoutData("time_slot", selectedSlot.time);
                          updateCheckoutData(
                            "start_time",
                            selectedSlot.start_time
                          );
                          updateCheckoutData("end_time", selectedSlot.end_time);
                        }}
                      >
                        {availableSlot?.map((as, i) => (
                          <option key={i} value={JSON.stringify(as)}>
                            {as.time}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {showTimeSlotErr && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "small",
                        textAlign: "center",
                      }}
                    >
                      {t("no_slot_available_on_holiday")}
                    </p>
                  )}
                </div>
                <div
                  className="background-white-shadow package-service-right"
                  style={{ height: "fit-content" }}
                >
                  <h4>{t("payment_summary")}</h4>
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "5%" }}
                  >
                    <p style={{ color: "grey" }}>{t("total")}</p>
                    <p style={{ fontSize: "medium", textAlign: "right" }}>
                      {currency} {Number(totalPrice).toFixed(2)}
                    </p>
                  </div>

                  {minOrderAmount - totalPrice > 0 ? (
                    <div
                      class="alert-container"
                      style={{
                        fontSize: "100%",
                        marginBottom: "5%",
                        marginLeft: "5%",
                        textAlign: "left",
                        alignItems: "flex-start",
                      }}
                    >
                      <span class="alert-icon">&#9888;</span>
                      {"   "}
                      <span class="min-order-style">
                        {formattedMinOrderError}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    className="booking-continue-button btn-continue initial-height btn btn-primary"
                    style={{ width: "75%" }}
                    type="button"
                    onClick={handleShowAddInfoPopupPackage}
                    disabled={minOrderAmount > totalPrice ? true : false}
                  >
                    {t("continue_btn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div
        className="container"
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <div
          className={`how-works ${
            service?.ui_type === "Package"
              ? "how-works-small-package"
              : "how-works-small"
          }`}
        >
          <div className="how-works-header">
            <hr />
            <h1>{t("how_works")}</h1>
            <hr />
          </div>

          <div className="how-works-items">
            <div className="how-works-subitems">
              <img
                src="/images/customise-book.png"
                alt="how=righthand-works"
                title="Customise and Book as per your needs"
                width="70px"
              ></img>
              <h4 style={{ marginTop: "13%", marginBottom: "0%" }}>
                {t("customise_book")}
              </h4>
              <p style={{ marginTop: "7%", marginBottom: "0%" }}>
                {t("book_desc")}
              </p>
            </div>
            <div className="how-works-subitems">
              <img
                src="/images/easily-manage.png"
                alt="Eaily Manage"
                title="Easily Manager everything using RightHands"
                width="70px"
              ></img>
              <h4 style={{ marginTop: "13%", marginBottom: "0%" }}>
                {t("easily_manage")}
              </h4>
              <p
                style={{
                  marginTop: "7%",
                  marginBottom: "0%",
                  marginLeft: "6%",
                }}
              >
                {t("choose_plan")}
              </p>
            </div>
            <div className="how-works-subitems">
              <img
                src="/images/sit-relax.png"
                alt="relax"
                title="Sit and Relax, let Righthands get the job done!"
                width="70px"
              ></img>
              <h4 style={{ marginTop: "13%", marginBottom: "0%" }}>
                {t("sit_relax")}
              </h4>
              <p style={{ marginTop: "7%", marginBottom: "0%" }}>
                {t("rest_work_play")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {((currentLanguage === "en" && service?.introduction?.title) ||
        (currentLanguage === "ar" && service?.introduction?.title_arabic)) && (
        <div className="background-grey why-book-service">
          <div className="why-book-service-main-new">
            <br />
            <h1>
              {currentLanguage === "en"
                ? service?.introduction?.title
                : service?.introduction?.title_arabic}
            </h1>
            <p>
              {currentLanguage === "en"
                ? service?.introduction?.description
                : service?.introduction?.description_arabic}
            </p>
            <ul className="why-book-list tick-list">
              {service?.introduction?.service_features.map((point, i) => {
                return <li key={i}>{point}</li>;
              })}
            </ul>
            <button
              className="btn-continue initial-height btn btn-primary"
              onClick={handleScrollToTop}
              style={{ margin: "0% 5% 2% 5%" }}
            >
              {t("get_started")}
            </button>
          </div>
        </div>
      )}

      {((currentLanguage === "en" && service?.quality_assurance?.title) ||
        (currentLanguage === "ar" &&
          service?.quality_assurance?.title_arabic)) && (
            <div className="background-white-shadow quality-service">
              <div className="quality-service-main">
                <br />
                <h1 className="quality-service-heading">
                  {currentLanguage === "en"
                    ? service?.quality_assurance?.title
                    : service?.quality_assurance?.title_arabic}
                </h1>
                <p className="quality-service-description">
                  {currentLanguage === "en"
                    ? service?.quality_assurance?.short_description
                    : service?.quality_assurance?.short_description_arabic}
                </p>
                <ul className="quality-service-points-list">
                  <div className="quality-service-points-list-items">
                    {service?.quality_assurance?.quality_points
                      ?.slice(0, 2)
                      ?.map((point, i) => {
                        return (
                          <div className="quality-service-points">
                            <div className="d-flex">
                              <img
                                src={point.icon_url}
                                height="25px"
                                style={{ marginRight: "2%", maxWidth: "10%" }}
                              />
                              <div>
                                <h3>{point.title}</h3>
                                <p>{point.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="quality-service-points-list-items">
                    {service?.quality_assurance?.quality_points
                      ?.slice(2, 4)
                      ?.map((point, i) => {
                        return (
                          <div className="quality-service-points">
                            <div className="d-flex">
                              <img
                                src={point.icon_url}
                                height="25px"
                                style={{ marginRight: "2%", maxWidth: "10%" }}
                              />
                              <div>
                                <h3>{point.title}</h3>
                                <p>{point.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </ul>
                <button
                  className="btn-continue initial-height btn btn-primary"
                  onClick={handleScrollToTop}
                  style={{ margin: "0% 5% 2% 10%" }}
                >
                  {t("get_started")}
                </button>
              </div>
            </div>
          )}

      {((currentLanguage === "en" && service?.key_benefit?.title !== "") || (
        (currentLanguage === "ar" && service?.key_benefit?.title_arabic && service?.key_benefit?.title_arabic !== ""))
      ) && (
        <div className="background-grey benefits">
          <br />
          <h1 className="benefits-heading">
            {currentLanguage === "en"
              ? service?.key_benefit?.title
              : service?.key_benefit?.title_arabic}
          </h1>

          <div className="benefits-body">
            {service?.key_benefit?.benefits?.map((point, i) => {
              return (
                <div className="benefits-item d-flex item-center" key={i}>
                  {i % 2 === 1 ? (
                    <>
                      <div className="background-white-shadow benefits-text">
                        <h2>{point.name}</h2>
                        <p>{point.description}</p>
                      </div>
                      <img src={point.image_url} className="benefits-image" />
                    </>
                  ) : (
                    <>
                      <img src={point.image_url} className="benefits-image" />
                      <div className="background-white-shadow benefits-text">
                        <h2>{point.name}</h2>
                        <p>{point.description}</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {serviceFaqs?.length > 0 && (
        <div className=" faqs">
          {serviceFaqs?.length > 0 ? (
            <h2 className="faqs-heading">{t("faqs")}</h2>
          ) : (
            ""
          )}
          {serviceFaqs?.map((qa, i) => {
            return (
              <div>
                <div
                  className="d-flex justify-content-between ques-click"
                  onClick={() => {
                    showAnswer === i
                      ? updateShowAnswer(-1)
                      : updateShowAnswer(i);
                  }}
                >
                  <h3 className="ques">{qa.ques}</h3>
                  <p
                    className="ques-icon"
                    onClick={() => {
                      showAnswer === i
                        ? updateShowAnswer(-1)
                        : updateShowAnswer(i);
                    }}
                  >
                    {showAnswer === i ? "-" : "+"}
                  </p>
                </div>
                {i === showAnswer && <p className="ans">{qa.ans}</p>}
                <hr />
              </div>
            );
          })}
        </div>
      )}
      <div className="background-grey locations-main">
        <div className="container locations">
          <div className="locations-header">
            <h2>{t("where_operates")}</h2>
            <p>{t("operates_location")}</p>
          </div>
          <div
            className="locations-item background-white-shadow locations-item-weight"
            style={{
              backgroundPosition: locationImgBackgroundPosition,
              marginRight: "25%",
            }}
          >
            <ul className="items-list">
              {country_list?.map((country, i) => {
                return (
                  <li key={country._id} style={{ color: "black" }}>
                    {currentLanguage === "en"
                      ? country.name
                      : country.name_arabic}
                  </li>
                );
              })}
            </ul>
          </div>
          <br />
        </div>

        <Footer />
      </div>
      {/* <div className="container why-righthands-best">
        <h2>
          {t("why_best")}{" "}
          {currentLanguage === "en" ? service?.name : service?.name_arabic}
        </h2>
        <h4>{t("instant_booking")}</h4>
        <p>{t("instant_booking_desc")}</p>

        <h4>{t("friendly_vetted_pros")}</h4>
        <p>{t("friendly_vetted_pros_desc")}</p>

        <h4>{t("way_you_want")}</h4>
        <p>{t("way_you_want_desc")}</p>

        <button
          className="btn-continue initial-height btn btn-primary"
          onClick={handleScrollToTop}
        >
          {t("get_started")}
        </button>
      </div> */}

      <Modal
        show={showTotalSummary}
        onHide={hideTotalSummary}
        className="totalPriceSummary"
        id="totalPriceSummary"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">Total Summary</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h4>Selected Services</h4>
            <ul>
              {[...pkgSvcCountMap.entries()].map(([key, value]) => {
                return (
                  value !== 0 && (
                    <>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {value}
                          {" X "}
                          {key.name}
                        </p>
                        <p>
                          {currency}{" "}
                          {Number(value * key.package_new_price).toFixed(2)}
                        </p>
                      </li>
                    </>
                  )
                );
              })}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <h4>Total</h4>
          <h4>
            {currency} {Number(totalPrice).toFixed(2)}
          </h4>
        </Modal.Footer>
      </Modal>

      {/* Modal for time slot selection and additional needs */}
      <Modal
        show={showAddons}
        onHide={hideAddOns}
        className="totalPriceSummary"
        id="totalPriceSummary"
        centered
        style={{
          height: "95%",
          top: "5%",
        }}
      >
        {/* Sticky Header */}
        <Modal.Header
          closeButton
          className="modal-header"
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 100,
            borderBottom: "1px solid #ddd",
          }}
        >
          <h3 className="modal-title">{t("additional_info")}</h3>
        </Modal.Header>

        {/* Scrollable Content */}
        <Modal.Body
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "60vh",
            padding: "10px",
          }}
        >
          {/* Favorite Pros Selection */}
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "black",
              }}
            >
              {t("pro_you_prefer_optional")}
            </p>

            {favPros.length === 0 && (
              <p style={{ fontSize: "90%" }}>
                No favourite pros available. We will auto-assign a pro.
              </p>
            )}

            {favPros.length !== 0 && (
              <div>
                <p>{t("pro_auto_assign")}</p>
                <div
                  className="d-flex justify-content-between"
                  style={{ overflowX: "auto" }}
                >
                  {
                    <div
                      className="item"
                      style={{
                        marginRight: "5%",
                        maxWidth: "150px",
                        minWidth: "140px",
                        border: autoSelect ? "1px solid black" : "none",
                        borderRadius: autoSelect ? "5%" : "none",
                      }}
                      onClick={() => {
                        setAutoSelect(true);
                        setSelectedCleaners([]);
                        getTimeSlot();
                      }}
                    >
                      <div className="serviceSlider">
                        <span>
                          <img
                            alt="Auto Assign"
                            src="https://dvdljkkxpxqo3.cloudfront.net/images/window.png.webp"
                            width="80px"
                            height="80px"
                            title="Auto Assing Pro"
                          />
                        </span>
                        <h3>{t("auto_assign")}</h3>
                        <p>{t("we_ll_assign_the_best_professional")}</p>
                      </div>
                    </div>
                  }

                  {favPros.map((professional, i) => (
                    <div className="item" key={i} style={{ marginRight: "5%" }}>
                      <div
                        className="serviceSlider"
                        style={{
                          maxWidth: "150px",
                          minHeight: "170px",
                          minWidth: "125px",
                          border:
                            !autoSelect &&
                            selectedCleaners.includes(professional?._id)
                              ? "1px solid black"
                              : "none",
                          borderRadius: selectedCleaners.includes(
                            professional?._id
                          )
                            ? "5%"
                            : "none",
                        }}
                        onClick={() => {
                          setAutoSelect(false);
                          setSelectedCleaners([professional?._id]);
                          getCleanersSlot(professional?._id);
                        }}
                      >
                        <span>
                          <img
                            alt="fav pros"
                            width="80px"
                            height="80px"
                            style={{ backgroundColor: "#f2e9ff" }}
                            src={
                              professional.profile_pic
                                ? professional.profile_pic
                                : "/images/DefaultPro.webp"
                            }
                          />
                        </span>
                        <h3>
                          {professional?.first_name} <br />
                          <i className="fa fa-star">
                            {" "}
                            <br />
                            {professional.rating ? professional.rating : 4.5}
                            <h6
                              onClick={() =>
                                viewProfessionalProfile(professional)
                              }
                            >
                              {t("see_details")}
                            </h6>
                          </i>
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <hr />

          {/* Date & Time Slot Selection */}
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "black",
              }}
            >
              {t("when_pro_visit")}
              <span style={{ fontSize: "70%" }}></span>
              <p style={{ fontSize: "85%" }}>
                {selectedDate} at {timeSlotSelected?.start_time}
              </p>
            </p>

            {
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "47.5%" }}>
                  <h4>{t("date")}</h4>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={today}
                    defaultValue={today}
                    className="no-calendar"
                    style={{ width: "100%", height: "3rem" }}
                  />
                </div>
                <div style={{ width: "47.5%" }}>
                  <h4>{t("time_slot")}</h4>
                  {isSlotLoading ? (
                    "Loading ..."
                  ) : (
                    <select
                      style={{ width: "100%", height: "3rem" }}
                      onChange={(event) => {
                        let selectedSlot = JSON.parse(event.target.value);
                        setTimeSlotSelected(selectedSlot);
                        updateCheckoutData("time_slot", selectedSlot.time);
                        updateCheckoutData(
                          "start_time",
                          selectedSlot.start_time
                        );
                        updateCheckoutData("end_time", selectedSlot.end_time);
                      }}
                    >
                      {availableSlot?.map((as, i) => (
                        <option key={i} value={JSON.stringify(as)}>
                          {as.time} {as.totalSlotPrice !== 0 ? `(${currency}  ${Number(as.totalSlotPrice).toFixed(2)})` : ""}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            }
          </div>

          {showTimeSlotErr && (
            <p style={{ color: "red", fontSize: "80%" }}>
              {selectedCleaners?.length > 0 &&
              selectedCleaners[0] &&
              availableSlot?.length > 0
                ? "Timeslot not available for selected professional. Select a different date or another pro."
                : "No pros are available. Please select a different date."}
            </p>
          )}

          <hr />

          {/* Cleaning Materials Selection */}
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "black",
              }}
            >
              {t("require_cleaning_materials")}
              <p style={{ fontSize: "85%" }}>
                {t("your_choice")}{" "}
                {checkoutData?.is_cleaning_materials
                  ? t("yes_please")
                  : t("no_i_have_them")}
              </p>
            </p>

            {
              <div className="buttonItem">
                <label>
                  <input
                    type="radio"
                    name="cleaning_materials"
                    defaultChecked={!checkoutData?.is_cleaning_materials}
                    onClick={() =>
                      updateCheckoutData("is_cleaning_materials", false)
                    }
                  />
                  <span>{t("no_i_have_them")}</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="cleaning_materials"
                    defaultChecked={checkoutData?.is_cleaning_materials}
                    onClick={() =>
                      updateCheckoutData("is_cleaning_materials", true)
                    }
                  />
                  <span>{t("yes_please")}</span>
                </label>
              </div>
            }
          </div>

          {/* Additional Needs */}
          <hr />
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: "black",
                marginTop: "1%",
              }}
            >
              {t("specific_cleaning_instructions")}
            </p>
            {
              <textarea
                style={{ width: "100%", height: "10vh" }}
                placeholder={service?.ui_type === "Wheel" ? t("babysitting_instructions_hint") : t("instructions_hint")}
                maxLength={200}
              ></textarea>
            }
          </div>
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ width: "50%", fontSize: "14px" }}
            onClick={handleFormSubmit}
          >
            {currentUser ? t("book_now_btn") : "Login to Continue"}
          </button>
        </Modal.Footer>
      </Modal>

      <LoginSignupModal ref={loginSignupRef} />

      <Modal
        show={professionalInfoModal}
        onHide={handleProfessionalInfoModalClose}
        id="profDetails"
        className="profDetails"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("professional_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="recentTranstion professionalModal">
            <div className="reviewBar">
              <div className="professionalInfoCard">
                <img
                  src={
                    professionalInfo
                      ? professionalInfo?.user?.profile_pic
                      : "/images/DefaultPro.webp"
                  }
                  alt=""
                />
                <h4 className="text-center mb-2">
                  {professionalInfo?.user?.first_name}{" "}
                  {professionalInfo?.user?.last_name}
                </h4>
                <div className="ratingArea">
                  <div className="ratingDiv">
                    <p>{t("ave_rating")}</p>

                    <h4>
                      <span>
                        <i className="fa fa-star"></i>
                        {professionalInfo?.user?.ratingAverage?.toFixed(1)}
                      </span>
                    </h4>
                  </div>
                  <div className="ratingDiv">
                    <p>{t("reviews")}</p>
                    <h4>{professionalInfo?.reviewCount}</h4>
                  </div>
                  <div className="ratingDiv">
                    <p>{t("hired")}</p>
                    <h4>{professionalInfo?.cleanerHired}</h4>
                  </div>
                </div>
              </div>
              <div className="barArea">
                <div className="reviewContent">
                  <p>
                    {t("excellent")} ({professionalInfo?.cleaner5count})
                  </p>
                </div>
                <div className="reviewProgress">
                  <div
                    className="barcolor"
                    style={{ width: professionalInfo?.cleaner5count + "%" }}
                  ></div>
                </div>
              </div>
              <div className="barArea">
                <div className="reviewContent">
                  <p>
                    {t("good")} ({professionalInfo?.cleaner4count})
                  </p>
                </div>
                <div className="reviewProgress">
                  <div
                    className="barcolor"
                    // style={{width : getRatingPerformance('Good',professionalInfo?.cleaner4count, professionalInfo?.user?.ratingAverage.toFixed(1))+'%'}}
                    style={{ width: professionalInfo?.cleaner4count + "%" }}
                  ></div>
                </div>
              </div>
              <div className="barArea">
                <div className="reviewContent">
                  <p>
                    {t("average")} ({professionalInfo?.cleaner3count})
                  </p>
                </div>
                <div className="reviewProgress">
                  <div
                    className="barcolor"
                    style={{ width: professionalInfo?.cleaner3count + "%" }}
                  ></div>
                </div>
              </div>
              <div className="barArea">
                <div className="reviewContent">
                  <p>
                    {t("bad")} ({professionalInfo?.cleaner2count})
                  </p>
                </div>
                <div className="reviewProgress">
                  <div
                    className="barcolor"
                    style={{ width: professionalInfo?.cleaner2count + "%" }}
                  ></div>
                </div>
              </div>
              <div className="barArea">
                <div className="reviewContent">
                  <p>
                    {t("terrible")} ({professionalInfo?.cleaner1count})
                  </p>
                </div>
                <div className="reviewProgress">
                  <div
                    className="barcolor"
                    style={{ width: professionalInfo?.cleaner1count + "%" }}
                  ></div>
                </div>
              </div>
            </div>
            <h3>{t("reviews")}</h3>
            {professionalInfo?.cleanerReviews?.length > 0
              ? professionalInfo?.cleanerReviews.map((cleanerReview, index) => {
                  return (
                    <div className="transtionList" key={index}>
                      <h3>
                        {cleanerReview.review ? cleanerReview.review : ""}{" "}
                        {cleanerReview?.additional_tip !== 0 && (
                          <span>+{cleanerReview.additional_tip} QAR</span>
                        )}
                      </h3>
                      {cleanerReview.rating_point === 5 && (
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      )}
                      {cleanerReview.rating_point === 4 && (
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      )}
                      {cleanerReview.rating_point === 3 && (
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      )}
                      {cleanerReview.rating_point === 2 && (
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      )}
                      {cleanerReview.rating_point === 1 && (
                        <p>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </p>
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </Modal.Body>
      </Modal>

      <AddressSetupComponent
        ref={setAddressRef}
        showSetupAddressModal={showSetupAddressModal}
        setShowSetupAddressModal={setShowSetupAddressModal}
        selectedCountryCode={sessionStorage.getItem("selectedCountry")}
      />

      {/* Package service individual info modal */}
      <Modal
        show={showSelectedPackageSvc}
        onHide={() => {
          setshowSelectedPackageSvc(false);
          setSelectedPackageSvc(null);
        }}
        id="profDetails"
        className="profDetails"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentLanguage === "en"
              ? selectedPackageSvc?.name
              : selectedPackageSvc?.name_arabic}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="package-service-info-modal">
            <p>
              {currentLanguage === "en"
                ? selectedPackageSvc?.description
                : selectedPackageSvc?.description_arabic}
            </p>
            <p>
              {currency} {selectedPackageSvc?.package_new_price}{" "}
              <strike>
                {"  "}
                {currency} {selectedPackageSvc?.package_price}
              </strike>{" "}
            </p>
          </div>
          <hr />
          <div className="package-service-info-modal">
            <p className="left-header">
              Duration:{" "}
              <span className="right-item">
                {selectedPackageSvc?.duration}
                {" mins."}
              </span>
            </p>

            <p className="left-header">What's Included: </p>
            <PackageDescription
              htmlContent={
                currentLanguage === "en"
                  ? selectedPackageSvc?.additional_information
                  : selectedPackageSvc?.additional_information_arabic
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="d-inline-flex align-items-center border overflow-hidden"
            style={{ width: "100%" }}
          >
            <button
              className="btn px-3 border-end incr-decr-btn"
              onClick={() => handleItemsDecr(selectedPackageSvc)}
              style={{ ":hover": "background-color: #grey" }}
            >
              –
            </button>

            <div
              className="px-3 d-flex align-items-center justify-center"
              style={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <span className="fw-bold me-1 small">
                {pkgSvcCountMap.get(selectedPackageSvc) || 0}
              </span>
              <span
                className="text-muted small me-1"
                style={{ fontSize: "smaller" }}
              >
                {currentLanguage === "en"
                  ? selectedPackageSvc?.name
                  : selectedPackageSvc?.name_arabic}
              </span>
            </div>

            <button
              className="btn px-3 border-start incr-decr-btn"
              onClick={() => handleItemsIncr(selectedPackageSvc)}
            >
              +
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(NewServices);
